import { Box, Button, Grid, Link, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, Card, Container } from '@material-ui/core';
import PageHeader from '../PageHeader';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorIcon, toast } from 'react-hot-toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { apiClient } from '../../../api/common';
import { IoTCheckIdDuplication, SX_TEMP_BORDER } from '../../../commonStyles';
import {
  myRegexEmail,
  myRegexId,
  myRegexPassword4,
  VERTICAL_TITLE_BAR_SECTION
} from '../../../consts';
import { statesOfKoreaOptions, IoTEmailOptions } from '../../../typesServiceDesign';
import { ReactComponent as Invisible } from '../../../icons/ic_invisible.svg';
import { ReactComponent as Visible } from '../../../icons/ic_visible.svg';
import { FormItemTitleA } from '../../widgets/FormItemTitleA'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import { setCookie } from 'src/__CareWell__/utils/cookieTools'
import useAuth from 'src/hooks/useAuth';

export default function IoTForm() {
  const navigate = useNavigate()
  const {logout} = useAuth()

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectEmail, setSelectEmail] = useState(IoTEmailOptions[0].value)
  const [IoTId, SetIoTId] = useState('')
  const [IoTPassword, setIoTPassword] = useState('')
  const [IoTPassword2, setIoTPassword2] = useState('')
  const [IoTEmail, setIoTEmail] = useState('')
  const [IoTEmail2, setIoTEmail2] = useState('')
  const [duplicateId, setDuplicateId] = useState<0 | 1 | 2>(0)
  const [duplicateIdCheck, setDuplicateIdCheck] = useState(false)
  const [idError, setIdError] = useState(false)
  const [idErrorText, setIdErrorText] = useState('')
  const [pwdError, setPwdError] = useState(false)
  const [pwdErrorText, setPwdErrorText] = useState('')
  const [pwdError2, setPwdError2] = useState(false)
  const [pwdErrorText2, setPwdErrorText2] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState('')
  const [email, setEmail] = useState('')

  const handleVisible = (e) => {
    setPasswordVisible((visible) => !visible);
  }

  const validationId = async (e) => {
    SetIoTId(e.target.value)
    setDuplicateId(0)
    setIdErrorText('')
    setIdError(true)
    setDuplicateIdCheck(false)
    if (!duplicateIdCheck && e.target.value) {
      setIdError(true)
      setIdErrorText('대상자 ID체크를 해주세요')
    }
  }

  const validationPwd = (e) => {
    setIoTPassword(e.target.value)
    setPwdError(true)
    setPwdErrorText('')
    if (!myRegexPassword4.test(e.target.value)) {
      setPwdError(true)
      setPwdErrorText('4~20자 영문,숫자,특수문자를 사용해주세요.')
    } else if (myRegexPassword4.test(e.target.value)) {
      setPwdError(false)
    }
  }

  const validationPwdCheck = (e) => {
    setIoTPassword2(e.target.value)
    setPwdError2(true)
    setPwdErrorText2('')
    if (e.target.value === IoTPassword) {
      setPwdError2(false)
      setPwdErrorText2('비밀번호가 일치합니다.')
    } else {
      setPwdErrorText2('비밀번호가 일치하지 않습니다.')
    }
  }

  // 등록
  const save = async () => {
    let reqData;
    if (IoTId !== '' && IoTPassword !== '' && email !== '' && !idError && !pwdError && !pwdError2 && !emailError) {
      reqData = {
        silver_id: IoTId,
        iot_id: email,
        iot_pwd: IoTPassword
      }
    } else {
      if (!duplicateIdCheck && myRegexId.test(IoTId)) {
        toast.error('대상자 ID체크를 해주세요.')
      } else {
        toast.error('필수 값을 입력해주세요!')
      }
      return;
    }
    console.log(reqData)
    try {
      const url = '/add_iot_user'
      const config = await checkToken(logout, navigate);
      let res = await apiClient.post(url, reqData, config)

      if (res.data.state === 'reissue') {
        const tkn = res.data.token;
        config.headers['x-access-token'] = tkn
        setCookie('access_token', tkn, 1)
        res = await apiClient.post(url, reqData, config)
      }

      if (duplicateId === 1 && res.data.state === '204') {
        toast.error('이미 사용 중인 ID입니다.');
        return;
      }
      if (res.data.state === 'ok' && duplicateIdCheck) {
        toast.success('성공적으로 등록하였습니다.')
        navigate('/dashboard/users/IoT')
      } else toast.error('등록에 실패하였습니다.')
    } catch (err) {
      console.log(err)
      toast.error('등록에 실패하였습니다.')
    }
  }

  // ID 중복체크
  const checkIdDuplication = async () => {
    setIdErrorText('')
    try {
      if (!IoTId) {
        toast.error('아이디를 입력하십시오.')
      }
      if (IoTId && !myRegexId.test(IoTId)) {
        setIdErrorText('4~20자 영문,숫자를 사용해주세요.')
      }
      if (myRegexId.test(IoTId)) {
        const config = await checkToken(logout, navigate);
        const url = `/is_exist_silver?silver_id=${IoTId}`
        const res = await apiClient.get(url, config)
        setDuplicateId(res.data.state === 'fail' ? 2 : 1)
        if (res.data.state !== 'fail') {
          setDuplicateIdCheck(true)
          setIdError(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  // useEffect(() => {
  //   const Email = `${IoTEmail}@${selectEmail}`
  //   if (Email !== '') {
  //     if (myRegexEmail.test(Email)) {
  //       setEmailError(false)
  //       setEmailErrorText('')
  //       setIoTEmail2(`${IoTEmail}@${selectEmail}`)
  //     } else {
  //       setEmailError(true)
  //       setEmailErrorText('이메일 형식에 맞지 않습니다.')
  //     }
  //   }
  // }, [IoTEmail, selectEmail])

  const handleEmail = (e) => {
    const Email = `${e.target.value}@${selectEmail}`
    setIoTEmail(e.target.value)
    if (myRegexEmail.test(Email)) {
      setEmailError(false)
      setEmailErrorText('')
      setEmail(Email)
    } else {
      setEmailError(true)
      setEmailErrorText('이메일 형식에 맞지 않습니다.')
    }
  }

  const handleEmailChange = (e) => {
    const Email = `${IoTEmail}@${e.target.value}`
    setSelectEmail(e.target.value)
    if (myRegexEmail.test(Email)) {
      setEmailError(false)
      setEmailErrorText('')
      setEmail(Email)
    } else {
      setEmailError(true)
      setEmailErrorText('이메일 형식에 맞지 않습니다.')
    }
  }

  const SX_TEXTFIELD = {width: 220}

  return (
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 2}}>
      <Container maxWidth="xl">
        <PageHeader title="IoT 등록" paths={['홈', '회원관리', 'IoT', '등록']} links={['/', '', '', '']} />
        <Card sx={{ width: '100%', typography: 'body1', mt: 3, p: 3 }}>
          <Box sx={SX_TEMP_BORDER}>
            <Typography color="textPrimary" variant="h6" sx={VERTICAL_TITLE_BAR_SECTION}>기본정보</Typography>
            <Grid item xs={12} sm={4}>
              <FormItemTitleA title="ID" />
              <TextField variant="outlined" fullWidth size="small" sx={SX_TEXTFIELD} onChange={validationId}
                error={idError} helperText={idErrorText}
                value={IoTId === null ? '' : IoTId}
                autoComplete="new-password"
              />
              <Button variant="outlined" sx={{ml: 2, height: 40}} onClick={checkIdDuplication}>대상자 ID 체크</Button>
              {IoTCheckIdDuplication(duplicateId)}
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormItemTitleA title="비밀번호" />
              <Grid container alignItems="center" direction="row">
                <Grid item>
                  <TextField variant="outlined" fullWidth size="small" name="public_care_password" sx={SX_TEXTFIELD} type={passwordVisible ? 'text' : 'password'} onChange={validationPwd}
                    error={pwdError} helperText={pwdErrorText}
                    value={IoTPassword === null ? '' : IoTPassword}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item>
                  {passwordVisible && <Visible
                    style={{ marginLeft: '10px' }}
                    width={20}
                    height={20}
                    onClick={handleVisible}
                  />}
                  {!passwordVisible && <Invisible
                    style={{ marginLeft: '10px' }}
                    width={20}
                    height={20}
                    onClick={handleVisible}
                  />}
                </Grid>
              </Grid>
              <Typography variant="body2" sx={{mt: 1}}>비밀번호 영문/숫자/특수문자 조합 4~20자 사용 가능 사용가능한 특수문자 ~! @ # $ % ^ & * ( ) _ - + =</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormItemTitleA title="비밀번호 확인" />
              <TextField variant="outlined" fullWidth size="small" sx={SX_TEXTFIELD} onChange={validationPwdCheck} type="password"
                error={pwdError2} helperText={pwdErrorText2}
                value={IoTPassword2 === null ? '' : IoTPassword2}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormItemTitleA title="이메일" />
              <Box justifyContent="center" alignItems="center">
                <TextField variant="outlined" fullWidth size="small" sx={{width: 140, mr: 0}} name="email1" onChange={handleEmail}
                  error={emailError} helperText={emailErrorText}
                  value={IoTEmail === null ? '' : IoTEmail}
                  autoComplete="new-password"
                />
                <Box display="inline-flex" justifyContent="center" alignItems="center" component="span"
                  sx={{border: 0, borderColor: '#FF0000', height: 40, p: 0.5, m: 0, pt: 0}}
                >
                  <Typography display="inline" align="center" sx={{border: 0}}>@</Typography>
                </Box>
                <TextField variant="outlined" fullWidth size="small" placeholder="직접입력" sx={{width: 120, mr: 1}} name="email2" value={selectEmail} onChange={handleEmailChange} />
                <TextField size="small" select SelectProps={{native: true}} variant="outlined"
                  name="email3" sx={{width: 140}} onChange={handleEmailChange}
                >
                  {IoTEmailOptions.map((item) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid container justifyContent="space-between" sx={{mt: 4}}>
              <Grid item>
                <Button variant="outlined" sx={{m: 0, ml: 0}}>
                  <Link
                    to="/dashboard/users/IoT"
                    component={RouterLink}
                    state={{
                      prevPath: window.location.pathname
                    }}
                  >
                    목록
                  </Link>
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" sx={{ m: 0 }} type="submit" onClick={save}>
                  저장
                </Button>
                <Button variant="outlined" sx={{ml: 3, mr: 5}} onClick={() => navigate('/dashboard/users/IoT')}>취소</Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </Box>
  );
}
