import useAuth from 'src/hooks/useAuth';
import { apiClient } from '../api/common';
import { checkToken } from './tokenChecker';
import { useNavigate } from 'react-router-dom';
import { carewellAuthApi } from '../api/carewellAuthApi';

export const historyType = {
  ADD_SILVER: '대상자 정보 추가',
  MODIFY_SILVER: '대상자 정보 수정',
  DELETE_SILVER: '대상자 정보 삭제',
  RESET_SILVER_PW: '대상자 비밀번호 변경',
  ADD_AI_SPEAKER: '스피커 정보 추가',
  UPDATE_AI_SPEAKER: '스피커 정보 수정',
  UPDATE_AI_SPEAKER_INSTALL_STATE: '스피커 설치 정보 수정',
  ASSIGN_AI_SPEAKER: '사용자에게 스피커 할당',
  REMOTE_AI_SPEAKER: '사용자 스피커 원격',
  ADD_CHATBOT: '스마트알림 정보 추가',
  MODIFY_CHATBOT: '스마트알림 정보 수정',
  DELETE_CHATBOT: '스마트알림 정보 삭제'
}
/// / history api

/// / [speaker]
/// / connect_ai_speaker_v2 사용자 스피커 연결 확인
/// / add_ai_speaker 스피커 등록
/// / add_ai_speaker_multi_data 스피커 대량 등록
/// / app_to_add_aispeaker 앱으로 스피커 등록
/// / update_ai_speaker 스피커 정보 수정
/// / set_ai_speaker_install_state 스피커 설치 스테이트 변경
/// / assign_ai_speaker 스피커 로그인
/// / disconnect_ai_speaker 스피커 연결 해제
/// / reset_ai_speaker 스피커 초기화
/// / reset_move_ai_speaker 기기변경으로인한 스피커 초기화(사용여부 불확실)

/// / [silver]
/// / add_silver_data // 대상자 등록
/// / add_silver_multi_data // 대상자 대량 등록
/// / update_silver_data // 대상자 수정
/// / delete_silver // 대상자 삭제

/// / [remote]
/// / 원격 이용 로그


export const addHistory = async (targetID, targetSpeakerSN, editLog, editType) => {
  const url = '/add_history';
  const editID = carewellAuthApi.loggedInUser.id
  const edit = {
    target_id: targetID,
    target_speaker_sn: targetSpeakerSN,
    edit_log: JSON.stringify(editLog),
    edit_type: editType,
    edit_by_id: editID
  }
  console.log(edit)
  try {
    const res = await apiClient.post(url, edit);
    console.log('add_history', res)
    return res
  } catch {
    console.log('no add history');
    return null
  }
}
