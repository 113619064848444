import {ChangeEvent, MouseEvent, useCallback, useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Pagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  LinearProgress,
  CircularProgress,
  debounce
} from '@material-ui/core'
import Scrollbar from '../../../../components/Scrollbar'
import {AccountType, CSS_CENTER, PAGE_LIMITS, RIGHT_VERTICAL_BAR_COUNT} from '../../../consts'
import MyRowsPerPage from '../../MyRowsPerPage'
import {applyPagination, applyStringSortG, handleClickSort} from '../../../utils/table'
import {getSortIcon, MyTableA, SX_TABLE_HEADER_A} from '../../../commonStyles'
import {carewellAuthApi} from '../../../api/carewellAuthApi'
import {AiSpeaker} from '../../../types/speaker'
import SpeakerStatusEditModal from '../../widgets/modals/SpeakerStatusEditModal'
import SpeakerAssignModal from '../../widgets/modals/SpeakerAssignModal'
import SpeakerStatusEditModalV2 from '../../widgets/modals/SpeakerStatusEditModalV2'


const baseTableUrl = '/dashboard/users/recipients'

const HEIGHT = 72
const TITLE_WIDTH = 100

interface Props {
  limit : number,
  setLimit : (number) => void,
  allCount : number,
  page: number,
  setPage : (number) => void,
  aiSpeakerList: AiSpeaker[],
  doUpdateTable: ()=>void,
  loading: boolean
}

// 스피커 현황
export default function SpeakerOverviewTableV2(props: Props) {
  const {doUpdateTable, limit, setLimit, allCount, page, setPage, aiSpeakerList, loading} = props;
  const navigate = useNavigate()
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const [popupSpeakerStatusEdit, setPopupSpeakerStatusEdit] = useState(false)
  const [currentItem, setCurrentItem] = useState<AiSpeaker>()
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [paginationDisabled, setPaginationDisabled] = useState<boolean>(false)

  const onRowsPerPageChange = useCallback((limitNumber: number): void => {
    debounce((event, value) => {
      setLimit(limitNumber)
      setPage(0) // 페이지 rows 수 변경 시 1페이지부터 보여줌
    }, 200) /// 페이징 더블클릭시 에러 있음
  }, [])

  const handleCellClick = useCallback(
    (item: AiSpeaker) => {
      navigate(`/dashboard/users/recipients/${item.silver_id}/details`, {
        state: {
          pathName: window.location.pathname
        }
      })
    }, [setCurrentItem]
  );

  const handleSelectedOneRow = (
    item: AiSpeaker
  ): void => {
    if (selectedRow === item.ai_speaker_sn) {
      setSelectedRow('')
      setCurrentItem(null)
    } else {
      setSelectedRow(item.ai_speaker_sn)
      setCurrentItem(item)
    }
  }
  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    if (newPage != null) {
      setPage(newPage - 1)
    }
  }
  useEffect(() => {
    setSelectedRow('')
    setCurrentItem(null)
  }, [aiSpeakerList])

  const handleSpeakerStatus = (time: any) => {
    const currentTime: Date = new Date();

    const itemTime: Date = new Date(time);

    if (currentTime.getTime() - itemTime.getTime() <= 3 * 60 * 60 * 1000) {
      return '사용';
    }
    return '미사용';
  }

  return (
    <Card>
      <Box>
        <Scrollbar>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ml: 2, mb: 0}}>
              <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{p: 1, ml: 2}}>
                  <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${allCount}`}</Typography>
                </Box>
                <Box sx={{p: 1}}>
                  <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
                </Box>
                <Box sx={{p: 1}} />
              </Box>
            </Grid>
            {/* <Grid item sx={{mr: 2, pt: 2}}>
              {(isOperator) && <Button variant="outlined" disabled={(selectedRow === '' || selectedRow == null)} onClick={() => setPopupSpeakerStatusEdit(true)}>기기 상태변경</Button> }
            </Grid> */}
          </Grid>
          <MyTableA size="small">
            <TableHead>
              <TableRow sx={SX_TABLE_HEADER_A}>
                <TableCell />
                <TableCell>
                  모델명
                  <IconButton size="small" />
                </TableCell>
                <TableCell>
                  시리얼 번호
                  <IconButton size="small" />
                </TableCell>
                <TableCell>
                  기기상태
                </TableCell>
                <TableCell>
                  사용여부
                </TableCell>
                <TableCell>
                  서비스인증
                  <IconButton size="small" />
                </TableCell>
                <TableCell>대상자ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                  aiSpeakerList && aiSpeakerList.map((item, index) => {
                    const isProductSelected = selectedRow.includes(item.ai_speaker_sn)

                    return (
                      <TableRow hover key={item.ai_speaker_sn} selected={isProductSelected}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell align="center" padding="checkbox">
                          <Checkbox color="primary" checked={isProductSelected} value={isProductSelected}
                            onChange={(e): void => handleSelectedOneRow(item)}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_model_name}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_sn}</TableCell>
                        {/* <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_created_date}</TableCell> */}
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_status}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}><Typography fontSize={12}>{handleSpeakerStatus(item.ai_speaker_alive_check_time)}</Typography></TableCell>
                        <TableCell onClick={() => handleCellClick(item)}><Typography fontSize={14}>{item.ai_speaker_certificate_yn}</Typography></TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.silver_id}</TableCell>
                      </TableRow>
                    )
                  })
                }
            </TableBody>
          </MyTableA>
          <Divider />
          <Box display="flex" justifyContent="center" sx={{my: 3}}>
            {aiSpeakerList.length === 0 && (<Typography>조회 내역이 없습니다.</Typography>)}
          </Box>
          <Box sx={{...CSS_CENTER, my: 2}}>
            <Pagination
              siblingCount={5}
              count={allCount % limit > 0 ? Math.floor(allCount / limit + 1) : Math.floor(allCount / limit)}
              page={page + 1}
              onClick={() => {
                setPaginationDisabled(true);
                setTimeout(() => {
                  setPaginationDisabled(false);
                }, 100)
              }}
              disabled={paginationDisabled}
              onChange={handlePageChange}
            />
          </Box>
        </Scrollbar>
      </Box>
      <SpeakerStatusEditModalV2 isOverviewMode initialValue={currentItem} open={!!((currentItem != null && popupSpeakerStatusEdit === true))} setOpen={setPopupSpeakerStatusEdit} />
    </Card>
  )
}
