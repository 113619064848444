import { Box, Button, Grid, Link, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, Card, Container } from '@material-ui/core';
import PageHeader from '../PageHeader';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorIcon, toast } from 'react-hot-toast';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../../api/common';
import { IoTCheckIdDuplication, SX_TEMP_BORDER } from '../../../commonStyles';
import {
  myRegexEmail,
  myRegexId,
  myRegexPassword4,
  VERTICAL_TITLE_BAR_SECTION
} from '../../../consts';
import { statesOfKoreaOptions, IoTEmailOptions } from '../../../typesServiceDesign';
import { ReactComponent as Invisible } from '../../../icons/ic_invisible.svg';
import { ReactComponent as Visible } from '../../../icons/ic_visible.svg';
import { FormItemTitleA } from '../../widgets/FormItemTitleA'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import { setCookie } from 'src/__CareWell__/utils/cookieTools'
import useAuth from 'src/hooks/useAuth';

export default function IoTForm() {
  const navigate = useNavigate()
  const {logout} = useAuth()
  const {id} = useParams()

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectEmail, setSelectEmail] = useState(IoTEmailOptions[0].value)
  const [IoTId, SetIoTId] = useState('')
  const [IoTPassword, setIoTPassword] = useState('')
  const [IoTPassword2, setIoTPassword2] = useState('')
  const [IoTEmail, setIoTEmail] = useState('')
  const [IoTEmail2, setIoTEmail2] = useState('')
  const [idError, setIdError] = useState(false)
  const [idErrorText, setIdErrorText] = useState('')
  const [pwdError, setPwdError] = useState(false)
  const [pwdErrorText, setPwdErrorText] = useState('')
  const [pwdError2, setPwdError2] = useState(false)
  const [pwdErrorText2, setPwdErrorText2] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState('')

  const handleVisible = (e) => {
    setPasswordVisible((visible) => !visible);
  }

  const handleEmailChange = (e) => {
    setSelectEmail(e.target.value)
  }

  const validationPwd = (e) => {
    setIoTPassword(e.target.value)
    setPwdError(true)
    setPwdErrorText('')
    if (!myRegexPassword4.test(e.target.value)) {
      setPwdError(true)
      setPwdErrorText('4~20자 영문,숫자,특수문자를 사용해주세요.')
    } else if (myRegexPassword4.test(e.target.value)) {
      setPwdError(false)
    }
  }

  const validationPwdCheck = (e) => {
    setIoTPassword2(e.target.value)
    setPwdError2(true)
    setPwdErrorText2('')
    if (e.target.value === IoTPassword) {
      setPwdError2(false)
      setPwdErrorText2('비밀번호가 일치합니다.')
    } else {
      setPwdErrorText2('비밀번호가 일치하지 않습니다.')
    }
  }

  // 업데이트
  const save = async () => {
    let reqData;
    if (IoTId !== '' && IoTPassword !== '' && IoTEmail2 !== '' && !idError && !pwdError && !pwdError2 && !emailError) {
      reqData = {
        silver_id: IoTId,
        iot_id: IoTEmail2,
        iot_pwd: IoTPassword
      }
    }
    try {
      const url = '/update_iot_user'
      const config = await checkToken(logout, navigate);
      console.log(`[API Req-${url}]: `, reqData)
      let res = await apiClient.post(url, reqData, config)

      if (res.data.state === 'reissue') {
        const tkn = res.data.token;
        config.headers['x-access-token'] = tkn
        setCookie('access_token', tkn, 1)
        res = await apiClient.post(url, reqData, config)
      }
      if (res.data.state === 'ok') {
        toast.success('성공적으로 수정하였습니다.')
        navigate('/dashboard/users/IoT')
      } else toast.error('수정에 실패하였습니다.')
    } catch (err) {
      console.log(err)
      toast.error('수정에 실패하였습니다.')
    }
  }

  useEffect(() => {
    const Email = `${IoTEmail}@${selectEmail}`
    if (myRegexEmail.test(Email)) {
      setEmailError(false)
      setEmailErrorText('')
      setIoTEmail2(Email)
    } else {
      setEmailError(true)
      setEmailErrorText('이메일 형식에 맞지 않습니다.')
    }
  }, [IoTEmail, selectEmail])

  useEffect(() => {
    const getUser = async () => {
      const reqData = {
        silver_id: id
      }
      try {
        const url = '/get_iot_userinfo'
        const config = await checkToken(logout, navigate);
        let res = await apiClient.post(url, reqData, config)

        if (res.data.state === 'reissue') {
          const tkn = res.data.token;
          config.headers['x-access-token'] = tkn
          setCookie('access_token', tkn, 1)
          res = await apiClient.post(url, reqData, config)
        }
        const email = res.data.iot_id.split('@')[0]
        const email2 = res.data.iot_id.split('@')[1]
        SetIoTId(res.data.silver_id)
        setIoTPassword(res.data.iot_pwd)
        setIoTPassword2(res.data.iot_pwd)
        setIoTEmail(email)
        setSelectEmail(email2)
      } catch (err) {
        console.log(err)
      }
    }
    getUser()
  }, [])

  const SX_TEXTFIELD = {width: 220}

  return (
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 2}}>
      <Container maxWidth="xl">
        <PageHeader title="IoT 수정" paths={['홈', '회원관리', 'IoT', '수정']} links={['/', '', '', '']} />
        <Card sx={{ width: '100%', typography: 'body1', mt: 3, p: 3 }}>
          <Box sx={SX_TEMP_BORDER}>
            <Typography color="textPrimary" variant="h6" sx={VERTICAL_TITLE_BAR_SECTION}>기본정보</Typography>
            <Grid item xs={12} sm={4}>
              <FormItemTitleA title="ID" />
              <TextField variant="outlined" fullWidth size="small" sx={SX_TEXTFIELD}
                error={idError} helperText={idErrorText} value={IoTId}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormItemTitleA title="비밀번호" />
              <Grid container alignItems="center" direction="row">
                <Grid item>
                  <TextField variant="outlined" fullWidth size="small" name="public_care_password" sx={SX_TEXTFIELD} type={passwordVisible ? 'text' : 'password'} onChange={validationPwd}
                    error={pwdError} helperText={pwdErrorText} value={IoTPassword}
                  />
                </Grid>
                <Grid item>
                  {passwordVisible && <Visible
                    style={{ marginLeft: '10px' }}
                    width={20}
                    height={20}
                    onClick={handleVisible}
                  />}
                  {!passwordVisible && <Invisible
                    style={{ marginLeft: '10px' }}
                    width={20}
                    height={20}
                    onClick={handleVisible}
                  />}
                </Grid>
              </Grid>
              <Typography variant="body2" sx={{mt: 1}}>비밀번호 영문/숫자/특수문자 조합 4~20자 사용 가능 사용가능한 특수문자 ~! @ # $ % ^ & * ( ) _ - + =</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormItemTitleA title="비밀번호 확인" />
              <TextField variant="outlined" fullWidth size="small" sx={SX_TEXTFIELD} onChange={validationPwdCheck} type="password"
                error={pwdError2} helperText={pwdErrorText2} value={IoTPassword2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormItemTitleA title="이메일" />
              <Box justifyContent="center" alignItems="center">
                <TextField variant="outlined" fullWidth size="small" sx={{width: 140, mr: 0}} name="email1" onChange={(e) => { setIoTEmail(e.target.value) }}
                  error={emailError} helperText={emailErrorText} value={IoTEmail}
                />
                <Box display="inline-flex" justifyContent="center" alignItems="center" component="span"
                  sx={{border: 0, borderColor: '#FF0000', height: 40, p: 0.5, m: 0, pt: 0}}
                >
                  <Typography display="inline" align="center" sx={{border: 0}}>@</Typography>
                </Box>
                <TextField variant="outlined" fullWidth size="small" placeholder="직접입력" sx={{width: 120, mr: 1}} name="email2" value={selectEmail} onChange={handleEmailChange} />
                <TextField size="small" select SelectProps={{native: true}} variant="outlined"
                  name="email3" sx={{width: 140}} onChange={handleEmailChange}
                >
                  {IoTEmailOptions.map((item) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid container justifyContent="space-between" sx={{mt: 4}}>
              <Grid item>
                <Button variant="outlined" sx={{m: 0, ml: 0}}>
                  <Link
                    to="/dashboard/users/IoT"
                    component={RouterLink}
                    state={{
                      prevPath: window.location.pathname
                    }}
                  >
                    목록
                  </Link>
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" sx={{ m: 0 }} type="submit" onClick={save}>
                  저장
                </Button>
                <Button variant="outlined" sx={{ml: 3, mr: 5}} onClick={() => navigate('/dashboard/users/IoT')}>취소</Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </Box>
  );
}
