import { Box, Button, Card, Container, Grid, Link, Typography, TextField,
  Pagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider, } from '@material-ui/core'
import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import useSettings from '../../../../hooks/useSettings'
import gtm from '../../../../lib/gtm'
import { carewellAuthApi } from '../../../api/carewellAuthApi'
import { apiClient } from '../../../api/common'
import PageHeader from '../PageHeader'
import { AccountType, CONTENT_LAYOUT_PY, PAGE_LIMITS, CSS_CENTER, RIGHT_VERTICAL_BAR_COUNT } from '../../../consts'
import MyRowsPerPage from '../../MyRowsPerPage'
import Scrollbar from '../../../../components/Scrollbar'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import { setCookie } from 'src/__CareWell__/utils/cookieTools'
import {toast} from 'react-hot-toast'
import {getCPEStatusLabel, getSortIcon, MyTableA, SX_TABLE_HEADER_A} from '../../../commonStyles'



const urlCreateRecord = '/dashboard/users/IoT/new'

const HEIGHT = 58
const TITLE_WIDTH = 80

export default function IoTListSearch() {
  const { settings } = useSettings()
  const {logout} = useAuth()
  const navigate = useNavigate()
  const [searchDone, setSearchDone] = useState(false)
  const [searchItem, setSearchItem] = useState('전체');
  const [IoTId, setIoTId] = useState('')
  const [IoTEmail, setIoTEmail] = useState('')
  const [searchIoTId, setSearchIoTId] = useState('')
  const [searchIoTEmail, setSearchIoTEmail] = useState('')
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(PAGE_LIMITS[1])
  const [data, setData] = useState([])
  const [dataLength, setDataLength] = useState(0)

  const isKHealthMember = carewellAuthApi.getAccountType() === AccountType.KHealthMember

  const isHCenterManager = carewellAuthApi.getAccountType() === AccountType.HCenterManager
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const isMunicipalityManager = carewellAuthApi.getAccountType() === AccountType.MunicipalityManager
  const suppix = isOperator ? '관리' : ''

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  const searchUrl = '/get_silver_list_search/2'

  const onChangeSearchItem = (e) => {
    setSearchIoTId('')
    setSearchIoTEmail('')
    setSearchItem(e.target.value)
  }

  const handleSearchChange = (e) => {
    if (searchItem === '대상자 아이디') {
      setSearchIoTId(e.target.value)
    } else if (searchItem === 'iot_id') {
      setSearchIoTEmail(e.target.value)
    }
  }

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    console.log('handlePageChange newPage: ', newPage - 1)
    setPage(newPage - 1)
  }

  const onRowsPerPageChange = (limitNumber: number): void => {
    setLimit(limitNumber)
  }

  const handleCellClick = (id) => {
    navigate(`/dashboard/users/IoT/${id}/detail`, {
    })
  }

  const getUser = async () => {
    const reqData = {
      silver_id: searchIoTId,
      iot_id: searchIoTEmail
    };
    try {
      const url = `/get_iot_user_list/${page}/${limit}`
      const config = await checkToken(logout, navigate);
      let res = await apiClient.post(url, reqData, config)

      if (res.data.state === 'reissue') {
        const tkn = res.data.token;
        config.headers['x-access-token'] = tkn
        setCookie('access_token', tkn, 1)
        res = await apiClient.post(url, reqData, config)
      }
      setIoTId(res.data.silver_id)
      setIoTEmail(res.data.iot_id)
      setData(res.data.data)
      setDataLength(res.data.count)
    } catch (err) {
      console.log(err)
    }
  }

  const handleReset = async (e) => {
    setSearchItem('전체')
    setSearchIoTEmail('')
    setSearchIoTId('')
    setLimit(PAGE_LIMITS[1])
    const reqData = {
      silver_id: '',
      iot_id: ''
    };
    try {
      const url = `/get_iot_user_list/${page}/${limit}`
      const config = await checkToken(logout, navigate);
      let res = await apiClient.post(url, reqData, config)

      if (res.data.state === 'reissue') {
        const tkn = res.data.token;
        config.headers['x-access-token'] = tkn
        setCookie('access_token', tkn, 1)
        res = await apiClient.post(url, reqData, config)
      }
      setIoTId(res.data.silver_id)
      setIoTEmail(res.data.iot_id)
      setData(res.data.data)
      setDataLength(res.data.count)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const handleDelete = async (id) => {
    let qa;
    const question = window.confirm('정말 삭제하시겠습니까?') ? qa = true : qa = false
    if (qa === true) {
      const reqData = {
        silver_id: id
      }
      try {
        const url = '/delete_iot_user'
        const config = await checkToken(logout, navigate);
        let res = await apiClient.post(url, reqData, config)

        if (res.data.state === 'reissue') {
          const tkn = res.data.token;
          config.headers['x-access-token'] = tkn
          setCookie('access_token', tkn, 1)
          res = await apiClient.post(url, reqData, config)
        }
        if (res.data.state === 'ok') {
          toast.success('성공적으로 삭제하였습니다.')
          // window.location.reload();
          getUser()
        }
      } catch (err) {
        console.log(err)
        toast.error('삭제에 실패하였습니다.')
      }
    }
  }

  const handleSearch = () => {
    getUser()
  }

  useEffect(() => {
    getUser()
  }, [limit, page])

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      getUser()
    }
  }

  return (
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <PageHeader title="IoT 회원 조회" paths={['홈', `회원${suppix}`, 'IoT', '조회']} links={['/', '', '', '']} />

        <Box sx={{mt: 3}}>
          <Card>
            <Box sx={{ pt: 1 }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT }}>
                    <Box sx={{ p: 1 }}>
                      <Typography width={TITLE_WIDTH} align="right">
                        검색
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        size="small"
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        label=""
                        name="search_item"
                        sx={{ ml: 1, width: 140 }}
                        value={searchItem}
                        onChange={onChangeSearchItem}
                      >
                        <option value="전체">전체</option>
                        <option value="대상자 아이디">대상자 아이디</option>
                        <option value="iot_id">IoT 아이디</option>
                      </TextField>
                      <Box sx={{ px: 1 }}>
                        { searchItem !== '전체' ? <TextField size="small" placeholder="텍스트를 입력해 주세요." name="search_content" value={searchItem === '대상자 아이디' ? searchIoTId : searchIoTEmail} onChange={handleSearchChange} onKeyDown={handleOnKeyDown} /> : ''}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Button variant="outlined" sx={{ mt: 1, mr: 2 }} onClick={handleReset}>초기화</Button>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, m: 1, bgcolor: 'background.paper' }}>
              <Button variant="outlined" type="submit" sx={{ width: 100 }} onClick={handleSearch}>
                검색
              </Button>
            </Box>
          </Card>
          <Box sx={{mb: 2}} />
          <Card>
            {/* <IoTListTable willHideR
            egionColumn={willHideRegionColumn} records={states.silverList} doUpdateTable={doUpdateTable} searchDone={searchDone}
              sortColumns={sortColumns} sheetHeader={sheetHeader} sheetKeyNames={sheetKeyNames} sheetFilename="대상자"
            /> */}
            <Box sx={{border: 0}}>
              <Box>
                <Scrollbar>
                  <Box sx={{minWidth: 900}}>
                    <Grid container justifyContent="space-between">
                      <Grid item sx={{ml: 2, mb: 0}}>
                        <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, bgcolor: 'background.paper', height: HEIGHT}}>
                          <Box sx={{p: 1, ml: 2}}>
                            {/* <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${page < Math.floor(records.length / 50) - 1 ? 50 : records.length - 50 * page}`}</Typography>
                            */}
                            <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${dataLength !== undefined ? dataLength : 0}`}</Typography>
                          </Box>
                          <Box sx={{p: 1}}>
                            <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
                          </Box>
                          <Box sx={{p: 1}} />
                        </Box>
                      </Grid>
                      <Grid item sx={{mr: 2, pt: 2}}>
                        {/** 엑셀 다운로드 */}
                        {/* {sheetHeader && (<XlsxControl rows={sheetRows} filename={sheetFilename} />) } */}
                      </Grid>
                    </Grid>
                    <MyTableA size="small">
                      <TableHead>
                        <TableRow sx={SX_TABLE_HEADER_A}>
                          <TableCell align="center">
                            대상자 아이디
                          </TableCell>
                          <TableCell align="center">
                            IoT 아이디
                          </TableCell>
                          <TableCell align="center">회원관리</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          data && data.map((item) => (
                            <TableRow hover key={item.silver_id}
                              sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                              <TableCell align="center" onClick={() => handleCellClick(item.silver_id)}>{item.silver_id}</TableCell>
                              <TableCell align="center" onClick={() => handleCellClick(item.silver_id)}>{item.iot_id}</TableCell>
                              <TableCell align="center"><Typography fontSize={12} fontWeight={600} onClick={() => handleDelete(item.silver_id)}>[삭제]</Typography></TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </MyTableA>
                    <Divider />

                    <Box display="flex" justifyContent="center" sx={{my: 3}}>
                      {dataLength === undefined && (searchDone ? <Typography>조회 내역이 없습니다.</Typography> : <Typography>등록된 내역이 없습니다</Typography>)}
                    </Box>

                    <Box sx={{...CSS_CENTER, my: 2}}>
                      <Pagination
                        showFirstButton showLastButton siblingCount={5}
                        count={Math.ceil(dataLength / limit)}
                        page={page + 1}
                        onChange={handlePageChange}
                      />
                    </Box>
                  </Box>
                </Scrollbar>
              </Box>
            </Box>

            { !isKHealthMember && (
            <Grid container justifyContent="space-between">
              <Grid item sx={{ml: 2, mb: 0}} />
              <Grid item sx={{mr: 2, mb: 2}}>
                <Button variant="outlined" sx={{mt: 2, mr: 2}}>
                  <Link color="textPrimary" component={RouterLink} to={urlCreateRecord} variant="subtitle2">IoT 회원등록</Link>
                </Button>
              </Grid>
            </Grid>
            )}
          </Card>
        </Box>
      </Container>
    </Box>
  )
}


