/* eslint react/prop-types: 0 */
import {Button, Typography, Box, Avatar} from '@material-ui/core'
import * as XLSX from 'xlsx'
import {useState} from 'react'
import {forEach} from 'lodash';

// const MyApiTest: NextPage = () => {
//   return (
//     <>
//       <Typography>Api Test</Typography>
//     </>
//   )
// }

// https://github.com/SheetJS/sheetjs/tree/fcf9182fa628b8c5f6fbf1997a7ad6fcbb695fd1/demos/react
// https://github.com/SheetJS/sheetjs/blob/fcf9182fa628b8c5f6fbf1997a7ad6fcbb695fd1/demos/react/sheetjs.js


/* list of supported file types */
const SheetJSFT = [
  'xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm'
].map((x) => `.${x}`).join(',')

/* generate an array of column objects */
// eslint-disable-next-line @typescript-eslint/naming-convention
const make_cols = (refstr) => {
  const o = []
  const
    C = XLSX.utils.decode_range(refstr).e.c + 1
  for (let i = 0; i < C; ++i) o[i] = {name: XLSX.utils.encode_col(i), key: i}
  return o
}



/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/

// eslint-disable-next-line react/prop-types
function DragDropFile({handleFile, children}) {
  const suppress = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleDrop = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const {files} = e.dataTransfer
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <div
      onDrop={handleDrop}
      onDragEnter={suppress}
      onDragOver={suppress}
    >
      {children}
    </div>
  )
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
// eslint-disable-next-line react/prop-types
function DataInput({handleFile}) {
  const handleChangeFile = (e) => {
    const {files} = e.target
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <form className="form-inline">
      <div className="form-group">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="file">Drag or choose a spreadsheet file</label>
        <br />
        <input
          type="file"
          className="form-control"
          id="file"
          accept={SheetJSFT}
          onChange={handleChangeFile}
        />
      </div>
    </form>
  )
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
// eslint-disable-next-line react/prop-types
function OutTable({data, cols}) {
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>{cols.map((c) => <th key={c.key}>{c.name}</th>)}</tr>
        </thead>
        <tbody>
          {data.map((r, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              {cols.map((c) => <td key={c.key}>{r[c.key]}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface Props<Type> {
  sx?: Type
  rows?: Type[]
  onImportData?: (rows: Type[]) => void
  filename?: string
}

export default function XlsxControl<Type>(props: Props<Type>) {
  const { sx, rows, onImportData, filename = 'sheet'} = props

  const [data, setData] = useState([])
  const [cols, setCols] = useState([])

  const [selectedFilename, setSelectedFilename] = useState('선택한 파일이 없음')

  const handleFile = (file) => {
    console.log(file)
    const reader = new FileReader()
    reader.onload = (e) => {
      /* Parse data */
      const ab = e.target.result
      const wb = XLSX.read(ab, {type: 'array'})
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data0 = XLSX.utils.sheet_to_json(ws, {header: 1})
      /* Update state */
      setData(data0)
      setCols(make_cols(ws['!ref']))

      console.log('> imported Data: ', data0)
      let rowCount = data0.length
      for (let i = 0; i < data0.length; ++i) {
        // @ts-ignore
        if (data0[i].length === 0 || data0[i][0] === '') {
          rowCount = i
          break
        }
      }

      console.log(`rows(${data0.length}) valid rowCount(${rowCount}) `)
      const finalRows = data0.slice(0, rowCount)
      console.log('pre final rows: ', finalRows)
      // @ts-ignore
      const columns = finalRows[0].length
      const calculateExcelbod = (excelSerialDate) => new Date((excelSerialDate - (25567 + 2)) * 86400 * 1000);
      for (let i = 0; i < finalRows.length; ++i) {
        if (`${finalRows[i][4]}`.length === 5) {
          const date = calculateExcelbod(finalRows[i][4])
          console.log(date)
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          // @ts-ignore
          finalRows[i][4] = `${year}-${month <= 9 ? `0${month}` : month}-${day <= 9 ? `0${day}` : day}`
        }

        // @ts-ignore
        for (let j = finalRows[i].length; j < columns; ++j) {
          // @ts-ignore
          finalRows[i].push('')
        }
      }
      console.log('final rows: ', finalRows)

      // @ts-ignore
      onImportData(finalRows)
      setSelectedFilename(file.name)
    }
    reader.readAsArrayBuffer(file)
  }

  // const exportFile = (dataToExp: []) => {
  function exportFile<Type0>(dataToExp: Type0[]) {
    console.log('data to export: ', dataToExp || data)
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(dataToExp || data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${filename}.xlsx`)
  }

  const handleChangeFile = (e) => {
    const {files} = e.target
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <DragDropFile handleFile={handleFile}>
      {
        onImportData && (
          <Box>
            <Button variant="outlined" component="label">
              {/* <img src="/static/icons/excel.svg" alt="Download Excel" /> */}
              파일찾기
              <input
                type="file"
                hidden
                className="form-control"
                id="file"
                accept={SheetJSFT}
                onChange={(handleFile0) => handleChangeFile(handleFile0)}
              />
            </Button>
            <Button><Typography>{selectedFilename}</Typography></Button>
          </Box>
        )
       }
      <div className="row">
        <div className="col-xs-12">
          {rows
          && (
          <Button sx={sx} variant="outlined" onClick={() => exportFile(rows)} disabled={!(rows.length && rows.length > 0)}>
            {/* <Box component="img" sx={{height: 28, width: 30}} src="/static/icons/excel.svg" /> */}
            엑셀 다운로드
          </Button>
          )}
        </div>
      </div>
      {/* <div className="row"> */}
      {/*  <div className="col-xs-12"> */}
      {/*    <OutTable data={data} cols={cols} /> */}
      {/*  </div> */}
      {/* </div> */}
    </DragDropFile>
  )
}

// if(typeof module !== 'undefined') module.exports = SheetJSApp




