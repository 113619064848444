import {Typography} from '@material-ui/core'
import React from 'react'

interface Props {
  title: string
  required?: boolean
}

export function FormItemTitleA({title, required = true}: Props) {
  const REQUIRED_COLOR = '#FF000080'
  return (
    <Typography variant="subtitle2" sx={{ marginBottom: 1, mt: 2 }} fontWeight={700}>
      <span>{title}</span>
      {required && <span style={{color: REQUIRED_COLOR}}>{' *'}</span>}
    </Typography>
  )
}

export function FormItemTitleB({title, required = true}: Props) {
  const REQUIRED_COLOR = '#FF000080'
  return (
    <Typography variant="subtitle2" sx={{ marginBottom: 1, mt: 0 }} fontWeight={700}>
      <span>{title}</span>
      {required && <span style={{color: REQUIRED_COLOR}}>{' *'}</span>}
    </Typography>
  )
}
