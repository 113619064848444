import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography
} from '@material-ui/core';
import useAuth from '../../../../hooks/useAuth';
import useMounted from '../../../../hooks/useMounted';
import {myRegexId, myRegexPassword4, myRegexPhone} from '../../../consts'
import { Navigation } from '@material-ui/icons';

// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = '유효한 email 주소를 넣어야 됨 ~ ';
//   }

const LoginJWT: FC = (props) => {
  const mounted = useMounted();
  const { login } = useAuth() as any;

  const idMsg = '4~20자 영문,숫자를 사용해주세요.'
  const pwMsg = '4~20자 영문,숫자, 특수문자를 사용해주세요.'

  return (
    <Formik
      initialValues={{
        email: '', // demo@devias.io',
        password: '', // 'Password123!',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            email: Yup.string()
              // .matches(myRegexId, idMsg)
              .min(4, idMsg)
              .max(20, idMsg)
              .required('아이디를 입력해 주세요.'),
            password: Yup.string()
              // .matches(myRegexPassword4, pwMsg)
              .min(4, pwMsg)
              .max(20, pwMsg)
              .required('비밀번호를 입력해 주세요.')
          })
      }
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          await login(values.email, values.password);
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          {/* <Typography>Login-JWT !!!</Typography> */}
          <TextField
            size="small"
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="아이디"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            placeholder="아이디"
          />
          <TextField
            size="small"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="비밀번호"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            placeholder="비밀번호"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              로그인
            </Button>
          </Box>
          {/* <Box sx={{ mt: 2 }}> */}
          {/*  <Alert severity="info"> */}
          {/*    <div> */}
          {/*      Use */}
          {/*      {' '} */}
          {/*      <b>demo@devias.io</b> */}
          {/*      {' '} */}
          {/*      and password */}
          {/*      {' '} */}
          {/*      <b>Password123!</b> */}
          {/*    </div> */}
          {/*  </Alert> */}
          {/* </Box> */}
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
