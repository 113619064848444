import {
  Avatar,
  Box,
  Button,
  Container, DialogTitle, Dialog, DialogContent,
  Paper,
  Typography,
  DialogProps, TextField, ToggleButtonGroup, ToggleButton
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';
import {useState, MouseEvent, useEffect} from 'react';
import {apiClient} from '../../../api/common'
import {toast} from 'react-hot-toast'
import {
  getDateYyyyMmDd, getDateYyyyMmDdHhMmSs
} from '../../../utils/serverTools'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  userList: any[]
  time: string
  setTime: (time: string) => void
}

const dateOptions = [
  {id: '1', label: '1개월'},
  {id: '2', label: '2개월'},
  {id: '3', label: '3개월'},
  {id: '4', label: '6개월'}
]

const handleOnKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    // onSubmit()
  }
}

export default function ServiceEndTimeModal(props: Props) {
  //
  const { open, setOpen, userList, time, setTime } = props
  const navigate = useNavigate()
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('xs')
  const [alignment, setAlignment] = useState<any>(dateOptions[0]);
  const [errorMessage, setErrorMessage] = useState('')

  const regexDate = /^\d{4}-\d{2}-\d{2}$/;

  useEffect(() => {
    console.log(userList)
  }, [])

  const handleCloseModal = () => {
    setTime('')
    setOpen(false)
    setAlignment('')
  }

  const serviceEndTimeUpdate = async (config) => {
    const url = '/update_silver_service_end_time'
    if (!regexDate.test(time)) {
      toast.error('연도-월-일 형식에 맞게 입력해주세요.')
      return;
    }
    const reqData = {
      user_list: userList,
      service_end_time: time ? `${time} 23:59:59` : ''
    }
    console.log(reqData)
    const res = await apiClient.post(url, reqData, config)
    if (res.data.state === 'ok') {
      toast.success('서비스 종료일이 업데이트 되었습니다.')
    } else toast.error('서비스 종료일 업데이트에 실패했습니다.')
    handleCloseModal();
    window.location.reload()
  }

  const handleChangedate = (e) => {
    setTime(e.target.value)
  }

  const DATETYPE = {
    MONTH: 1, TWOMONTH: 2, THREEMONTH: 3, SIXMONTH: 4
  }
  const handleDateOptionsChange = (
    event: MouseEvent<HTMLElement>,
    datetype: string,
  ) => {
    const today = new Date();
    setAlignment(datetype);
    const type = parseInt(datetype, 10);
    switch (type) {
      case DATETYPE.MONTH:
        today.setMonth(today.getMonth() + 1)
        break;
      case DATETYPE.TWOMONTH:
        today.setMonth(today.getMonth() + 2)
        break;
      case DATETYPE.THREEMONTH:
        today.setMonth(today.getMonth() + 3)
        break;
      case DATETYPE.SIXMONTH:
        today.setMonth(today.getMonth() + 6)
        break;
      default:
        break;
    }
    setTime(getDateYyyyMmDd(today))
  }

  return (
    <Dialog fullWidth={fullWidth} maxWidth="md" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        서비스 종료일 연장
        <CloseIcon onClick={handleCloseModal} style={{ cursor: 'pointer', marginLeft: '600px' }} />
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }} sx={{cursor: 'pointer', mr: 2}}
              id="date" value={time} onChange={(e) => { handleChangedate(e) }} onKeyPress={handleOnKeyPress} inputProps={{ max: '2999-12-31' }}
            />
            {/* <TextField size="small" type="text" sx={{cursor: 'pointer', mr: 2}} value={time} onChange={(e) => { handleChangedate(e) }} /> */}
            <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleDateOptionsChange} onKeyPress={handleOnKeyPress}>
              {
                dateOptions.map((item) => (
                  <ToggleButton sx={{width: 72}} value={item.id} key={item.id}>{item.label}</ToggleButton>
                ))
              }
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box justifyContent="center" sx={{px: 8}} />
        <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', my: 1, mt: 2}}>
          <Button variant="outlined" sx={{m: 1, mt: 2}} onClick={serviceEndTimeUpdate}>연장하기</Button>
          <Button variant="outlined" sx={{m: 1, mt: 2}} color="secondary" onClick={handleCloseModal}>닫기</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
