export type SpeakerStatus =
  | 'stored' // 입고
  | 'released' // 출고
  | 'as' // AS
  | 'used' // 중고
  | 'disuse' // 폐기

export type AiSpeakerStatus =
  | '정상' // ???
  | '입고'
  | '출고'
  | 'AS'
  | '중고'
  | '폐기'

export interface AiSpeakerHistory {
  ai_speaker_id: string
  ai_speaker_sn: string
  speaker_status: string
  speaker_use_start_date: string
  speaker_use_end_date: string
  speaker_is_certified: string
}

export interface AiSpeaker {
  // ai_speaker_id: string // ":"001",
  // ai_speaker_created_date: string // ":"2021-10-07 14:10:09",
  // ai_speaker_password: string // ":"1234",
  // ai_speaker_sn: string // ":"001",
  // ai_speaker_model_name: string // ":"model_name",
  // ai_speaker_make_date: string // ":"2001.01.01",
  // ai_speaker_macaddress: string // ":"adfewfwefwf",
  // ai_speaker_status: string // ":"정상",
  // ai_speaker_network_status: string // ":"연결",
  // ai_speaker_certificate_yn: string // ":"인증",
  // ai_speaker_remark: string // ":"테스트기기",
  // ai_speaker_user_aggrement_yn: string // ":"동의",
  // ai_speaker_user_aggrement_date: string // ":"2001.01.01
  ai_speaker_history: AiSpeakerHistory[]
  ai_speaker_certificate_yn: string // ""
  ai_speaker_created_date: string //  "2021-12-03 06:40:29"
  ai_speaker_id: string //  "ㅓㅗㅓㅓ"
  cpe_installer_id: string //  ""
  ai_speaker_macaddress: string //  "ㅓㅓ"
  ai_speaker_make_date: string //  ""
  ai_speaker_model_name: string //  "IF-H1100"
  ai_speaker_network_status: string //  ""
  ai_speaker_password: string //  "ㅓㅓㅓ"
  ai_speaker_regist_writer_id: string //  ""
  ai_speaker_regist_writer_name: string //  ""
  ai_speaker_remark: string //  ""
  ai_speaker_sn: string //  "ㅓㅓ"
  ai_speaker_status: string //  "입고"
  ai_speaker_user_aggrement_date: string //  ""
  ai_speaker_user_aggrement_yn: string //  ""
  ai_speaker_alive_check_time:string
  ai_speaker_fw_ver:string//
  admin_id: string
  silver_id: string
  cpe_id: string
  cpe_tel: string
  cpe_reg_date: string
  wifi_sn: string
  wifi_pw: string
  wifi_model :string
}

export const initialValuesAiSpeaker: AiSpeaker = {
  ai_speaker_certificate_yn: '', // string // ""
  ai_speaker_created_date: '', //  string //  "2021-12-03 06:40:29"
  ai_speaker_id: '', //  string //  "ㅓㅗㅓㅓ"
  cpe_installer_id: '', //  string //  ""
  ai_speaker_macaddress: '', //  string //  "ㅓㅓ"
  ai_speaker_make_date: '', //  string //  ""
  ai_speaker_model_name: 'IF-H1100', //  string //  "IF-H1100"
  ai_speaker_network_status: '', //  string //  ""
  ai_speaker_password: '', //  string //  "ㅓㅓㅓ"
  ai_speaker_regist_writer_id: '', //  string //  ""
  ai_speaker_regist_writer_name: '', //  string //  ""
  ai_speaker_remark: '', //  string //  ""
  ai_speaker_sn: '', //  string //  "ㅓㅓ"
  ai_speaker_status: '', //  string //  "입고"
  ai_speaker_user_aggrement_date: '', //  string //  ""
  ai_speaker_user_aggrement_yn: '', //  string //  ""
  ai_speaker_alive_check_time: '',
  ai_speaker_fw_ver: '',
  admin_id: '',
  silver_id: '',
  ai_speaker_history: [],
  cpe_id: '',
  cpe_tel: '',
  cpe_reg_date: '',
  wifi_sn: '',
  wifi_pw: '',
  wifi_model: ''
}

export interface SpeakerAddData {
  modelName: string
  speakerId: string
  speakerPassword: string
  serialNumber: string
  macAddress: string
  createdAt: string
  status: string // SpeakerStatus
}

// export type SpeakerStatus =
//   | 'stored' // 입고
//   | 'released' // 출고
//   | 'as' // AS
//   | 'used' // 중고
//   | 'disuse' // 폐기
export function speakerStatusString(enumStr: string): string {
  switch (enumStr) {
    case 'stored':
      return '입고'
    case 'released':
      return '출고'
    case 'as':
      return 'AS'
    case 'used':
      return '중고'
    case 'disuse':
      return '폐기'
    default:
      return '입고'
  }
}

export interface ReqAiSpeakerCreate {
  ai_speaker_id: string // " : "010o30333",
  ai_speaker_password: string // "  : "oo",
  ai_speaker_sn: string // "  : "oo",
  ai_speaker_model_name: string // "  : "oo",
  ai_speaker_make_date: string // "  : "oo",
  ai_speaker_macaddress: string // "  : "oo",
  ai_speaker_status: string // "  : "oo",
  ai_speaker_network_status: string // "  : "oo",
  ai_speaker_certificate_yn: string // "  : "oo",
  ai_speaker_remark: string // "  : "oo",
  ai_speaker_user_aggrement_yn: string // "  : "oo",
  ai_speaker_user_aggrement_date: string // "  : "oo"
}

export interface SpeakerStockData {
  state: string // ": "ok",
  full_cnt: string // ": "8",
  stock_cnt: string // ": "6",
  sail_cnt: string // ": "1",
  as_cnt: string // ": "0",
  used_cnt: string // ": "0",
  release_cnt: string // t": "0"
}

export const initialValuesSpeakerStock: SpeakerStockData = {
  state: '', // : string // ": "ok",
  full_cnt: '', // : string // ": "8",
  stock_cnt: '', // : string // ": "6",
  sail_cnt: '', // : string // ": "1",
  as_cnt: '', // : string // ": "0",
  used_cnt: '', // string // ": "0",
  release_cnt: ''
}

export interface SpeakerSearchFilter {
  from_date: string,
  end_date: string,
  search_content: string,
  search_item: string,
  ai_speaker_status: string,
  use_yn: string
}

export const initSpeakerSearchFilter:SpeakerSearchFilter = {
  from_date: '',
  end_date: '',
  search_content: '',
  search_item: '',
  ai_speaker_status: '전체',
  use_yn: '전체'
}

export const SEARCH_TYPE = {
  SILVER_CONNECT: 'SILVER_CONNECT',
  ONLY_SPEAKER: 'ONLY_SPEAKER'
}
