import {useState} from 'react'
import {
  Avatar,
  Box,
  Button,
  Container, DialogTitle, Dialog, DialogContent,
  Paper,
  Typography,
  DialogProps, CardActions,
  FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, TextField
} from '@material-ui/core'
import {alpha} from '@material-ui/core/styles'
import CheckIcon from '../../../../icons/Check'
import * as React from 'react'
import {apiClient} from '../../../api/common'
import {toast} from 'react-hot-toast'
import {currentTimeForApi} from '../../../utils/serverTools'
import {useFormik} from 'formik'
import { addHistory, historyType } from 'src/__CareWell__/utils/history'


interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  userId: string
  rowId: string
  onFinish: (success:boolean) => void
  onNeedTableUpdate?: (update:boolean) => void
}

const deleteReasonOptions = [
  '대상자 중도 포기로 인한 개인정보 삭제 목적',
  '대상자 사망으로 인한 개인정보 삭제 목적',
  '기타'
]

export default function RecipientDeleteModal(props: Props) {
  //
  const { open, setOpen, userId, rowId, onFinish, onNeedTableUpdate } = props
  const [fullWidth, setFullWidth] = React.useState(true)
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm')

  const requestInitPassword = async (values) => {
    const radio = values.reasonRadio
    let finalMsg = deleteReasonOptions[0]
    if (radio === '2') {
      // eslint-disable-next-line prefer-destructuring
      finalMsg = deleteReasonOptions[1]
    } else if (radio === '3') {
      finalMsg = values.reasonEtc
    }

    const reqData = {
      silver_id: rowId,
      silver_deleted_reason: finalMsg,
      silver_deleted_reason_detail: '',
      silver_deleted_date: currentTimeForApi(),
      silver_admin_id_delete_action: userId
    }
    // console.log('reqData: ', reqData)
    try {
      const url = '/delete_silver'
      console.log(`[API Req-${url}]: `, reqData)
      const res = await apiClient.post(url, reqData)
      console.log(`[API ${url}]: `, res.data)

      if (res.data.state === 'ok') {
        toast.success('해당 유저가 삭제되었습니다.')
        await addHistory(reqData.silver_id, null, reqData, historyType.RESET_SILVER_PW);
      } else toast.error('삭제에 실패하였습니다.')

      onFinish(res.data.state === 'ok')
    } catch (err) {
      console.error(err)
    }

    setOpen(false)
  }

  const initialValues = {
    reasonRadio: '1',
    reasonEtc: ''
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      // console.log('submit: ', values)
      await requestInitPassword(values)
      formik.setValues(initialValues)
    }
  })

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={() => setOpen(false)}>
      <DialogTitle />
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">사유 선택</FormLabel>
            <RadioGroup name="reasonRadio" onChange={formik.handleChange} value={formik.values.reasonRadio}>
              <FormControlLabel value="1" control={<Radio />} label={deleteReasonOptions[0]} />
              <FormControlLabel value="2" control={<Radio />} label={deleteReasonOptions[1]} />
              <FormControlLabel value="3" control={<Radio />} label={deleteReasonOptions[2]} />
            </RadioGroup>
          </FormControl>
          <TextField variant="outlined" multiline rows={6} fullWidth
            name="reasonEtc" placeholder="기타 사유를 입력 해 주세요.  최대 500자 등록 가능합니다."
            onChange={formik.handleChange} value={formik.values.reasonEtc}
            sx={{mt: 2, mb: 1}}
          />
        </DialogContent>
        <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', my: 1, mt: 2}}>
          <Button variant="outlined" sx={{m: 1, }} type="submit">저장</Button>
          <Button variant="outlined" sx={{m: 1, mr: 5}} color="secondary" onClick={() => setOpen(false)}>취소</Button>
        </Box>
      </form>
    </Dialog>
  )
}
