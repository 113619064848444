import type { Theme } from '@material-ui/core'
import { Box, Divider, Drawer } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Logo from '../../../components/Logo'
import Scrollbar from '../../../components/Scrollbar'
import useAuth from '../../../hooks/useAuth'
import { AccountType, NAVBAR_Y_PX, SIDEBAR_X } from '../../consts'
import { currentSidebarSection } from '../../serviceCategories'
import NavItem from '../NavItem'
import NavSection3Depth from '../NavSection3Depth'
import { carewellAuthApi } from 'src/__CareWell__/api/carewellAuthApi'


interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

// 사이드바
const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const {onMobileClose, openMobile} = props
  const location = useLocation()
  const {user} = useAuth()
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Scrollbar options={{suppressScrollX: true}}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{p: 1}}>
          {
            currentSidebarSection().map((section) => (
              <NavSection3Depth
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            ))
          }
          {isOperator && (
            <NavItem
              depth={0}
              title="웨어러블"
              url="https://inzicare-dev.carewellplus.co.kr/"
            />
          )}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: `${NAVBAR_Y_PX}px !Important`, // '64px !Important',
            width: SIDEBAR_X // 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: SIDEBAR_X // 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default DashboardSidebar
