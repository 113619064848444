import { carewellAuthApi } from '../api/carewellAuthApi';
import { apiClient } from '../api/common';
import { AccountType } from '../consts';
import { getCookie, setCookie } from './cookieTools';

const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator;
const isHCenter = carewellAuthApi.getAccountType() === AccountType.HCenterManager;
const isMunicipality = carewellAuthApi.getAccountType() === AccountType.MunicipalityManager;
const isInstaller = carewellAuthApi.getAccountType() === AccountType.InstallManager;
const isCallcenter = carewellAuthApi.getAccountType() === AccountType.CallCenterManager;

export const checkToken = async (logout, navigate) => {
  let reqType;
  let postfix = '';
  if (isOperator) {
    reqType = 'operator';
    postfix = '_admin';
  } else if (isHCenter) {
    reqType = 'hcenter';
    postfix = '_public_care_admin';
  } else if (isMunicipality) {
    reqType = 'municipality';
    postfix = '_municipality';
  } else if (isInstaller) {
    reqType = 'installer';
    postfix = '_admin';
  } else if (isCallcenter) {
    reqType = 'callcenter';
    postfix = '_admin';
  }
  const accessToken = getCookie('access_token');
  const refreshToken = getCookie('refresh_token');
  if (!accessToken || !refreshToken) {
    await logout();
    await carewellAuthApi.logout();
    navigate('/');
  }
  const checkTokenUrl = `/check_token${postfix}?req_type=${reqType}`;
  const config = {
    headers: {
      'x-access-token': accessToken,
      'x-refresh-token': refreshToken,
    },
  };
  const preRes = await apiClient.post(checkTokenUrl, {}, config);
  const {state} = preRes.data
  if (state === 'reissue') {
    setCookie('access_token', preRes.data.token, 1);
    config.headers['x-access-token'] = preRes.data.token;
  } else if (state === 'fail') {
    const createRefreshTokenUrl = '/create_refresh_token';
    const res2 = await apiClient.post(createRefreshTokenUrl, {id: carewellAuthApi.getAdminId()});
    const tken = res2.data.result;
    setCookie('refresh_token', tken, 1);
    config.headers['x-refresh-token'] = tken;
  }
  return config;
};
