/* eslint-disable no-nested-ternary */
import { Box, Container } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import useMounted from '../../../hooks/useMounted'
import useSettings from '../../../hooks/useSettings'
import gtm from '../../../lib/gtm'
import {apiClient } from '../../api/common'
import { AccountType, CONTENT_LAYOUT_PY, PAGE_LIMITS } from '../../consts'
// import {HCenterManager} from '../../types/user'
// import UserListSearch from '../../components/dashboard/users/UserListSearch'
// import HCenterManagerListTable from '../../components/dashboard/users/HCenterManagerListTable'
// import {hCenterManagerSearchOptions} from '../../genderSelectOptions'
import { useLocation, useNavigate } from 'react-router'
import { carewellAuthApi } from '../../api/carewellAuthApi'
import PageHeader from '../../components/dashboard/PageHeader'
import SpeakerListSearch from '../../components/dashboard/speakers/SpeakerListSearch'
import SpeakerOverviewNumbers from '../../components/dashboard/speakers/SpeakerOverviewNumbers'
import SpeakerOverviewTable from '../../components/dashboard/speakers/SpeakerOverviewTable'
import { speakerSearchOptions } from '../../options'
import { AiSpeaker, initialValuesSpeakerStock, initSpeakerSearchFilter, SpeakerSearchFilter, SpeakerStockData } from '../../types/speaker'
import { speakerStatusHCenterOptions, speakerStatusOptions } from '../../typesServiceDesign'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import useAuth from 'src/hooks/useAuth'
import { setCookie } from 'src/__CareWell__/utils/cookieTools'
import SpeakerListSearchV2 from 'src/__CareWell__/components/dashboard/speakers/SpeakerListSearchV2'
import { SearchFilter } from 'src/__CareWell__/types/user';
import SpeakerOverviewTableV2 from 'src/__CareWell__/components/dashboard/speakers/SpeakerOverviewTableV2'
import MyRowsPerPage from 'src/__CareWell__/components/MyRowsPerPage'
import styled from 'styled-components'
import { DotLoader } from 'react-spinner-overlay'

const sheetHeader = ['지역', '보건소', '이름', '직군', '관리자ID', '휴대폰번호', '이메일', '최근접속']
const sheetKeyNames = ['public_care_address', 'public_care_company_name', 'public_care_username', 'public_care_job', 'public_care_admin_id', 'public_care_tel', 'public_care_email', 'public_care_recent_connection_time']
const sortColumns = [
  'ai_speaker_model_name', 'ai_speaker_sn', 'ai_speaker_certificate_yn'
]

interface LocationState {
  prevPath: string
}

const Loading = styled.div`
  width:100%;
  height:100%;
  min-height: 400px;
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:white;
  opacity: 0.8;
  z-index:999;
`

export default function SpeakerOverviewV2() {
  const navigate = useNavigate()
  const {logout} = useAuth()
  const { settings } = useSettings()
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator

  const [searchFilter, setSearchFilter] = useState<SpeakerSearchFilter>(initSpeakerSearchFilter);
  const [aiSpeakerList, setAiSpeakerList] = useState<AiSpeaker[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(PAGE_LIMITS[1])
  const [loading, setLoading] = useState(false)
  const [allCount, setAllCount] = useState(0)

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  const getListCount = async (config) => {
    try {
      const url = '/get_silver_ai_speaker_list_count_v2'
      const reqData = {
        user_id: carewellAuthApi.loggedInUser.id,
        req_type: carewellAuthApi.getAccountType() === AccountType.Operator ? 'operator' : carewellAuthApi.getAccountType() === AccountType.HCenterManager ? 'hcenter' : 'municipality',
        user_status: '등록',
        ...searchFilter
      }
      reqData.use_yn = reqData.use_yn === '전체' ? '' : reqData.use_yn;
      reqData.ai_speaker_status = reqData.ai_speaker_status === '전체' ? '' : reqData.ai_speaker_status
      const res = await apiClient.post(url, reqData, config)
      if (res.data.state === 'ok') {
        setAllCount(res.data.result.count);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getList = async (config) => {
    setLoading(true);
    try {
      const url = `/get_silver_ai_speaker_list_v2/${page}/${limit}`;
      const reqData = {
        user_id: carewellAuthApi.loggedInUser.id,
        req_type: carewellAuthApi.getAccountType() === AccountType.Operator ? 'operator' : carewellAuthApi.getAccountType() === AccountType.HCenterManager ? 'hcenter' : 'municipality',
        user_status: '등록',
        ...searchFilter
      };
      reqData.use_yn = reqData.use_yn === '전체' ? '' : reqData.use_yn;
      reqData.ai_speaker_status = reqData.ai_speaker_status === '전체' ? '' : reqData.ai_speaker_status;
      console.log(reqData)
      const res = await apiClient.post(url, reqData, config);
      if (res.data.state === 'ok') {
        setAiSpeakerList(res.data.result);
      }
    } catch (error) {
      // 오류 처리
      console.error(error);
    } finally {
      // 타이머 해제 및 setLoading(false) 호출
      // clearTimeout(timerId);
      setLoading(false);
    }
  };


  const doUpdateTable = () => {
    checkToken(logout, navigate).then((config) => {
      getList(config)
    });
  }

  useEffect(() => {
    doUpdateTable()
  }, [page, limit])

  useEffect(() => { // 검색 버튼을 누른 최초1회 동작
    checkToken(logout, navigate).then((config) => {
      setPage(0)
      getListCount(config)
      getList(config)
    });
  }, [searchFilter])

  useEffect(() => {
    checkToken(logout, navigate).then((config) => {
      getListCount(config);
      getList(config)
    });
  }, []);

  const paths = isOperator ? ['홈', '스피커 관리', '스피커 현황'] : ['홈', '스피커 현황']

  return (
    <>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <PageHeader title="스피커 현황" paths={paths} links={['/', '', '']} />
          <Box sx={{mt: 3}}>
            {/* <Box sx={{my: 2}}>
              <SpeakerOverviewNumbers stock={stock} fullData={isOperator} />
            </Box> */}

            <SpeakerListSearchV2 setSearchFilter={setSearchFilter} statusOptions={isOperator ? speakerStatusOptions : speakerStatusHCenterOptions} />

            <Box sx={{my: 2}} />
            <Box sx={{position: 'relative'}}>
              {loading && <Loading><DotLoader loading={loading} size={10} /></Loading>}
              <SpeakerOverviewTableV2
                loading={loading} doUpdateTable={doUpdateTable} aiSpeakerList={aiSpeakerList} page={page} setPage={setPage}
                allCount={allCount} limit={limit} setLimit={setLimit}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}


