import type {FC} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import {AppBar, Box, Button, CardMedia, IconButton, Toolbar} from '@material-ui/core'
import {experimentalStyled} from '@material-ui/core/styles'
import type {AppBarProps} from '@material-ui/core'
import MenuIcon from '../../../icons/Menu'
import AccountPopover from '../../../components/dashboard/AccountPopover'
import ContactsPopover from '../../../components/dashboard/ContactsPopover'
import ContentSearch from '../../../components/dashboard/ContentSearch'
import LanguagePopover from '../../../components/dashboard/LanguagePopover'
import Logo from '../../../components/Logo'
import NotificationsPopover from '../../../components/dashboard/NotificationsPopover'
import {SIDEBAR_X, NAVBAR_COLOR, AccountType} from '../../consts'
import {ButtonChatbot, ButtonVitalReaction, ButtonLogout, ButtonUser} from './DashboardNavbarButtons'
import React from 'react'
import {carewellAuthApi} from '../../api/carewellAuthApi'




const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({theme}) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
)

// https://s3.ap-northeast-2.amazonaws.com/mys3.jeneri.net/test/logo.png

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const {onSidebarMobileOpen, ...other} = props

  const navigate = useNavigate()

  const handleEditProfile = () => {
    console.log('개인 정보 변경')

    navigate(`/dashboard/users/hcenter-managers/${carewellAuthApi.loggedInUser.id}/edit-profile`)
    //  navigate('/dashboard/users/hcenter-managers/hcenter001/edit')
  }

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{minHeight: 64, bgcolor: NAVBAR_COLOR}}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{display: {lg: 'none'}}}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/" onClick={() => {
          console.log(window.location.pathname);
          if (window.location.pathname === '/') {
            window.location.reload()
          }
          // 루트로 가서 이전 페이지를 reload 하는 문제가 있음
        }}
        >
          <CardMedia component="img" image="/static/logo.svg" alt="Live from space album cover"
            sx={{display: {lg: 'inline', xs: 'none'}, height: 22, ml: 1}}
          />
        </RouterLink>
        {/* <Box sx={{ml: '60px'}}><ButtonChatbot /></Box> */}
        {/* <Box sx={{ml: 8}}><ButtonVitalReaction /></Box> */}
        <Box sx={{flexGrow: 1, ml: 2}} />
        {/* <LanguagePopover /> */}
        {/* <Box sx={{ml: 1}}><ContentSearch /></Box> */}
        {/* <Box sx={{ml: 1}}><ContactsPopover /></Box> */}
        {/* <Box sx={{ml: 1}}><NotificationsPopover /></Box> */}
        {/* <Box sx={{ml: 1}}><AccountPopover /></Box> */}
        <Box sx={{ml: 1}}><ButtonUser /></Box>
        {
          carewellAuthApi.getAccountType() === AccountType.HCenterManager
          && <Button sx={{color: 'primary.contrastText'}} size="small" onClick={handleEditProfile}>개인정보변경</Button>
        }
        <Box sx={{ml: 1}}><ButtonLogout /></Box>
      </Toolbar>
    </DashboardNavbarRoot>
  )
}

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
}

export default DashboardNavbar
