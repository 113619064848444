import {ChangeEvent, MouseEvent, useCallback, useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Pagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  LinearProgress,
  CircularProgress,
  debounce
} from '@material-ui/core'
import Scrollbar from '../../../../components/Scrollbar'
import {AccountType, CSS_CENTER, PAGE_LIMITS, RIGHT_VERTICAL_BAR_COUNT} from '../../../consts'
import MyRowsPerPage from '../../MyRowsPerPage'
import {applyPagination, applyStringSortG, handleClickSort} from '../../../utils/table'
import {getSortIcon, MyTableA, SX_TABLE_HEADER_A} from '../../../commonStyles'
import {carewellAuthApi} from '../../../api/carewellAuthApi'
import { AiSpeaker } from '../../../types/speaker';
import SpeakerStatusEditModal from '../../widgets/modals/SpeakerStatusEditModal'
import SpeakerAssignModal from '../../widgets/modals/SpeakerAssignModal'
import SpeakerStatusEditModalV2 from '../../widgets/modals/SpeakerStatusEditModalV2'
import XlsxControlJSON from '../../widgets/modals/XlsxControlJSON'


const baseTableUrl = '/dashboard/users/recipients'

const HEIGHT = 72
const TITLE_WIDTH = 100

interface Props {
  limit : number,
  setLimit : (number) => void,
  allCount : number,
  page: number,
  setPage : (number) => void,
  aiSpeakerList: AiSpeaker[],
  doUpdateTable: ()=>void,
  loading: boolean
}

// 스피커 현황
export default function SpeakerListTableV2(props: Props) {
  const {doUpdateTable, limit, setLimit, allCount, page, setPage, aiSpeakerList, loading} = props;
  const navigate = useNavigate()
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const [popupSpeakerStatusEdit, setPopupSpeakerStatusEdit] = useState(false)
  const [currentItem, setCurrentItem] = useState<AiSpeaker>()
  // const [selectedRows, setSelectedRows] = useState<AiS>('')
  const [paginationDisabled, setPaginationDisabled] = useState<boolean>(false)
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [sheetRows, setSheetRows] = useState<any[]>([])
  const onRowsPerPageChange = useCallback((limitNumber: number): void => {
    debounce((event, value) => {
      setAllChecked(false)
      setSheetRows([])
      setSelectedRows([])
      setLimit(limitNumber)
      setPage(0) // 페이지 rows 수 변경 시 1페이지부터 보여줌
    }, 200) /// 페이징 더블클릭시 에러 있음
  }, [])

  const handleCellClick = useCallback(
    (item: AiSpeaker) => {
      setCurrentItem(item)
      setPopupSpeakerStatusEdit(true)
    }, [setCurrentItem]
  );

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    if (newPage != null) {
      setPage(newPage - 1)
      setAllChecked(false)
      setSheetRows([])
      setSelectedRows([])
    }
  }
  const handleChangeAllChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const result = event.target.checked
    setAllChecked(result);
    const temp:string[] = [];
    const sheet = [];
    if (result) {
      for (let idx = 0; idx < aiSpeakerList.length; idx++) {
        temp.push(aiSpeakerList[idx].ai_speaker_id)
        // const sheetPiece = {
        //   "모델명": aiSpeakerList[idx].ai_speaker_model_name,
        // }
        const sheetPiece = {}
        sheetPiece['모델명'] = aiSpeakerList[idx].ai_speaker_model_name
        sheetPiece['시리얼번호'] = aiSpeakerList[idx].ai_speaker_id
        sheetPiece['맥'] = aiSpeakerList[idx].ai_speaker_macaddress
        sheetPiece['제조년월'] = aiSpeakerList[idx].ai_speaker_make_date
        sheetPiece['기기상태'] = aiSpeakerList[idx].ai_speaker_status
        sheetPiece['실시간사용일시'] = aiSpeakerList[idx].ai_speaker_alive_check_time
        sheetPiece['입고등록일시'] = aiSpeakerList[idx].ai_speaker_regist_writer_id
        sheet.push(sheetPiece)
      }
    }
    setSheetRows(sheet)
    setSelectedRows(temp)
  };
  const handleChangeChecked = (event: ChangeEvent<HTMLInputElement>, item:AiSpeaker):void => {
    const result = event.target.checked;
    let temp = [...selectedRows];
    let sheettemp = [...sheetRows]
    if (result) {
      temp.push(item.ai_speaker_id)
      const sheetPiece = {}
      sheetPiece['모델명'] = item.ai_speaker_model_name
      sheetPiece['시리얼번호'] = item.ai_speaker_id
      sheetPiece['맥'] = item.ai_speaker_macaddress
      sheetPiece['제조년월'] = item.ai_speaker_make_date
      sheetPiece['기기상태'] = item.ai_speaker_status
      sheetPiece['실시간사용일시'] = item.ai_speaker_alive_check_time
      sheetPiece['입고등록일시'] = item.ai_speaker_regist_writer_id
      sheettemp.push(sheetPiece)
      setSelectedRows(temp)
      setSheetRows(sheettemp)
    } else {
      temp = temp.filter((sn) => sn !== item.ai_speaker_id);
      sheettemp = sheettemp.filter((piece) => piece['시리얼번호'] !== item.ai_speaker_id)
      setSelectedRows(temp)
      setSheetRows(sheettemp)
    }

    if (temp.length === limit) {
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }
  }

  useEffect(() => {
    // setSelectedRow('')
    setCurrentItem(null)
  }, [aiSpeakerList])

  return (
    <Card>
      <Box>
        <Scrollbar>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ml: 2, mb: 0}}>
              <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{p: 1, ml: 2}}>
                  <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${allCount}`}</Typography>
                </Box>
                <Box sx={{p: 1}}>
                  <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
                </Box>
                <Box sx={{p: 1}} />
              </Box>
            </Grid>
            <Grid item sx={{mr: 2, pt: 2}}>
              <XlsxControlJSON rows={sheetRows} filename="스피커목록" />
            </Grid>
            {/* <Grid item sx={{mr: 2, pt: 2}}>
              {(isOperator) && <Button variant="outlined" disabled={(selectedRow === '' || selectedRow == null)} onClick={() => setPopupSpeakerStatusEdit(true)}>기기 상태변경</Button> }
            </Grid> */}
          </Grid>
          <MyTableA size="small">
            <TableHead>
              <TableRow sx={SX_TABLE_HEADER_A}>
                <TableCell>
                  <Checkbox color="primary" checked={allChecked} onChange={handleChangeAllChecked} />
                </TableCell>
                <TableCell>
                  모델명
                  <IconButton size="small" />
                </TableCell>
                <TableCell>
                  시리얼 번호
                  <IconButton size="small" />
                </TableCell>
                <TableCell>
                  MAC
                </TableCell>
                <TableCell>
                  제조년월
                </TableCell>
                <TableCell>
                  기기상태
                </TableCell>
                <TableCell>
                  실시간 사용일시
                </TableCell>
                <TableCell>
                  입고 등록일시
                </TableCell>
                <TableCell>
                  등록자
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                  aiSpeakerList && aiSpeakerList.map((item, index) => {
                    const isProductSelected = selectedRows.includes(item.ai_speaker_sn)

                    return (
                      <TableRow hover key={item.ai_speaker_sn} selected={isProductSelected}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell align="center" padding="checkbox">
                          <Checkbox color="primary" checked={isProductSelected} value={isProductSelected}
                            onChange={(e): void => handleChangeChecked(e, item)}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_model_name}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_sn}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_macaddress}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_make_date}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_status}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_alive_check_time}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_created_date}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_regist_writer_name}</TableCell>
                      </TableRow>
                    )
                  })
                }
            </TableBody>
          </MyTableA>
          <Divider />
          <Box display="flex" justifyContent="center" sx={{my: 3}}>
            {aiSpeakerList.length === 0 && (<Typography>조회 내역이 없습니다.</Typography>)}
          </Box>
          <Box sx={{...CSS_CENTER, my: 2}}>
            <Pagination
              siblingCount={5}
              count={allCount % limit > 0 ? Math.floor(allCount / limit + 1) : Math.floor(allCount / limit)}
              page={page + 1}
              onClick={() => {
                setPaginationDisabled(true);
                setTimeout(() => {
                  setPaginationDisabled(false);
                }, 100)
              }}
              disabled={paginationDisabled}
              onChange={handlePageChange}
            />
          </Box>
        </Scrollbar>
      </Box>
      <SpeakerStatusEditModalV2 isOverviewMode initialValue={currentItem} open={!!((currentItem != null && popupSpeakerStatusEdit === true))} setOpen={setPopupSpeakerStatusEdit} />
    </Card>
  )
}
