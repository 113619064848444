/* eslint-disable react/jsx-closing-tag-location */
import {
  Box, Button, Card, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, ToggleButton,
  ToggleButtonGroup, Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import { MouseEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { carewellAuthApi } from 'src/__CareWell__/api/carewellAuthApi'
import * as Yup from 'yup'
import { apiClient } from '../../../api/common'
import {
  AccountType, KEY_USER, SelectInputOptions
} from '../../../consts'
import {
  getDateYyyyMmDd
} from '../../../utils/serverTools'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import useAuth from 'src/hooks/useAuth'
import { setCookie } from 'src/__CareWell__/utils/cookieTools'
import { areaSortOptions } from 'src/__CareWell__/typesServiceDesign'
import { any } from 'prop-types'
import { SearchFilter, initSearchFilter } from 'src/__CareWell__/types/user'


const HEIGHT = 58
const TITLE_WIDTH = 80

export interface CommonTableSearch {
  from_date: string
  end_date: string
  search_item: string
  search_content: string
  area: string
  admin_role: string
}

// const initialValuesCommonTableSearch = {
//   from_date: '',
//   end_date: '',
//   search_item: '',
//   search_content: '',
//   notify_type: '',
//   area: '',
//   admin_role: '',
//   user_id: '',
//   simpleSelect: '',
//   simpleRadio: ''
// }

const dateOptions = [
  {id: '1', label: '전체'},
  {id: '2', label: '일주일'},
  {id: '3', label: '한달'},
  {id: '4', label: '3개월'}
]

interface Props<Type> {
  categorySearch?: SelectInputOptions
}

interface Props<Type> {
  hcenterlist?: any[]
  municipalitieslist?: string[]
  setSearchFilter:(filter:SearchFilter) => void
  searchFilter2: any
}

export default function UserListSearch<Type>(props: Props<Type>) {
  const {categorySearch, ...other} = props;
  const {hcenterlist, municipalitieslist, setSearchFilter, searchFilter2} = props
  const [alignment, setAlignment] = useState<any>(dateOptions[0]);
  const getSearchItem = () => {
    const foundOption = categorySearch.items.find((option) => option.value === searchFilter2?.searchItem);
    return foundOption || categorySearch[0];
  };
  // const [searchItem, setSearchItem] = useState<any>(searchFilter2?.searchItem || categorySearch[0]);
  const [searchItem, setSearchItem] = useState<any>(getSearchItem());
  const getArea = () => {
    const foundOption = areaSortOptions.find((option) => option.value === searchFilter2?.area);
    return foundOption || areaSortOptions[0];
  };
  // const [area, setArea] = useState<any>(searchFilter2?.area || areaSortOptions[0]);
  const [area, setArea] = useState<any>(getArea());
  const [fromdate, setFromDate] = useState(searchFilter2?.fromdate || '')
  const [enddate, setEnddate] = useState(searchFilter2?.enddate || '')
  const [searchContent, setSearchContent] = useState<any>(searchFilter2?.searchContent || '')
  const [searchContent2, setSearchContent2] = useState<any>(searchFilter2?.searchContent2 || '')
  const [publicCareName, setPublicCareName] = useState<any>()

  useEffect(() => {
    console.log(searchFilter2)
    console.log(area)
  }, [])

  const initfilter = () => {
    setAlignment(dateOptions[0])
    setSearchItem(categorySearch.items[0])
    setArea(areaSortOptions[0])
    setFromDate('')
    setEnddate('')
  }
  const DATETYPE = {
    ALL: 1, WEEK: 2, MONTH: 3, THREEMONTH: 4
  }
  const handleDateOptionsChange = (
    event: MouseEvent<HTMLElement>,
    datetype: string,
  ) => {
    const from = new Date()
    const end = new Date()
    setAlignment(datetype);
    const type = parseInt(datetype, 10);
    switch (type) {
      case DATETYPE.ALL:
        setFromDate('')
        setEnddate('')
        break;
      case DATETYPE.WEEK:
        from.setDate(from.getDate() - 7)
        break;
      case DATETYPE.MONTH:
        from.setMonth(from.getMonth() - 1)
        break;
      case DATETYPE.THREEMONTH:
        from.setMonth(from.getMonth() - 3)
        break;
      default:
        break;
    }
    if (type !== 1) {
      setFromDate(getDateYyyyMmDd(from));
      setEnddate(getDateYyyyMmDd(end))
    }
  }

  const onChangeSearchItem = async (e) => {
    const selectCategory = await categorySearch.items.find((item) => item.value === e.target.value);
    await setSearchItem(selectCategory)
    if (selectCategory.value === 'silver_id' || selectCategory.value === 'silver_name' || selectCategory.value === 'silver_tel') {
      await setSearchContent('');
    } else if (selectCategory.value === 'ai_speaker_certificate_yn') {
      await setSearchContent(null);
    } else if (selectCategory.value === 'public_care_name') {
      await setSearchContent(hcenterlist[0].value);
    } else if (selectCategory.value === 'municipality_name') {
      await setSearchContent(municipalitieslist[0]);
    } else {
      await setSearchContent('')
    }
  }

  const onSubmit = async () => {
    // 검색 필터 넘기기
    const searchFilter:SearchFilter = {
      area: '',
      fromdate: '',
      enddate: '',
      searchContent: '',
      searchContent2: '',
      searchItem: ''
    };
    searchFilter.area = area == null ? '' : area.value;
    searchFilter.fromdate = fromdate;
    searchFilter.enddate = enddate;
    searchFilter.searchContent = searchContent;
    searchFilter.searchContent2 = searchContent2;
    searchFilter.searchItem = searchItem == null ? '' : searchItem.value;
    if (searchContent !== '' && searchFilter.searchItem === 'silver_tel') {
      searchFilter.searchContent = searchContent.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3')
    } else if (searchContent !== '' && searchFilter.searchItem === 'public_care_name') {
      searchFilter.area = searchContent2
    }
    console.log(searchFilter)
    setSearchFilter(searchFilter)
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit()
    }
  }

  const handleChangeFromDate = (e) => {
    setFromDate(e.target.value)
    if (enddate === '' || enddate == null) {
      setEnddate(e.target.value)
    }
    if (e.target.value > enddate) {
      setEnddate(e.target.value)
    }
  }

  const handleChangeEnddate = (e) => {
    setEnddate(e.target.value)
    if (fromdate === '' || fromdate == null) {
      setFromDate(e.target.value)
    }
    if (e.target.value < fromdate) {
      setFromDate(e.target.value)
    }
  }

  // const onChangeSearchContent = (e) => {
  //   setSearchContent(e.target.value)
  //   if (searchItem.value === 'silver_tel') {

  //   }
  // }

  return (
    <>
      <Card>
        <form>
          <Box sx={{pt: 1}}>
            <Grid container>
              {/* 지역 */}
              <Box sx={{p: 1, width: '100%', bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{mr: 1}} width={TITLE_WIDTH} align="right">지역</Typography>
                  <TextField
                    sx={{width: 140}}
                    size="small" select SelectProps={{native: true}} variant="outlined"
                    label="" name="area"
                    value={area && area.label} onChange={(e) => { setArea({label: e.target.value, value: e.target.value}) }}
                  >
                    {
                      areaSortOptions.map((opt, index) => {
                        const keyName = `${opt.value}_${index}`
                        return (
                          <option key={keyName} value={opt.value}>{opt.label}</option>
                        )
                      })
                  }
                  </TextField>
                </Box>
                <Button variant="outlined" sx={{mr: 2}} onClick={initfilter}>초기화</Button>
              </Box>
              {/* 검색어 */}
              <Box sx={{p: 1, width: '100%', display: 'flex', alignItems: 'center'}}>
                <Typography sx={{mr: 1}} width={TITLE_WIDTH} align="right">검색</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField
                    size="small" select SelectProps={{native: true}} variant="outlined"
                    label="" name="search_item" sx={{width: 140}}
                    value={searchItem && searchItem.value}
                    onChange={onChangeSearchItem}
                  >
                    {
                      categorySearch.items.map((option, index) => {
                        const keyName = `${option.value}_${index}`
                        return (
                          <option key={keyName} value={option.value}>{option.label}</option>
                        )
                      })
                    }
                  </TextField>
                  {
                  (searchItem && (searchItem.value === 'silver_id' || searchItem.value === 'silver_name' || searchItem.value === 'silver_tel' || searchItem.value === 'emergency_call_source_detail'))
                  && <TextField size="small" placeholder="텍스트를 입력해 주세요." sx={{ml: 1}}
                    name="search_content" onChange={(e) => { setSearchContent(e.target.value) }} value={searchContent == null ? '' : searchContent}
                    onKeyPress={handleOnKeyPress}
                  />
                  }
                  {
                  // eslint-disable-next-line react/jsx-indent
                  (searchItem && searchItem.value === 'silver_gender') && <RadioGroup name="search_content" sx={{flexDirection: 'row'}} onChange={(e) => { setSearchContent(e.target.value) }}
                    value={searchContent}
                  >
                    {['남', '여'].map((item) => (
                      <FormControlLabel checked={searchContent === item}
                        control={(<Radio color="primary" sx={{ml: 1}} />)} key={item}
                        value={item}
                        label={(
                          <Typography color="textPrimary" variant="body1">{item}</Typography>)}
                      />))}

                  </RadioGroup>
                }
                  {
                  // eslint-disable-next-line react/jsx-indent
                  (searchItem && searchItem.value === 'ai_speaker_certificate_yn') && <RadioGroup name="search_content" sx={{flexDirection: 'row'}} onChange={(e) => { setSearchContent(e.target.value) }}
                    value={searchContent}
                  >
                    {['인증', '미인증'].map((item) => (
                      <FormControlLabel checked={searchContent === item}
                        control={(<Radio color="primary" sx={{ml: 1}} />)} key={item}
                        value={item}
                        label={(
                          <Typography color="textPrimary" variant="body1">{item}</Typography>)}
                      />))}

                  </RadioGroup>
                }
                  {
                      (searchItem && searchItem.value === 'public_care_name')
                      && <TextField
                        size="small" select SelectProps={{native: true}} variant="outlined"
                        label="" name="search_content" sx={{ml: 1, width: 140}} onChange={async (e) => {
                          setPublicCareName(e.target.value)
                          const selectCategory = await hcenterlist.find((item) => item.value === e.target.value.replace(/\([^()]*\)/g, '').trim() && item.area === e.target.value.match(/\(([^)]+)\)/)[1]);
                          await setSearchContent(selectCategory.value)
                          await setSearchContent2(selectCategory.area)
                        }}
                        value={publicCareName && publicCareName}
                      >
                          {
                            hcenterlist?.map((option, index) => {
                              const keyName = `${option.value}_${index}`
                              return (
                                <option key={keyName} value={`(${option.area})${option.value}`}>{`(${option.area})${option.value}`}</option>
                              )
                            })
                          }
                        </TextField>
                    }
                  {
                      (searchItem && searchItem.value === 'municipality_name')
                      && <TextField
                        size="small" select SelectProps={{native: true}} variant="outlined"
                        label="" name="search_content" sx={{ml: 1, width: 140}}
                        onChange={async (e) => {
                          const selectCategory = await municipalitieslist.find((item) => item === e.target.value);
                          await setSearchContent(selectCategory)
                        }} value={searchContent && searchContent}
                      >
                        {
                        municipalitieslist?.map((option) => (
                          <option key={option} value={option}>{option}</option>
                        ))
                      }
                      </TextField>
                    }
                  {
                  (searchItem && (searchItem.value === 'silver_bod'))
                  && <TextField size="small" type="date" InputLabelProps={{shrink: true, }} sx={{cursor: 'pointer', mr: 1, ml: 1}}
                    id="bod" value={searchContent} onChange={(e) => { setSearchContent(e.target.value) }}
                  />
                  }
                </Box>
              </Box>
              <Box sx={{p: 1, width: '100%', display: 'flex', alignItems: 'center'}}>
                <Typography sx={{mr: 1}} width={TITLE_WIDTH} align="right">기간</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField size="small" type="date" InputLabelProps={{shrink: true, }} sx={{cursor: 'pointer', mr: 1}}
                    id="from_date" value={fromdate} onChange={(e) => { handleChangeFromDate(e) }} onKeyPress={handleOnKeyPress}
                  />
                  <TextField size="small" type="date" InputLabelProps={{shrink: true, }} sx={{cursor: 'pointer', mr: 1}}
                    id="end_date" value={enddate} onChange={(e) => { handleChangeEnddate(e) }} onKeyPress={handleOnKeyPress}
                  />
                  <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleDateOptionsChange} onKeyPress={handleOnKeyPress}>
                    {
                  dateOptions.map((item) => (
                    <ToggleButton sx={{width: 72}} value={item.id} key={item.id}>{item.label}</ToggleButton>
                  ))
                  }
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', p: 1, m: 1, bgcolor: 'background.paper', }}>
                <Button variant="outlined" onClick={onSubmit} sx={{width: 100}}>검색 </Button>
                <Divider />
              </Box>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  )
}
