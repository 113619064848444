import { Box, Button, Card, Container, Grid, Link } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import PageHeader from '../../components/dashboard/PageHeader'
import { AccountType, CONTENT_LAYOUT_PY, PAGE_LIMITS } from '../../consts'
import { AiSpeaker, SpeakerSearchFilter, initSpeakerSearchFilter } from '../../types/speaker'
import { speakerStatusHCenterOptions, speakerStatusOptions } from '../../typesServiceDesign'
import useAuth from 'src/hooks/useAuth'
import SpeakerListSearchV2 from 'src/__CareWell__/components/dashboard/speakers/SpeakerListSearchV2'
import { carewellAuthApi } from 'src/__CareWell__/api/carewellAuthApi'
import useSettings from 'src/hooks/useSettings'
import styled from 'styled-components'
import { DotLoader } from 'react-spinner-overlay'
import { apiClient } from 'src/__CareWell__/api/common'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import gtm from 'src/lib/gtm'
import SpeakerListTableV2 from '../../components/dashboard/speakers/SpeakerListTableV2';

const Loading = styled.div`
  width:100%;
  height:100%;
  min-height: 400px;
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:white;
  opacity: 0.8;
  z-index:999;
`

export default function SpeakerListV2() {
  const navigate = useNavigate()
  const {logout} = useAuth()
  const { settings } = useSettings()
  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator

  const [searchFilter, setSearchFilter] = useState<SpeakerSearchFilter>(initSpeakerSearchFilter);
  const [aiSpeakerList, setAiSpeakerList] = useState<AiSpeaker[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(PAGE_LIMITS[1])
  const [loading, setLoading] = useState(false)
  const [allCount, setAllCount] = useState(0)

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  const getListCount = async (config) => {
    const url = '/get_all_ai_speaker_list_count_v2'
    const reqData = {
      ...searchFilter
    }
    reqData.use_yn = reqData.use_yn === '전체' ? '' : reqData.use_yn;
    reqData.ai_speaker_status = reqData.ai_speaker_status === '전체' ? '' : reqData.ai_speaker_status
    const res = await apiClient.post(url, reqData, config)
    if (res.data.state === 'ok') {
      setAllCount(res.data.result.count);
    }
  }

  const getList = async (config) => {
    setLoading(true);

    try {
      const url = `/get_all_ai_speaker_list_v2/${page}/${limit}`;
      const reqData = {
        ...searchFilter
      };
      reqData.use_yn = reqData.use_yn === '전체' ? '' : reqData.use_yn;
      reqData.ai_speaker_status = reqData.ai_speaker_status === '전체' ? '' : reqData.ai_speaker_status;
      const res = await apiClient.post(url, reqData, config);

      if (res.data.state === 'ok') {
        setAiSpeakerList(res.data.result);
      }
    } catch (error) {
      // 오류 처리
      console.error(error);
    } finally {
      // 타이머 해제 및 setLoading(false) 호출
      // clearTimeout(timerId);
      setLoading(false);
    }
  };


  const doUpdateTable = () => {
    checkToken(logout, navigate).then((config) => {
      getList(config)
    });
  }
  useEffect(() => {
    doUpdateTable()
  }, [page, limit])

  useEffect(() => { // 검색 버튼을 누른 최초1회 동작
    checkToken(logout, navigate).then((config) => {
      setPage(0)
      getListCount(config)
      getList(config)
    });
  }, [searchFilter])

  useEffect(() => {
    checkToken(logout, navigate).then((config) => {
      getListCount(config);
      getList(config)
    });
  }, []);

  const paths = ['홈', '스피커 관리', '입고 등록', '조회']


  return (
    <>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <PageHeader title="입고 등록 조회" paths={paths} links={['/', '', '', '']} />
          <Box sx={{mt: 3}}>
            {/* <Box sx={{my: 2}}>
              <SpeakerOverviewNumbers stock={stock} fullData={isOperator} />
            </Box> */}

            <SpeakerListSearchV2 setSearchFilter={setSearchFilter} statusOptions={isOperator ? speakerStatusOptions : speakerStatusHCenterOptions} />

            <Box sx={{my: 2}} />
            <Box sx={{position: 'relative'}}>
              {loading && <Loading><DotLoader loading={loading} size={10} /></Loading>}
              <SpeakerListTableV2
                loading={loading} doUpdateTable={doUpdateTable} aiSpeakerList={aiSpeakerList} page={page} setPage={setPage}
                allCount={allCount} limit={limit} setLimit={setLimit}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}


