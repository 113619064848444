import {
  Box,
  Button,
  experimentalStyled,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { carewellAuthApi } from '../../../api/carewellAuthApi';
import { UserFormMode } from '../../../consts';
import { AiSpeaker, initialValuesAiSpeaker } from '../../../types/speaker';
import { speakerModelNameOptions, speakerStatusOptions } from '../../../typesServiceDesign';

const HEIGHT = 72
const TITLE_WIDTH = 80

const myRegexSn = /^[a-zA-Z]{0,}[0-9]{8}[a-zA-Z]{0,}$/

const validationSchema = Yup.object({
  ai_speaker_sn: Yup.string()
    .required('시리얼 번호를 입력해 주세요.'),
  ai_speaker_macaddress: Yup.string()
    .required('MAC주소를 입력해 주세요.'),
  ai_speaker_make_date: Yup.string()
    .required('제조년월을 입력해 주세요.')
})

const MyStackItem = experimentalStyled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const validate = (values: any) => {
  const errors: any = {};

  if (!myRegexSn.test(values.ai_speaker_sn)) {
    errors.ai_speaker_sn = '8자리 숫자 외 숫자는 불가능합니다.';
  }
  console.log(myRegexSn.test(values.ai_speaker_sn))

  return errors;
}

interface Props {
  formMode: UserFormMode
  targetData: AiSpeaker
  trigger?: number
  setTargetItem?: (values: AiSpeaker) => void
  onUpdateRecord?: (values: AiSpeaker) => Promise<void>
  onCreateRecord?: (values: AiSpeaker) => Promise<void>
  setOpen?: (open: boolean) => void
}

export default function SpeakerForm(props: Props) {
  //
  const {trigger, formMode, targetData, setTargetItem, onCreateRecord, onUpdateRecord, setOpen} = props

  const formik = useFormik({
    initialValues: targetData ? {...targetData} : {...initialValuesAiSpeaker},
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (values: AiSpeaker) => {
      console.log('values: ', values)
      const finalValues = {...values, admin_id: carewellAuthApi.getAdminId()}
      if (formMode === UserFormMode.Update) {
        await onUpdateRecord(finalValues)
      } else if (formMode === UserFormMode.Create) {
        await onCreateRecord(finalValues)
      }
    }
  })

  useEffect(() => {
    formik.setValues(targetData);
  }, [targetData]);

  useEffect(() => {
    const handleUpdate = async () => {
      await onUpdateRecord(formik.values);
    };
    if (trigger > 0) {
      console.log(formik.values);
      handleUpdate();
    }
  }, [trigger, targetData]);

  return (
    <Box>
      <Stack spacing={2}>
        <MyStackItem>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid container item xs={12} sm={12} spacing={2}>
                <Grid item sm={12} xs={12}>
                  <Box sx={{alignItems: 'center', display: 'flex', mt: 0}}>
                    <Typography color="textPrimary" sx={{ ml: 1 }} variant="h6">모델명</Typography>
                    <RadioGroup name="ai_speaker_model_name" onChange={formik.handleChange} sx={{ flexDirection: 'row' }}>
                      {speakerModelNameOptions.map((item, index) => (
                        <FormControlLabel control={(<Radio color="primary" sx={{ ml: 1 }} checked={item.value === formik.values.ai_speaker_model_name} />)}
                          label={(<Typography color="textPrimary" variant="body1">{item.label}</Typography>)}
                          key={item.value} value={item.value}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField size="small" fullWidth label="시리얼 번호" name="ai_speaker_sn" variant="outlined"
                    value={formik.values.ai_speaker_sn || ''} helperText={formik.errors.ai_speaker_sn} error={Boolean(formik.errors.ai_speaker_sn)}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField size="small" fullWidth label="Mac" name="ai_speaker_macaddress" variant="outlined"
                    value={formik.values.ai_speaker_macaddress || ''} helperText={formik.errors.ai_speaker_macaddress} error={Boolean(formik.errors.ai_speaker_macaddress)}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField size="small" fullWidth label="제조년월" name="ai_speaker_make_date" variant="outlined" placeholder="2020-01"
                    value={formik.values.ai_speaker_make_date || ''} helperText={formik.errors.ai_speaker_make_date} error={Boolean(formik.errors.ai_speaker_make_date)}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, height: HEIGHT}}>
                    <Box>
                      <Typography width={TITLE_WIDTH} align="left" variant="h6" color="textPrimary">기기 상태</Typography>
                    </Box>
                    <Box sx={{ p: 0 }}>
                      <RadioGroup name="ai_speaker_status" sx={{ flexDirection: 'row' }} onChange={(e) => {
                        const newValue = targetData;
                        newValue.ai_speaker_status = e.target.value;
                        setTargetItem(newValue);
                        formik.handleChange(e)
                      }}
                        value={formik.values.ai_speaker_status}
                      >
                        {speakerStatusOptions.map((item, index) => (
                          <FormControlLabel checked={item.value === formik.values.ai_speaker_status}
                            control={(<Radio color="primary" sx={{ ml: 0 }} />)} key={item.value} value={item.value}
                            label={(<Typography color="textPrimary" variant="body1">{item.label}</Typography>)}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {
              (formMode === UserFormMode.Create) && (<Button disabled={!formik.values.ai_speaker_sn || !formik.values.ai_speaker_macaddress || !formik.values.ai_speaker_make_date} variant="outlined" type="submit" sx={{mt: 4, mb: 1, width: 120}}>저장</Button>)
            }
          </form>
        </MyStackItem>
      </Stack>
    </Box>
  )
}
