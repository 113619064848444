import {Box, Button, Card, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Typography, ClickAwayListener} from '@material-ui/core'
import React, {useCallback, useEffect, useState} from 'react'
import SpeakerStatusEdit from '../../dashboard/speakers/SpeakerStatusEdit'
import {AiSpeaker, initialValuesAiSpeaker} from '../../../types/speaker'
import SpeakerStatusEditSearch from '../../dashboard/speakers/SpeakerStatusEditSearch'
import SpeakerStatusEditTable from '../../dashboard/speakers/SpeakerStatusEditTable'
import SpeakerForm from '../../dashboard/speakers/SpeakerForm';
import {AccountType, UserFormMode} from '../../../consts';
import {apiClient} from '../../../api/common';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import { ModelDetail, Recipient } from 'src/__CareWell__/types/user'
import { carewellAuthApi } from 'src/__CareWell__/api/carewellAuthApi'
import { addHistory, historyType } from 'src/__CareWell__/utils/history'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import useAuth from 'src/hooks/useAuth'


const apiUrlUpdate = '/update_ai_speaker'

type Props = {
  open?: boolean,
  isOverviewMode: boolean,
  setOpen: (open: boolean) => void
  initialValue?: AiSpeaker
}

export default function SpeakerStatusEditModalV2(props: Props) {
  const {open, setOpen, isOverviewMode, initialValue: oldValue} = props
  const navigate = useNavigate()
  const [open2, setOpen2] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true)
  const [formMode, setFormMode] = React.useState<UserFormMode>(UserFormMode.Update)
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md')
  const [newValue, setTargetItem] = useState<AiSpeaker>(initialValuesAiSpeaker)
  const [trigger, doTrigger] = useState<number>(0);
  const [searchTrigger, doSearchTrigger] = useState<number>(0);
  const [onClickRowTrigger, doOnClickRowTrigger] = useState<number>(0);
  const [resetTrigger, doResetTrigger] = useState<number>(0);
  const [records, setRecords] = useState<AiSpeaker[]>([])
  const [searchDone, setSearchDone] = useState(false)
  const {logout} = useAuth()
  const handleClose = () => {
  }
  const handleRequest = async () => {
    console.log('> 저장??? 요청했다 !!!!!! ')
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  const handleClickOutside = () => {
  }

  const handleUpdateRecord = async (values: AiSpeaker) => {
    checkToken(logout, navigate).then(async (config) => {
      try {
        const url = apiUrlUpdate
        console.log(`[API Req-${url}]: `, values)
        const res = await apiClient.post(url, values, config)
        console.log(`[API ${url}]: `, res.data)
        if (res.data.state === 'ok') {
          toast.success('저장에 성공하였습니다.')
          await addHistory(null, values.ai_speaker_id, values, historyType.UPDATE_AI_SPEAKER);
        } else {
          console.log(`error, reason : ${res.data.error}`);
          toast.error('저장에 실패하였습니다.')
        }
        navigate('/dashboard/speakers/overview')
      } catch (err) {
        console.error(err)
      }
    });
  };

  useEffect(() => {
    // initialize value (한번만 실행)
    if (searchTrigger === 0) {
      setTargetItem(oldValue);
    }
  }, [oldValue, searchTrigger]);

  return (
    <>
      <Box>
        <Dialog fullWidth onBackdropClick={handleClickOutside} maxWidth={maxWidth} open={open} onClose={handleClose}>
          <DialogTitle>기기 상태 변경</DialogTitle>
          <Divider />
          <DialogContent>
            <SpeakerForm trigger={trigger} formMode={UserFormMode.Update} onUpdateRecord={handleUpdateRecord}
              targetData={newValue} setOpen={setOpen} setTargetItem={setTargetItem}
            />
            <Box>
              <Button variant="outlined" onClick={(e) => {
                const newTarget: AiSpeaker = newValue.admin_id ? {
                  ...oldValue,
                  ai_speaker_status: newValue.ai_speaker_status,
                }
                  : {
                    ...oldValue,
                    admin_id: carewellAuthApi.getAdminId(),
                    ai_speaker_status: newValue.ai_speaker_status,
                  };
                if (isOverviewMode) {
                  setOpen(false);
                }
                if (searchTrigger === 0) {
                  doTrigger((prev) => prev + 1);
                }
                handleUpdateRecord(newTarget);
              }} sx={{mt: 4, mb: 1, width: 120}}
              >
                저장
              </Button>
              <Button variant="outlined" sx={{mt: 4, mb: 1, width: 120, ml: 2}} color="secondary" onClick={() => setOpen(false)}>
                취소
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      <Dialog fullWidth maxWidth="xs" open={open2} onClose={handleClose}>
        <DialogTitle>
          <Typography align="center">
            체크 하신 기기로 모델명과 기기상태를
            <br />
            변경 하시겠습니까?
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <Button variant="outlined" onClick={(e) => {
              doOnClickRowTrigger((trig) => trig + 1);
              setOpen2(false);
            }} sx={{mt: 4, mb: 1, width: 120}}
            >
              네
            </Button>
            <Button variant="outlined" sx={{mt: 4, mb: 1, width: 120, ml: 2}} color="secondary" onClick={() => {
              doOnClickRowTrigger(0);
              doResetTrigger((trig) => trig + 1);
              setOpen2(false);
            }}
            >
              아니오
            </Button>
          </Box>
        </DialogTitle>
      </Dialog>
    </>
  )
}

