import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Recipient, RecipientSearchParam } from '../types/user';


interface GlobalStates {
  emergencyOpened: boolean
  myCount: number
  emergencyPopupEnabled: boolean
  hCenterMap: object
  cpeTel: string
  cpeTelModified: boolean
  silverList: Recipient[]
  silverSearchParam: RecipientSearchParam
  // 지자체
  municipalityMap: object
}

interface MyAppContextValue {
  // emergencyCallOpened: boolean
  // setEmergencyCallOpened: (opened: boolean) => void
  states: GlobalStates
  setStates: (arg: GlobalStates) => void
}

const initialStates: GlobalStates = {
  emergencyOpened: false,
  myCount: 1,
  emergencyPopupEnabled: true,
  hCenterMap: null,
  // 지자체
  cpeTel: null,
  cpeTelModified: false,
  silverList: [],
  silverSearchParam: {
    from_date: '',
    end_date: '',
    search_item: '',
    search_content: '',
    notify_type: '',
    area: '',
    admin_role: '',
    user_id: '',
    simpleSelect: '',
    simpleRadio: '',
    req_type: '',
  },
  municipalityMap: null
}

const MyAppContext = createContext<MyAppContextValue>({
  // emergencyCallOpened: false,
  // setEmergencyCallOpened: () => {},
  states: initialStates,
  setStates: (arg) => {}
})

interface MyAppProps {
  children: ReactNode
}

export const MyAppProvider: FC<MyAppProps> = (props) => {
  const {children} = props
  const [opened, setOpened] = useState(false)
  const [states, setStates] = useState<GlobalStates>(initialStates)

  const funcSetOpened = (arg: boolean): void => {
    setOpened(arg)
  }

  const funcSetStates = (arg: GlobalStates): void => {
    console.log('funcSetStates: ', arg)
    setStates(arg)
  }

  return (
    <MyAppContext.Provider value={{
      // emergencyCallOpened: opened,
      // setEmergencyCallOpened: funcSetOpened,
      states,
      setStates: funcSetStates
    }}
    >
      {children}
    </MyAppContext.Provider>
  )
}

MyAppProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default MyAppContext
