import axios from 'axios'
import { AccountType } from '../consts'

require('dotenv').config();

// const SERVER_URL = `https://api${process.env.REACT_APP_PUB_ENV === 'DEV' ? '-test' : ''}.carewellplus.co.kr/api_cc/v2`;
// const SERVER_URL_REAL_CALLCS = 'https://api.carewellplus.co.kr/api_cc/v2';

const SERVER_URL = 'https://api.carewellplus.co.kr/api_cc/v2';
const SERVER_URL_REAL_CALLCS = 'https://api.carewellplus.co.kr/api_cc/v2';

// const SERVER_URL = 'https://api-dev.carewellplus.co.kr/api_cc/v2';
// const SERVER_URL_REAL_CALLCS = 'https://api-dev.carewellplus.co.kr/api_cc/v2';

interface Info {
  accountType: AccountType
  avatar: string
  email: string
  id: string
  name: string
  plan: string
  state: string
}

export interface HCenterManagerInfo extends Info {
  public_care_address: string
  public_care_admin_id: string
  public_care_bod: string
  public_care_company_name: string
  public_care_created_date: string
  public_care_date_user_agreement: string
  public_care_del_date: string
  public_care_del_reason:string
  public_care_del_reason_detail:string
  public_care_del_user:string
  public_care_email: string
  public_care_gender: string
  public_care_job: string
  public_care_memo: any[]
  public_care_organization:string
  public_care_password: string
  public_care_recent_connection_time: string
  public_care_role: string
  public_care_status: string
  public_care_tel: string
  public_care_temp_password_yn: string
  public_care_username: string
  state: string
}

export interface MunicipalityManagerInfo extends Info {
  municipality_address: string
  municipality_admin_id: string
  municipality_bod: string
  municipality_company_name: string
  municipality_created_date: string
  municipality_date_user_agreement: string
  municipality_del_date: string
  municipality_del_reason: string
  municipality_del_reason_detail: string
  municipality_del_user: string
  municipality_email: string
  municipality_gender: string
  municipality_job: string
  municipality_memo: any[]
  municipality_organization: string
  municipality_password: string
  municipality_recent_connection_time: string
  municipality_role: string
  municipality_status: string
  municipality_tel: string
  municipality_temp_password_yn: string
  municipality_username: string
}

export const axiosConfig = {
  baseURL: '/', // SERVER_LOCAL, // SERVER_DEV, // API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/json'
  }
}

export const axiosUploadConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'content-type': 'multipart/form-data'
  }
}

export const apiClientFormData = axios.create({
  baseURL: SERVER_URL,
  headers: {'Content-Type': 'multipart/form-data'},
  timeout: 5000
})

export const apiClient = axios.create({
  baseURL: SERVER_URL,
  timeout: 10000
})

export const apiRealClient = axios.create({
  baseURL: SERVER_URL_REAL_CALLCS,
  timeout: 10000
})

export const findIdInUrl = (lastToken: string) : string => {
  const tokens = window.location.href.split('/')
  if (tokens[tokens.length - 1] !== lastToken) {
    // console.error('could not find recipient Id: ', tokens)
    return null
  }
  return tokens[tokens.length - 2]
}

export const findIdInLastTokenUrl = (lastToken: string) : string => {
  const tokens = window.location.href.split('/')
  return tokens[tokens.length - 1]
}
