import { Children, Suspense, lazy } from 'react';
import type { PartialRouteObject} from 'react-router'; // error on yarn install !!!
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import GuestGuard from '../components/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from '../components/MainLayout';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import RecipientListV2 from './pages/users/RecipientListV2';
import { element } from 'prop-types';
import SpeakerOverviewV2 from './pages/speakers/SpeakerOverviewV2';
import SpeakerListV2 from './pages/speakers/SpeakerListV2';
import IoTListSearch from './components/dashboard/users/IoTListSearch';
import IoTForm from './components/dashboard/users/IoTForm';
import IoTDetailForm from './components/dashboard/users/IoTDetailForm';
import IoTUpdateForm from './components/dashboard/users/IoTUpdateForm';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

// Authentication pages

// const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(lazy(() => import('../pages/authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('../pages/authentication/PasswordReset')))
const Register = Loadable(lazy(() => import('../pages/authentication/Register')))
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')))
const Login = Loadable(lazy(() => import('./pages/auth/Login')))

const DashboardHome = Loadable(lazy(() => import('./pages/Home')))

// EmergencyRequestOverview
const EmergencyRequestOverview = Loadable(lazy(() => import('./pages/EmergencyRequestOverview')))

// overview
const RecipientList = Loadable(lazy(() => import('./pages/users/RecipientList')))
const RecipientEdit = Loadable(lazy(() => import('./pages/users/RecipientEdit')))
const RecipientQuitList = Loadable(lazy(() => import('./pages/users/RecipientQuitList')))
const RecipientDetails = Loadable(lazy(() => import('./pages/users/RecipientDetails')))
const HCenterManagerList = Loadable(lazy(() => import('./pages/users/HCenterManagerList')))
const HCenterManagerEdit = Loadable(lazy(() => import('./pages/users/HCenterManagerEdit')))
const HCenterManagerQuitList = Loadable(lazy(() => import('./pages/users/HCenterManagerQuitList')))
const HCenterManagerDetails = Loadable(lazy(() => import('./pages/users/HCenterManagerDetails')))
const OperatorList = Loadable(lazy(() => import('./pages/users/OperatorList')))
const OperatorEdit = Loadable(lazy(() => import('./pages/users/OperatorEdit')))
const OperatorQuitList = Loadable(lazy(() => import('./pages/users/OperatorQuitList')))
const OperatorDetails = Loadable(lazy(() => import('./pages/users/OperatorDetails')))
const MunicipalityManagerList = Loadable(lazy(() => import('./pages/users/MunicipalityManagerList')))
const MunicipalityManagerEdit = Loadable(lazy(() => import('./pages/users/MunicipalityManagerEdit')))
const MunicipalityManagerQuitList = Loadable(lazy(() => import('./pages/users/MunicipalityManagerQuitList')))
const MunicipalityManagerDetails = Loadable(lazy(() => import('./pages/users/MunicipalityManagerDetails')))

const ApiTest = Loadable(lazy(() => import('./pages/ApiTest')))

const SpeakerOverview = Loadable(lazy(() => import('./pages/speakers/SpeakerOverview')))
const SpeakerList = Loadable(lazy(() => import('./pages/speakers/SpeakerList')))
const SpeakerEdit = Loadable(lazy(() => import('./pages/speakers/SpeakerEdit')))
const SpeakerDetails = Loadable(lazy(() => import('./pages/speakers/SpeakerDetails')))

const ChatbotList = Loadable(lazy(() => import('./pages/chatbots/ChatbotList')))
const ChatbotEdit = Loadable(lazy(() => import('./pages/chatbots/ChatbotEdit')))
const ChatbotDetails = Loadable(lazy(() => import('./pages/chatbots/ChatbotDetails')))

const AppList = Loadable(lazy(() => import('./pages/softwares/AppList')))
const AppEdit = Loadable(lazy(() => import('./pages/softwares/AppEdit')))
const AppRead = Loadable(lazy(() => import('./pages/softwares/AppRead')))
const FotaList = Loadable(lazy(() => import('./pages/softwares/FotaList')))
const FotaWrite = Loadable(lazy(() => import('./pages/softwares/FotaWrite')))
const FotaRead = Loadable(lazy(() => import('./pages/softwares/FotaRead')))

const NoticeList = Loadable(lazy(() => import('./pages/communities/NoticeList')))
const NoticeDetails = Loadable(lazy(() => import('./pages/communities/NoticeDetails')))
const NoticeEdit = Loadable(lazy(() => import('./pages/communities/NoticeEdit')))
// 어르신 공지사항
const SilverNoticeList = Loadable(lazy(() => import('./pages/communities/SilverNoticeList')))
const SilverNoticeDetails = Loadable(lazy(() => import('./pages/communities/SilverNoticeDetails')))
const SilverNoticeEdit = Loadable(lazy(() => import('./pages/communities/SilverNoticeEdit')))
const FaqList = Loadable(lazy(() => import('./pages/communities/FaqList')))
const FaqEdit = Loadable(lazy(() => import('./pages/communities/FaqEdit')))
const FaqDetails = Loadable(lazy(() => import('./pages/communities/FaqDetails')))

const RecipientQuestionList = Loadable(lazy(() => import('./pages/communities/RecipientQuestionList')))
const RecipientQuestionEdit = Loadable(lazy(() => import('./pages/communities/RecipientQuestionEdit')))
const RecipientQuestionDetails = Loadable(lazy(() => import('./pages/communities/RecipientQuestionDetails')))
const HCenterQuestionList = Loadable(lazy(() => import('./pages/communities/HCenterQuestionList')))
const HCenterQuestionEdit = Loadable(lazy(() => import('./pages/communities/HCenterQuestionEdit')))
const HCenterQuestionDetails = Loadable(lazy(() => import('./pages/communities/HCenterQuestionDetails')))

const OneToOneQuestionList = Loadable(lazy(() => import('./pages/communities/OneToOneQuestionList')))
const OneToOneQuestionEdit = Loadable(lazy(() => import('./pages/communities/OneToOneQuestionEdit')))
const OneToOneQuestionDetails = Loadable(lazy(() => import('./pages/communities/OneToOneQuestionDetails')))

const TermsOfUseList = Loadable(lazy(() => import('./pages/communities/TermsOfUseList')))
const TermsOfUseEdit = Loadable(lazy(() => import('./pages/communities/TermsOfUseEdit')))
const TermsOfUseDetails = Loadable(lazy(() => import('./pages/communities/TermsOfUseDetails')))

const HCenterTerms = Loadable(lazy(() => import('./pages/hcenter/HCenterTerms')))
const HCenterFaq = Loadable(lazy(() => import('./pages/hcenter/HCenterFaq')))

const VitalReactionMonitoring = Loadable(lazy(() => import('./pages/VitalReactionMonitoring')))


const HCenterManagerTermsOfUse = Loadable(lazy(() => import('./pages/auth/ManagerTermsOfUse')))
const HCenterManagerRegisterPage = Loadable(lazy(() => import('./pages/auth/ManagerRegisterPage')))
const FindIdPasswordHCenter = Loadable(lazy(() => import('./pages/auth/FindIdPasswordHCenter')))

const RecipientSearch = Loadable(lazy(() => import('./pages/installer/RecipientSearch')))
const RecipientDetailsInstaller = Loadable(lazy(() => import('./pages/installer/RecipientDetailsInstaller')))
const CheckDuplication = Loadable(lazy(() => import('./pages/installer/CheckDuplication')))
const InstallerNoticeDetails = Loadable(lazy(() => import('./pages/installer/InstallerNoticeDetails')))

const RemoteService = Loadable(lazy(() => import('./pages/remote/RemoteService')))

// Error pages미사용 : 스피커 전원 연결 해제 또는 인터넷 미연결 등



const AuthorizationRequired = Loadable(lazy(() => import('../pages/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('../pages/NotFound')))
const ServerError = Loadable(lazy(() => import('../pages/ServerError')))

// My DevTest
const ZRoutes = Loadable(lazy(() => import('./pages/z-devtest/ZRoutes')))


const routesCareWell: PartialRouteObject[] = [
  // {
  //   path: 'login',
  //   element: <Login />
  // },
  {
    path: 'service',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'remote',
        element: <RemoteService />
      },
    ]
  },
  {
    path: 'devtest',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/',
        element: <ZRoutes />
      }
    ]
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register-terms',
        element: (
          <GuestGuard>
            {/* <Register /> */}
            <HCenterManagerTermsOfUse />
          </GuestGuard>
        )
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <HCenterManagerRegisterPage />
          </GuestGuard>
        )
      },
      {
        path: 'find',
        element: (
          <GuestGuard>
            <FindIdPasswordHCenter />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'cpe/check-dup',
    element: <CheckDuplication />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    // outlets
    children: [
      {
        path: 'api-test',
        element: <ApiTest />
      },
      {
        path: '/',
        element: <DashboardHome />
      },
      {
        path: '/emergency-req',
        element: <EmergencyRequestOverview />
      },
      {
        path: 'vital-reaction',
        element: <VitalReactionMonitoring />
      },
      {
        path: '/installer',
        children: [
          {
            path: '/search',
            element: <RecipientSearch />
          },
          {
            path: '/notices/:id/details',
            element: <InstallerNoticeDetails />
          },
        ]
      },
      {
        path: 'users',
        // element: <Account />
        children: [
          {
            path: '/',
            element: <RecipientListV2 />
          },
          //------------------------------------------
          {
            path: 'recipients',
            element: <RecipientListV2 />
          },
          {
            path: 'recipients/:id/details',
            element: <RecipientDetails />
          },
          {
            path: 'recipients/:id/details-quit',
            element: <RecipientDetails />
          },
          {
            path: 'recipients/:id/edit',
            element: <RecipientEdit />
          },
          {
            path: 'recipients/new',
            element: <RecipientEdit />
          },
          {
            path: 'recipients/new-many',
            element: <RecipientEdit />
          },
          {
            path: 'recipients-quit',
            element: <RecipientQuitList />
          },
          //------------------------------------------
          {
            path: 'hcenter-managers',
            element: <HCenterManagerList />
          },
          {
            path: 'hcenter-managers/:id/details',
            element: <HCenterManagerDetails />
          },
          {
            path: 'hcenter-managers/:id/edit-profile',
            element: <HCenterManagerEdit />
          },
          {
            path: 'hcenter-managers/:id/details-quit',
            element: <HCenterManagerDetails />
          },
          {
            path: 'hcenter-managers/:id/edit',
            element: <HCenterManagerEdit />
          },
          {
            path: 'hcenter-managers/new',
            element: <HCenterManagerEdit />
          },
          {
            path: 'hcenter-managers-quit',
            element: <HCenterManagerQuitList />
          },
          //------------------------------------------
          {
            path: 'operators',
            element: <OperatorList />
          },
          {
            path: 'operators/:id/details',
            element: <OperatorDetails />
          },
          {
            path: 'operators/:id/details-quit',
            element: <OperatorDetails />
          },
          {
            path: 'operators/:id/edit',
            element: <OperatorEdit />
          },
          {
            path: 'operators/new',
            element: <OperatorEdit />
          },
          {
            path: 'operators-quit',
            element: <OperatorQuitList />
          },
          //------------------------------------------
          {
            path: 'municipality-managers',
            element: <MunicipalityManagerList />
          },
          {
            path: 'municipality-managers/:id/details',
            element: <MunicipalityManagerDetails />
          },
          {
            path: 'municipality-managers/:id/details-quit',
            element: <MunicipalityManagerDetails />
          },
          {
            path: 'municipality-managers/:id/edit',
            element: <MunicipalityManagerEdit />
          },
          {
            path: 'municipality-managers/new',
            element: <MunicipalityManagerEdit />
          },
          {
            path: 'municipality-managers-quit',
            element: <MunicipalityManagerQuitList />
          },
          //------------------------------------------
          {
            path: 'recipients/:id/details-ins',
            element: <RecipientDetailsInstaller />
          },
          //------------------------------------------
          {
            path: '/IoT',
            element: <IoTListSearch />
          },
          {
            path: '/IoT/new',
            element: <IoTForm />
          },
          {
            path: '/IoT/:id/detail',
            element: <IoTDetailForm />
          },
          {
            path: 'IoT/:id/update',
            element: <IoTUpdateForm />
          }
        ]
      },
      {
        path: 'speakers',
        // element: <Account />
        children: [
          //------------------------------------------
          {
            path: '/overview',
            element: <SpeakerOverviewV2 />
          },
          {
            path: '/',
            element: <SpeakerListV2 />
          },
          {
            path: '/:id/details',
            element: <SpeakerDetails />
          },
          {
            path: '/:id/edit',
            element: <SpeakerEdit />
          },
          {
            path: '/new',
            element: <SpeakerEdit />
          },
          {
            path: '/new-many',
            element: <SpeakerEdit />
          },
        ]
      },
      {
        path: 'chatbots',
        children: [
          //------------------------------------------
          {
            path: '/',
            element: <ChatbotList />
          },
          {
            path: '/:id/details',
            element: <ChatbotDetails />
          },
          {
            path: '/edit',
            element: <ChatbotEdit />
          },
          {
            path: '/new',
            element: <ChatbotEdit />
          },
        ]
      },
      {
        path: 'softwares',
        children: [
          {
            path: '/',
            element: <AppList />
          },
          {
            path: 'apps',
            element: <AppList />
          },
          {
            path: 'apps/new',
            element: <AppEdit />
          },
          {
            path: 'apps/:id/details',
            element: <AppRead />
          },
          {
            path: 'apps/:id/edit',
            element: <AppEdit />
          },
          {
            path: 'fota',
            element: <FotaList />
          },
          {
            path: 'fota/new',
            element: <FotaWrite />
          },
          {
            path: 'fota/:id/details',
            element: <FotaRead />
          },
          {
            path: 'fota/:id/edit',
            element: <FotaWrite />
          },
        ]
      },

      {
        path: 'communities',
        children: [
          {
            path: '/',
            element: <NoticeList />
          },
          // {
          //   path: 'notices',
          //   element: <NoticeList />
          // },
          // {
          //   path: 'notices/:id',
          //   element: <InstallerNoticeDetails />
          // },
          // {
          //   path: 'notices/:id/edit',
          //   element: <NoticeCreate />
          // },
          // {
          //   path: 'notices/new',
          //   element: <NoticeCreate />
          // },
          //------------------------------------------
          {
            path: 'notices',
            element: <NoticeList />
          },
          {
            path: 'notices/:id/details',
            element: <NoticeDetails />
          },
          {
            path: 'notices/:id/edit',
            element: <NoticeEdit />
          },
          {
            path: 'notices/new',
            element: <NoticeEdit />
          },
          //------------------------------------------
          {
            path: 'silver_notices',
            element: <SilverNoticeList />
          },
          {
            path: 'silver_notices/:id/details',
            element: <SilverNoticeDetails />
          },
          {
            path: 'silver_notices/:id/edit',
            element: <SilverNoticeEdit />
          },
          {
            path: 'silver_notices/new',
            element: <SilverNoticeEdit />
          },
          //------------------------------------------
          {
            path: 'faqs',
            element: <FaqList />
          },
          {
            path: 'faqs/:id/details',
            element: <FaqDetails />
          },
          {
            path: 'faqs/:id/edit',
            element: <FaqEdit />
          },
          {
            path: 'faqs/new',
            element: <FaqEdit />
          },
          //------------------------------------------
          {
            path: 'recipient-questions',
            element: <RecipientQuestionList />
          },
          {
            path: 'recipient-questions/:id/details',
            element: <RecipientQuestionDetails />
          },
          {
            path: 'recipient-questions/:id/edit',
            element: <RecipientQuestionEdit />
          },
          {
            path: 'recipient-questions/new',
            element: <RecipientQuestionEdit />
          },
          //------------------------------------------
          {
            path: 'hcenter-questions',
            element: <HCenterQuestionList />
          },
          {
            path: 'hcenter-questions/:id/details',
            element: <HCenterQuestionDetails />
          },
          {
            path: 'hcenter-questions/:id/edit',
            element: <HCenterQuestionEdit />
          },
          {
            path: 'hcenter-questions/new',
            element: <HCenterQuestionEdit />
          },
          //------------------------------------------
          {
            path: 'terms',
            element: <TermsOfUseList />
          },
          {
            path: 'terms/:id/details',
            element: <TermsOfUseDetails />
          },
          {
            path: 'terms/:id/edit',
            element: <TermsOfUseEdit />
          },
          {
            path: 'terms/new',
            element: <TermsOfUseEdit />
          },
          //------------------------------------------
          {
            path: '1to1-questions',
            element: <OneToOneQuestionList />
          },
          {
            path: '1to1-questions/:id/details',
            element: <OneToOneQuestionDetails />
          },
          {
            path: '1to1-questions/:id/edit',
            element: <OneToOneQuestionEdit />
          },
          {
            path: '1to1-questions/new',
            element: <OneToOneQuestionEdit />
          },
          //------------------------------------------
          {
            path: 'terms',
            element: <TermsOfUseList />
          },
          {
            path: 'hcenter-terms',
            element: <HCenterTerms />
          },
          {
            path: 'hcenter-faqs',
            element: <HCenterFaq />
          },

        ]
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <DashboardHome />
      }
    ]
  },
  {
    path: '*',
    // element: <MainLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      },
    ]
  },
]

export default routesCareWell
