import type {FC} from 'react'
import * as React from 'react'
import {useEffect} from 'react'
import useAuth from '../../../hooks/useAuth'
import gtm from '../../../lib/gtm'
import {AppTypeCareWell, getAppTypeByUrl} from '../../consts'
import LoginOperator from './LoginOperator'
import LoginHCenter from './LoginHCenter'
import LoginInstaller from './LoginInstaller';
import LoginCallCenter from './LoginCallCenter';
import LoginMunicipality from './LoginMunicipality'

const POINT_COLOR = '#545454'
const desc = [
  {
    amount: 21500,
    color: POINT_COLOR, // '#6C76C4',
    name: '건강증진개발원 직원인 경우 운영관리자에게 문의하여 아이디를 발급하여 로그인합니다. '
  }
]



const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg'
}

const Login: FC = () => {
  const {platform} = useAuth() as any

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  function getComponent() {
    switch (getAppTypeByUrl()) {
      case AppTypeCareWell.OpManagerWeb: {
        return <LoginOperator />
        // break
      }
      case AppTypeCareWell.HCenterManagerWeb: {
        return <LoginHCenter />
        // break
      }
      case AppTypeCareWell.InstallerMobile: {
        return <LoginInstaller />
        // break
      }
      case AppTypeCareWell.CallCenterTablet: {
        return <LoginCallCenter />
        // break
      }
      case AppTypeCareWell.MunicipalityWeb: {
        return <LoginMunicipality />
      }
      default:
        return <div />
    }
  }
  return (
    <>
      {/* {getAppTypeByUrl() === AppTypeCareWell.HCenterManagerWeb ? <LoginHCenter /> : <LoginOperator /> } */}
      {getComponent()}
    </>
  )
}

export default Login
