import { SelectInputOptions } from './consts'

export const genderSelectOptions = [
  {
    label: '남',
    value: '남'
  },
  {
    label: '여',
    value: '여'
  }
]
export const appFreeOptions = [
  {
    label: '무료',
    value: '무료'
  },
  {
    label: '유료',
    value: '유료'
  }
]
export const appTypeOptions = [
  {
    label: 'AI 콘텐츠',
    value: 'AI 콘텐츠'
  },
  {
    label: '일반',
    value: '일반'
  },
]
// export const recipientSearchOptions = [
//   {label: '', value: ''},
//   {label: '보건소명', value: 'silver_public_care_name'},
//   {label: '이름', value: 'silver_name'},
//   {label: '스피커 ID', value: 'ai_speaker_id'},
//   {label: 'CPE 번호', value: 'cpe_id'}
// ]

export const recipientSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '대상자ID', value: 'silver_id'},
    {label: '대상자 이름', value: 'silver_name'},
    {label: '대상자 전화번호', value: 'silver_tel'},
    {label: '인증상태', value: 'ai_speaker_certificate_yn'},
    {label: '기관 이름', value: 'public_care_name'},
    {label: '지자체', value: 'municipality_name'},
    // {label: '보건소명', value: 'silver_public_care_name'},
    // {label: '이름', value: 'silver_name'},
    // {label: 'CPE 번호', value: 'cpe_id'}
  ]
}

export const recipientSearchOptionsForHCenter: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '대상자ID', value: 'silver_id'},
    {label: '대상자 이름', value: 'silver_name'},
    {label: '대상자 전화번호', value: 'silver_tel'},
    {label: '인증상태', value: 'ai_speaker_certificate_yn'},
    {label: '지자체', value: 'municipality_name'},
    // {label: '보건소명', value: 'silver_public_care_name'},
    // {label: '이름', value: 'silver_name'},
    // {label: 'CPE 번호', value: 'cpe_id'}
  ]
}

export const remoteServiceSearchOptions: SelectInputOptions = {
  items: [
    {label: '이름', value: 'silver_name'},
    {label: '전화번호', value: 'silver_tel'},
    {label: '시리얼번호', value: 'ai_speaker_sn'}
  ]
}

export const hCenterManagerSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '보건소명', value: 'public_care_company_name'},
    {label: '이름', value: 'public_care_username'},
    {label: '관리자 ID', value: 'public_care_admin_id'},
  ]
}

export const municipalityManagerSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '지자체명', value: 'municipality_company_name'},
    {label: '이름', value: 'municipality_username'},
    {label: '관리자 ID', value: 'municipality_admin_id'},
  ]
}

export const operatorSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '아이디', value: 'admin_user_id'},
    {label: '이름', value: 'admin_username'},
  ]
}
// ai_speaker_id,ai_speaker_model_name,ai_speaker_sn
export const speakerSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    // {label: '대상자ID', value: 'ai_speaker_id'},
    {label: '모델명', value: 'ai_speaker_model_name'},
    {label: '시리얼 번호', value: 'ai_speaker_sn'},
  ]
}

export const noticeSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '제목', value: 'notify_title'},
    {label: '제목 + 내용', value: 'notify_content'},
  ]
}

export const noticeCategorySearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '일반', value: '일반공지'},
    {label: '긴급', value: '긴급공지'},
  ]
}

export const faqSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '제목', value: 'faq_title'},
    {label: '제목 + 내용', value: 'faq_content'},
    {label: '카테고리', value: 'faq_category'},
  ]
}

// ???
export const recipientQuestionSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '제목', value: 'silver_question_tile'},
    {label: '제목 + 내용', value: 'silver_question_contents'},
  ]
}

export const recipientQuestionSimpleSelectOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '스피커', value: '스피커'},
    {label: 'CPE', value: 'CPE'},
    {label: '기타', value: '기타'},
  ]
}

export const hcenterQuestionSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '제목', value: 'public_care_question_tile'},
    {label: '제목 + 내용', value: 'public_care_question_content'},
  ]
}

export const hcenterQuestionSimpleSelectOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '관리자 WEB', value: '관리자 WEB'},
    {label: 'AI 스피커', value: 'AI 스피커'},
  ]
}

export const emergencyCallSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '이름', value: 'silver_name'},
    // {label: '휴대폰번호', value: 'silver_tel'},
    {label: '성별', value: 'silver_gender'},
    {label: '생년월일', value: 'silver_bod'},
    // {label: '주소', value: 'silver_address_top'},
    // {label: '대상자 ID', value: 'silver_id'},
    {label: '발화내용', value: 'emergency_call_source_detail'},
  ]
}

// 긴급요청현황 검색 옵션
export const emergencyCallCategoryOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '발화', value: '발화'},
    {label: '화면터치', value: '화면터치'}
  ]
}

export const appSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '모델명', value: 'app_model_name'},
    {label: '앱이름', value: 'app_name'},
    {label: '팩키지ID', value: 'app_package_id'},
  ]
}

export const chatbotSearchOptions: SelectInputOptions = {
  items: [
    {label: '전체', value: ''},
    {label: '패턴분류', value: 'smart_notice_pattern'},
    {label: '상세분류명', value: 'silver_tel'},
    {label: '사용여부', value: 'ai_speaker_certificate_yn'}
  ]
}

export const hasUsed = [
  {
    label: '전체',
    value: 'sum'
  },
  {
    label: '사용',
    value: 'used'
  },
  {
    label: '미사용',
    value: 'notUsed'
  }
]
export const publicOptions = [
  {
    label: '공개',
    value: 'Y'
  },
  {
    label: '비공개',
    value: 'N'
  },
]
export const ynOptions = [
  {
    label: 'Y',
    value: 'Y'
  },
  {
    label: 'N',
    value: 'N'
  },
]

export const nowOrLater = [
  {
    label: '현재',
    value: '현재'
  },
  {
    label: '예약',
    value: '예약'
  },
]

export const noticeCategoryOptions = [
  {label: '일반공지', value: '일반'},
  {label: '긴급공지', value: '긴급'}
]

export const noticeToOptionsAdmin = [
  {label: '전체', value: '전체'}
]

export const noticeToOptions = [
  {label: '전체', value: '전체'},
  {label: '집단', value: '집단'},
  {label: '개인', value: '개인'}
]

export const noticeToOptionsOperator = [
  {label: '전체', value: '전체'},
  {label: '집단', value: '집단'},
  {label: '개인', value: '개인'},
  {label: '보건소', value: '보건소'},
  {label: '지자체', value: '지자체'}
]

export const faqCategoryOptions = [
  {label: '관리자', value: '관리자'},
  {label: 'AI 스피커', value: 'AI 스피커'},
  {label: 'CPE', value: 'CPE'},
  {label: '인증 문의', value: '인증 문의'},
  {label: '기타', value: '기타'},
]

export const hCenterQuestionTypeOptions = [
  {label: '관리자 WEB', value: '관리자 WEB'},
  {label: 'AI 스피커', value: 'AI 스피커'},
]

export const hCenterQuestionCategoryOptions = [
  {label: 'AI 스피커', value: 'AI 스피커'},
  {label: 'CPE', value: 'CPE'},
  {label: '기타', value: '기타'},
]

export const termsChannelOptions = [
  {label: '보건소관리자', value: '보건소관리자'},
  {label: 'AI스피커', value: 'AI스피커'},
]

export const termsTypeOptions = [
  {label: '이용약관', value: '이용약관'},
  {label: '개인정보처리방침', value: '개인정보처리방침'},
]

export const termsEnableOptions = [
  {label: '미사용', value: '미사용'},
  {label: '사용', value: '사용'},
]

export const noticeRepeatOptions = [
  { label: '매일', value: 1 },
  { label: '매주', value: 2 },
  { label: '매월', value: 3 },
  { label: '매년', value: 4 },
  { label: '사용자 설정', value: 5 },
];

export const noticeRepeatOptionsCustom = [
  { label: '매일', value: 1 },
  { label: '매주', value: 2 },
  { label: '매월', value: 3 },
  { label: '매년', value: 4 },
];

export const noticeRepeatEndOptions = [
  { label: '지정한 날짜에 종료', value: 0 },
  { label: '지정한 횟수 반복 후 종료', value: 1 },
  { label: '계속 반복', value: 2 },
];

export const noticeNotifyStartTime = [
  { label: '일정 시작 시간', value: 0 },
  { label: '5분 전', value: 5 },
  { label: '15분 전', value: 15 },
  { label: '30분 전', value: 30 },
  { label: '1시간 전', value: 60 },
  { label: '1일 전', value: 1440 },
  { label: '2일 전', value: 2880 },
  { label: '1주 전', value: 10080 },
  { label: '사용자 설정', value: -1 },
];

export const recipientStatus = ['등록', '삭제']

export const appStatusOptions = [
  {
    label: '개발중',
    value: '개발중'
  },
  {
    label: '출시',
    value: '출시'
  }
]
