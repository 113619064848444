import {
  Box, Button, Card, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, ToggleButton,
  ToggleButtonGroup, Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import { MouseEvent, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { carewellAuthApi } from '../../../api/carewellAuthApi'
import { apiClient } from 'src/__CareWell__/api/common'
import {
  AccountType, SelectInputOptions
} from '../../../consts'
import {
  speakerNetworkStatusOptions, UIOptionItem
} from '../../../typesServiceDesign'
import {
  getDateYyyyMmDd
} from '../../../utils/serverTools'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router'
import { setCookie } from 'src/__CareWell__/utils/cookieTools'
import { SpeakerSearchFilter, initSpeakerSearchFilter } from 'src/__CareWell__/types/speaker'
import { speakerSearchOptions } from 'src/__CareWell__/options'


const HEIGHT = 58
const TITLE_WIDTH = 80

const dateOptions = [
  {id: '1', label: '전체'},
  {id: '2', label: '어제'},
  {id: '3', label: '오늘'},
  {id: '4', label: '6개월전'}
]

interface Props<Type> {
  setSearchFilter: (temp:SpeakerSearchFilter) => void,
  statusOptions: UIOptionItem[],
}

export default function SpeakerListSearchV2<Type>(props: Props<Type>) {
  const {setSearchFilter, statusOptions} = props;
  const [alignment, setAlignment] = useState('');
  const [speakerSearchFilter, setSpeakerSearchFilter] = useState<SpeakerSearchFilter>(initSpeakerSearchFilter)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedSearch, setSelectedSearch] = useState<any>(speakerSearchOptions)
  const [fromDate, setFromDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const handleResetForm = () => {
    setSpeakerSearchFilter(initSpeakerSearchFilter)
    setSelectedItem(null)
    setSelectedSearch(speakerSearchOptions)
    setFromDate('')
    setEndDate('')
    // setSearchFilter({
    //   from_date: '',
    //   end_date: '',
    //   search_content: '',
    //   search_item: '',
    //   ai_speaker_status: '전체',
    //   use_yn: '전체'
    // })
  }
  const handleDateOptionsChange = (
    event: MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment)
    // eslint-disable-next-line default-case
    let fromdate = ''
    let enddate = ''
    // eslint-disable-next-line default-case
    switch (newAlignment) {
      case '1': { // 전체
        fromdate = ''
        enddate = ''
        break
      }
      case '2': { // 어제
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        const val = getDateYyyyMmDd(yesterday)
        console.log('yesterday: ', val)
        fromdate = val
        enddate = val
        // setDate(toStartDateFormatOnApi(yesterday), toEndDateFormatOnApi(yesterday))
        break
      }
      case '3': { // 오늘
        const today = new Date()
        const val = getDateYyyyMmDd(today)
        console.log('today: ', val)
        fromdate = val
        enddate = val
        break
      }
      case '4': { // 6개월전
        const SIX_MONTHS_AGO = new Date()
        SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 6)
        const val = getDateYyyyMmDd(SIX_MONTHS_AGO)
        const now = getDateYyyyMmDd(new Date())
        console.log(`from(${val}) now(${now}) `)
        fromdate = val
        enddate = now
        break
      }
    }
    const temp = {...speakerSearchFilter}
    temp.from_date = fromdate ? `${fromdate} 00:00:00` : '';
    temp.end_date = enddate ? `${enddate} 23:59:59` : '';
    setSpeakerSearchFilter(temp)
    setFromDate(`${fromdate}`)
    setEndDate(`${enddate}`)
    console.log(temp)
  }
  const handleOnSearch = async () => {
    console.log(speakerSearchFilter)
    await setSearchFilter(speakerSearchFilter)
  }

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleOnSearch()
    }
  }

  const handleClickDate = (item) => {
    setSelectedItem(item)
  }

  return (
    <Card>
      <Box sx={{pt: 1}}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
              <Box sx={{p: 1}}><Typography width={TITLE_WIDTH} align="right">검색</Typography></Box>
              <Box sx={{display: 'flex'}}>
                <TextField
                  size="small" select SelectProps={{native: true}} variant="outlined"
                  label="" name="search_item" sx={{ml: 1, width: 120}}
                  value={selectedSearch}
                  onChange={(e) => {
                    const temp:SpeakerSearchFilter = {...speakerSearchFilter};
                    temp.search_item = e.target.value;
                    setSpeakerSearchFilter(temp)
                    setSelectedSearch(e.target.value)
                  }}
                >
                  {
                      speakerSearchOptions.items.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))
                    }
                </TextField>
                <Box sx={{px: 1}}>
                  {
                    speakerSearchFilter.search_item !== ''
                    && <TextField size="small" placeholder="텍스트를 입력해 주세요."
                      name="search_content" value={speakerSearchFilter.search_content} onChange={(e) => {
                        const temp:SpeakerSearchFilter = {...speakerSearchFilter}
                        temp.search_content = e.target.value;
                        setSpeakerSearchFilter(temp)
                      }}
                      onKeyPress={handleOnKeyPress}
                    />
                  }
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Button variant="outlined" sx={{mt: 1, mr: 2}} onClick={handleResetForm}>초기화</Button>
          </Grid>
        </Grid>
        <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
          <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">기기 상태</Typography></Box>
          <RadioGroup
            name="ai_speaker_status"
            sx={{ flexDirection: 'row' }}
            onKeyPress={handleOnKeyPress}
            onChange={(e) => {
              setSpeakerSearchFilter((prevFilter) => ({
                ...prevFilter,
                ai_speaker_status: e.target.value,
              }));
            }}
            value={speakerSearchFilter.ai_speaker_status}
          >
            {statusOptions.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio color="primary" sx={{ ml: 0 }} />}
                label={<Typography color="textPrimary" variant="body1">{item.label}</Typography>}
                checked={item.value === speakerSearchFilter.ai_speaker_status}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
          <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">사용 여부</Typography></Box>
          <RadioGroup name="ai_speaker_network_status" sx={{flexDirection: 'row'}} onKeyPress={handleOnKeyPress} onChange={(e) => {
            setSpeakerSearchFilter((prevFilter) => ({
              ...prevFilter,
              use_yn: e.target.value,
            }));
          }}
            value={speakerSearchFilter.use_yn}
          >
            {speakerNetworkStatusOptions.map((item) => (
              <FormControlLabel checked={item.value === speakerSearchFilter.use_yn}
                control={(<Radio color="primary" sx={{ml: 0}} />)} key={item.value}
                value={item.value}
                label={(
                  <Typography color="textPrimary" variant="body1">{item.label}</Typography>)}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
          <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">기간 검색</Typography></Box>
          <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
            id="from_date" value={fromDate} onChange={(e) => {
              const temp = {...speakerSearchFilter}
              if (temp.end_date === '' || temp.end_date === null) {
                temp.end_date = `${e.target.value} 23:59:59`
                setEndDate(e.target.value)
              }
              if (temp.end_date < e.target.value) {
                temp.end_date = `${e.target.value} 23:59:59`
                setEndDate(e.target.value)
              }
              // temp.from_date = e.target.value
              temp.from_date = e.target.value ? `${e.target.value} 00:00:00` : '';
              setFromDate(e.target.value)
              setSpeakerSearchFilter(temp)
            }}
          />
          <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
            id="end_date" value={endDate} onChange={(e) => {
              const temp = {...speakerSearchFilter}
              if (temp.from_date === '' || temp.from_date === null) {
                temp.from_date = `${e.target.value} 00:00:00`
                setFromDate(e.target.value)
              }
              if (temp.from_date > e.target.value) {
                temp.from_date = `${e.target.value} 00:00:00`
                setFromDate(e.target.value)
              }
              // temp.end_date = e.target.value
              temp.end_date = e.target.value ? `${e.target.value} 23:59:59` : '';
              setEndDate(e.target.value)
              setSpeakerSearchFilter(temp)
            }}
            sx={{mx: 1}}
          />
          <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleDateOptionsChange}>
            {
                  dateOptions.map((item) => (
                    <ToggleButton sx={{width: 72}} value={item.id} key={item.id} onClick={(() => { handleClickDate(item) })} selected={selectedItem === item}>{item.label}</ToggleButton>
                  ))
                }
          </ToggleButtonGroup>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', p: 1, m: 1, bgcolor: 'background.paper', }}>
          <Button variant="outlined" type="submit" sx={{width: 100}} onClick={handleOnSearch}>검색</Button>
          <Divider />
        </Box>
      </Box>
    </Card>
  )
}
