import createSvgIcon from '@material-ui/core/utils/createSvgIcon'

const AIconLogo = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" x="-60" width="142.468" height="24.872" viewBox="0 0 142.468 24.872" fill="currentColor">
    <g id="그룹_3767" data-name="그룹 3767" transform="translate(-339.999 -24)">
      <path id="패스_1" data-name="패스 1" className="cls-1"
        d="M329.613 272.255a12.168 12.168 0 0 1-4.664-.89 11.5 11.5 0 0 1-3.785-2.495 11.833 11.833 0 0 1-2.54-3.734 11.809 11.809 0 0 1-.016-9.117 11.563 11.563 0 0 1 2.54-3.72 11.878 11.878 0 0 1 8.465-3.413 12.586 12.586 0 0 1 4.079.6 12.071 12.071 0 0 1 3.462 1.941 1.074 1.074 0 0 1 .354.393.822.822 0 0 1 .092.423 2.477 2.477 0 0 1-.077.437.956.956 0 0 1-.385.715 1.5 1.5 0 0 1-.862.306 1.433 1.433 0 0 1-.969-.32 8.942 8.942 0 0 0-2.447-1.459 9.064 9.064 0 0 0-3.247-.5 8.621 8.621 0 0 0-3.526.729 9.435 9.435 0 0 0-2.893 1.984 9.287 9.287 0 0 0-1.955 2.9 8.825 8.825 0 0 0-.708 3.516 9 9 0 0 0 .708 3.559 9.215 9.215 0 0 0 1.955 2.918 9.193 9.193 0 0 0 9.482 2.144 11.426 11.426 0 0 0 2.755-1.445 1.459 1.459 0 0 1 .985-.306 1.379 1.379 0 0 1 .846.379 1.209 1.209 0 0 1 .353.919 1.344 1.344 0 0 1-.107.54 1.229 1.229 0 0 1-.354.452 10.962 10.962 0 0 1-3.541 1.925 13 13 0 0 1-4 .613"
        transform="translate(22.828 -223.914)"
      />
      <path id="패스_2" data-name="패스 2" className="cls-1"
        d="M329.393 272.565a12.753 12.753 0 0 1-4.874-.933 12.092 12.092 0 0 1-3.959-2.615 12.4 12.4 0 0 1-2.646-3.9 12.335 12.335 0 0 1-.016-9.519 12.133 12.133 0 0 1 2.649-3.888 12.4 12.4 0 0 1 8.847-3.575 13.149 13.149 0 0 1 4.259.629 12.657 12.657 0 0 1 3.619 2.029 1.56 1.56 0 0 1 .5.571 1.3 1.3 0 0 1 .141.671 3.04 3.04 0 0 1-.081.486 1.464 1.464 0 0 1-.572 1.024 2.032 2.032 0 0 1-1.169.42 1.985 1.985 0 0 1-1.331-.43 7.441 7.441 0 0 0-5.364-1.849 8.087 8.087 0 0 0-3.3.683A8.84 8.84 0 0 0 321.51 257a8.411 8.411 0 0 0-.669 3.333 8.581 8.581 0 0 0 .669 3.379 8.778 8.778 0 0 0 1.849 2.768 8.505 8.505 0 0 0 2.724 1.845 8.392 8.392 0 0 0 3.309.657 8.514 8.514 0 0 0 2.876-.479 10.884 10.884 0 0 0 2.624-1.381 1.993 1.993 0 0 1 1.352-.4 1.913 1.913 0 0 1 1.172.524 1.7 1.7 0 0 1 .508 1.278 1.813 1.813 0 0 1-.148.733 1.734 1.734 0 0 1-.494.64 11.585 11.585 0 0 1-3.719 2.028 13.607 13.607 0 0 1-4.172.641m0-23.422a11.318 11.318 0 0 0-8.083 3.267 11.141 11.141 0 0 0-2.431 3.569 11.377 11.377 0 0 0 .015 8.756 11.421 11.421 0 0 0 2.433 3.587 11.019 11.019 0 0 0 3.612 2.386 11.655 11.655 0 0 0 4.454.851 12.509 12.509 0 0 0 3.832-.587 10.493 10.493 0 0 0 3.367-1.837.728.728 0 0 0 .207-.261.864.864 0 0 0 .066-.349.738.738 0 0 0-.2-.565.829.829 0 0 0-.522-.234.935.935 0 0 0-.618.208 11.991 11.991 0 0 1-2.886 1.517 9.643 9.643 0 0 1-3.249.543 9.491 9.491 0 0 1-6.8-2.819 9.746 9.746 0 0 1-2.06-3.081 9.537 9.537 0 0 1-.747-3.756 9.352 9.352 0 0 1 .748-3.713 9.827 9.827 0 0 1 2.058-3.065 10.011 10.011 0 0 1 3.055-2.1 9.192 9.192 0 0 1 3.75-.778 8.515 8.515 0 0 1 6.042 2.082.844.844 0 0 0 .589.2.952.952 0 0 0 .557-.192.472.472 0 0 0 .194-.363l.015-.089a1.987 1.987 0 0 0 .062-.354.359.359 0 0 0-.045-.173.566.566 0 0 0-.191-.2 11.636 11.636 0 0 0-3.326-1.875 12.061 12.061 0 0 0-3.9-.569"
        transform="translate(23.049 -223.693)"
      />
      <path id="패스_3" data-name="패스 3" className="cls-1"
        d="M357.669 276.421a7.7 7.7 0 0 1-4.094-1.108 7.874 7.874 0 0 1-2.863-3.047 9.055 9.055 0 0 1-1.047-4.372 8.535 8.535 0 0 1 1.124-4.386 8.523 8.523 0 0 1 14.745.016 8.557 8.557 0 0 1 1.124 4.37l-1.108.247a8.624 8.624 0 0 1-1.031 4.217 7.9 7.9 0 0 1-2.8 2.97 7.549 7.549 0 0 1-4.049 1.093m.493-2.554a5.5 5.5 0 0 0 2.94-.785 5.742 5.742 0 0 0 2.032-2.14 6.163 6.163 0 0 0 .753-3.048 6.229 6.229 0 0 0-.753-3.078 5.753 5.753 0 0 0-2.032-2.14 5.841 5.841 0 0 0-5.848 0 5.658 5.658 0 0 0-2.063 2.14 6.206 6.206 0 0 0-.754 3.078 6.141 6.141 0 0 0 .754 3.048 5.648 5.648 0 0 0 2.063 2.14 5.546 5.546 0 0 0 2.909.785m7.049 2.462a1.418 1.418 0 0 1-1.447-1.446v-4.895l.585-2.986 2.309.892v6.989a1.433 1.433 0 0 1-.4 1.032 1.389 1.389 0 0 1-1.046.414"
        transform="translate(12.174 -228.265)"
      />
      <path id="패스_4" data-name="패스 4" className="cls-1"
        d="M357.449 276.732a8.263 8.263 0 0 1-4.37-1.185 8.435 8.435 0 0 1-3.053-3.249 9.613 9.613 0 0 1-1.11-4.623 9.1 9.1 0 0 1 1.2-4.654 9.053 9.053 0 0 1 15.661.018 9.032 9.032 0 0 1 1.189 4.27h.006v7.351a1.95 1.95 0 0 1-.549 1.4 1.985 1.985 0 0 1-3.406-1.4v-.034a8.4 8.4 0 0 1-1.238.932 8.109 8.109 0 0 1-4.327 1.171m.493-17.084a7.829 7.829 0 0 0-6.915 3.909 8.052 8.052 0 0 0-1.051 4.119 8.566 8.566 0 0 0 .983 4.119 7.38 7.38 0 0 0 2.673 2.846 7.209 7.209 0 0 0 3.818 1.031 7.067 7.067 0 0 0 3.77-1.013 7.3 7.3 0 0 0 1.795-1.564v-1.532a6.214 6.214 0 0 1-1.855 1.753 6.365 6.365 0 0 1-6.4 0 6.184 6.184 0 0 1-2.253-2.335 7.125 7.125 0 0 1 0-6.642 6.207 6.207 0 0 1 2.25-2.336 6.374 6.374 0 0 1 6.4 0 6.3 6.3 0 0 1 2.218 2.333 6.4 6.4 0 0 1 .676 1.846l1.823.7a7.792 7.792 0 0 0-7.934-7.242m6.133 13.815v1.2a.888.888 0 0 0 .917.916.859.859 0 0 0 .664-.253.9.9 0 0 0 .252-.663v-6.327l-.056.013a8.877 8.877 0 0 1-1.778 5.114m-6.134-11.252a5.044 5.044 0 0 0-2.636.709 5.16 5.16 0 0 0-1.872 1.942 5.722 5.722 0 0 0-.687 2.82 5.648 5.648 0 0 0 .687 2.789 5.147 5.147 0 0 0 1.872 1.943 5.307 5.307 0 0 0 5.3 0 5.231 5.231 0 0 0 1.846-1.945 5.66 5.66 0 0 0 .687-2.79 5.728 5.728 0 0 0-.687-2.82 5.255 5.255 0 0 0-1.846-1.946 5.013 5.013 0 0 0-2.662-.705m6.133 7.616v1.586a8.272 8.272 0 0 0 .725-3.484v-.311l-.271-.105z"
        transform="translate(12.414 -228.045)"
      />
      <path id="패스_5" data-name="패스 5" className="cls-1"
        d="M380.852 276.273a1.454 1.454 0 0 1-1.047-.354 1.4 1.4 0 0 1-.369-1.062v-14.006a1.287 1.287 0 0 1 1.416-1.417 1.252 1.252 0 0 1 1.416 1.417v14.006a1.454 1.454 0 0 1-.353 1.047 1.407 1.407 0 0 1-1.062.369m-.092-10.465a6.483 6.483 0 0 1 .939-3.355 6.723 6.723 0 0 1 5.772-3.2 4.884 4.884 0 0 1 2.554.539 1.2 1.2 0 0 1 .677 1.339 1.251 1.251 0 0 1-.384.647 1.1 1.1 0 0 1-.631.245 2.683 2.683 0 0 1-.8-.061 8.119 8.119 0 0 0-3.4.093 4.715 4.715 0 0 0-2.355 1.354 3.444 3.444 0 0 0-.863 2.4z"
        transform="translate(2.379 -228.239)"
      />
      <path id="패스_6" data-name="패스 6" className="cls-1"
        d="M380.632 276.583a1.968 1.968 0 0 1-1.414-.5 1.918 1.918 0 0 1-.532-1.445v-14.007a2.035 2.035 0 0 1 3.383-1.437 1.749 1.749 0 0 1 .476 1 7.467 7.467 0 0 1 1.1-.763 7.224 7.224 0 0 1 3.6-.93 5.33 5.33 0 0 1 2.836.619 1.7 1.7 0 0 1 .913 1.908 1.793 1.793 0 0 1-.564.936 1.631 1.631 0 0 1-.925.365 3.177 3.177 0 0 1-.955-.072 7.619 7.619 0 0 0-3.153.086 4.237 4.237 0 0 0-2.1 1.2 2.911 2.911 0 0 0-.725 2.046v9.05a1.968 1.968 0 0 1-.5 1.413 1.915 1.915 0 0 1-1.445.533m0-16.838a.769.769 0 0 0-.886.886v14.006a.921.921 0 0 0 .206.679.948.948 0 0 0 .68.207.922.922 0 0 0 .68-.206.964.964 0 0 0 .206-.679v-8.52H380l.009-.539a7.046 7.046 0 0 1 1.017-3.624 7.62 7.62 0 0 1 .491-.7v-.619a.74.74 0 0 0-.886-.886m.467 5.312h.418v-1.735a6.052 6.052 0 0 0-.418 1.735m1.479-3.421v1.129a5.314 5.314 0 0 1 2.55-1.445 8.71 8.71 0 0 1 3.623-.1 2.292 2.292 0 0 0 .672.057.583.583 0 0 0 .339-.127.755.755 0 0 0 .211-.382c.026-.115.094-.406-.448-.744a4.413 4.413 0 0 0-2.274-.459 6.2 6.2 0 0 0-4.672 2.075"
        transform="translate(2.609 -228.02)"
      />
      <path id="패스_7" data-name="패스 7" className="cls-1"
        d="M405.929 276.421a9.108 9.108 0 0 1-4.494-1.092 7.911 7.911 0 0 1-3.079-3.017 8.691 8.691 0 0 1-1.108-4.418 9.158 9.158 0 0 1 1.047-4.448 7.689 7.689 0 0 1 2.909-3.017 8.27 8.27 0 0 1 4.264-1.093 7.727 7.727 0 0 1 4.109 1.062 6.989 6.989 0 0 1 2.663 2.924 9.557 9.557 0 0 1 .923 4.294 1.2 1.2 0 0 1-.369.908 1.324 1.324 0 0 1-.955.354h-12.62v-2.309h12.651l-1.293.893a6.617 6.617 0 0 0-.616-2.909 4.863 4.863 0 0 0-1.754-2.017 4.932 4.932 0 0 0-2.74-.739 5.683 5.683 0 0 0-3.032.785 5.043 5.043 0 0 0-1.94 2.171 7.2 7.2 0 0 0-.662 3.14 6.166 6.166 0 0 0 .785 3.124 5.718 5.718 0 0 0 2.154 2.155 6.234 6.234 0 0 0 3.155.785 6.049 6.049 0 0 0 2.047-.37 6.26 6.26 0 0 0 1.678-.862 1.662 1.662 0 0 1 .923-.323 1.242 1.242 0 0 1 .862.293 1.361 1.361 0 0 1 .524.939 1 1 0 0 1-.462.877 8.309 8.309 0 0 1-2.586 1.37 9.209 9.209 0 0 1-2.986.539"
        transform="translate(-3.617 -228.265)"
      />
      <path id="패스_8" data-name="패스 8" className="cls-1"
        d="M405.709 276.732a9.67 9.67 0 0 1-4.751-1.158 8.466 8.466 0 0 1-3.281-3.216 9.257 9.257 0 0 1-1.178-4.683 9.717 9.717 0 0 1 1.112-4.7 8.244 8.244 0 0 1 3.105-3.22 8.832 8.832 0 0 1 4.532-1.166 8.273 8.273 0 0 1 4.386 1.14 7.536 7.536 0 0 1 2.861 3.142 10.114 10.114 0 0 1 .98 4.529 1.744 1.744 0 0 1-.533 1.291 1.836 1.836 0 0 1-1.321.5h-11.3a5.235 5.235 0 0 0 2.494 3.3 5.728 5.728 0 0 0 2.892.715 5.525 5.525 0 0 0 1.867-.338 5.719 5.719 0 0 0 1.535-.785 2.2 2.2 0 0 1 1.228-.431 1.762 1.762 0 0 1 1.219.415 1.888 1.888 0 0 1 .712 1.313 1.5 1.5 0 0 1-.671 1.334 8.839 8.839 0 0 1-2.733 1.448 9.764 9.764 0 0 1-3.158.567m-.462-17.084a7.772 7.772 0 0 0-3.994 1.021 7.184 7.184 0 0 0-2.711 2.812 8.666 8.666 0 0 0-.983 4.194 8.229 8.229 0 0 0 1.036 4.154 7.425 7.425 0 0 0 2.878 2.817 8.732 8.732 0 0 0 7.05.516 7.822 7.822 0 0 0 2.423-1.279c.281-.213.272-.369.268-.436a.855.855 0 0 0-.344-.571.726.726 0 0 0-.5-.161 1.124 1.124 0 0 0-.632.225 6.8 6.8 0 0 1-1.806.929 6.6 6.6 0 0 1-2.226.4 6.771 6.771 0 0 1-3.419-.855 6.316 6.316 0 0 1-3.055-4.224h-.766v-3.368h.81a7.112 7.112 0 0 1 .517-1.515 5.6 5.6 0 0 1 2.14-2.394 6.224 6.224 0 0 1 3.309-.862 5.464 5.464 0 0 1 3.03.824 5.4 5.4 0 0 1 1.943 2.231 6.652 6.652 0 0 1 .547 1.716h1.541a8.322 8.322 0 0 0-.756-2.482 6.507 6.507 0 0 0-2.465-2.708 7.242 7.242 0 0 0-3.833-.985m4.761 8.482h1.612a.8.8 0 0 0 .588-.206.682.682 0 0 0 .206-.525q0-.462-.036-.9zm-9.848 0h9.667v-.886c0-.122 0-.242-.009-.362h-9.636a8.192 8.192 0 0 0-.038.792c0 .155.006.306.016.455m.211-2.308h9.315a5.461 5.461 0 0 0-.422-1.256 4.361 4.361 0 0 0-1.566-1.8 4.423 4.423 0 0 0-2.451-.653 5.188 5.188 0 0 0-2.755.706 4.478 4.478 0 0 0-1.738 1.947 5.86 5.86 0 0 0-.384 1.058"
        transform="translate(-3.393 -228.045)"
      />
      <path id="패스_9" data-name="패스 9" className="cls-1"
        d="M428.353 276.349a1.4 1.4 0 0 1-.831-.262 1.424 1.424 0 0 1-.523-.662l-4.648-13.913a2.263 2.263 0 0 1 .03-1.447 1.023 1.023 0 0 1 1.048-.554 1.394 1.394 0 0 1 .892.293 1.947 1.947 0 0 1 .585.939l4 12.467h-.954l3.787-12.621a1.508 1.508 0 0 1 .477-.77 1.57 1.57 0 0 1 1.847-.016 1.445 1.445 0 0 1 .477.785l3.263 12h-.492l3.817-11.852a1.493 1.493 0 0 1 1.446-1.231 1.117 1.117 0 0 1 1.094.569 1.779 1.779 0 0 1-.016 1.432L439 275.425a1.252 1.252 0 0 1-.492.662 1.406 1.406 0 0 1-.831.262 1.544 1.544 0 0 1-.862-.262 1.2 1.2 0 0 1-.523-.662l-3.383-12.125h.307l-3.509 12.128a1.248 1.248 0 0 1-.493.662 1.448 1.448 0 0 1-.862.262"
        transform="translate(-12.1 -228.315)"
      />
      <path id="패스_10" data-name="패스 10" className="cls-1"
        d="M437.461 276.66a2.077 2.077 0 0 1-1.153-.348 1.729 1.729 0 0 1-.74-.951l-2.737-9.8-2.834 9.8a1.782 1.782 0 0 1-.7.947 2.037 2.037 0 0 1-2.306 0 1.959 1.959 0 0 1-.711-.9l-4.657-13.936a2.823 2.823 0 0 1 .049-1.831 1.513 1.513 0 0 1 1.532-.869 1.914 1.914 0 0 1 1.221.407 2.476 2.476 0 0 1 .76 1.187l2.988 9.31 2.834-9.448a2.02 2.02 0 0 1 .636-1.017 2.1 2.1 0 0 1 2.533-.025 1.975 1.975 0 0 1 .654 1.066l2.567 9.442 3-9.323a1.985 1.985 0 0 1 1.951-1.6 1.618 1.618 0 0 1 1.565.857 2.288 2.288 0 0 1 .011 1.853l-4.645 13.9a1.762 1.762 0 0 1-.688.926 1.928 1.928 0 0 1-1.138.36m-4.069-13.039 3.195 11.443a.675.675 0 0 0 .3.36.963.963 0 0 0 1.1.011.716.716 0 0 0 .292-.385l4.652-13.925a1.314 1.314 0 0 0 .047-1.023c-.043-.083-.145-.281-.621-.281-.308 0-.693.1-.942.865l-3.5 10.872.365 1.346h-1.913l.442-1.371-3-11.025a.936.936 0 0 0-.3-.517 1.047 1.047 0 0 0-1.161.008.988.988 0 0 0-.313.505l-3.282 10.943.664 2.072h-2.393l.618-2.061-3.458-10.774a1.455 1.455 0 0 0-.41-.685.873.873 0 0 0-.563-.178c-.457 0-.531.168-.563.239a1.784 1.784 0 0 0 0 1.108l4.635 13.87a.909.909 0 0 0 .329.4.96.96 0 0 0 1.079 0 .72.72 0 0 0 .293-.386l3.307-11.435-.3-1.068h1.717z"
        transform="translate(-11.887 -228.096)"
      />
      <path id="패스_11" data-name="패스 11" className="cls-1"
        d="M464.8 276.421a9.11 9.11 0 0 1-4.494-1.092 7.909 7.909 0 0 1-3.078-3.017 8.692 8.692 0 0 1-1.108-4.418 9.15 9.15 0 0 1 1.048-4.448 7.678 7.678 0 0 1 2.908-3.017 8.271 8.271 0 0 1 4.264-1.093 7.732 7.732 0 0 1 4.11 1.062 6.992 6.992 0 0 1 2.663 2.924 9.557 9.557 0 0 1 .922 4.294 1.2 1.2 0 0 1-.369.908 1.32 1.32 0 0 1-.954.354h-12.626v-2.309h12.652l-1.293.893a6.617 6.617 0 0 0-.616-2.909 4.87 4.87 0 0 0-1.754-2.017 4.934 4.934 0 0 0-2.74-.739 5.683 5.683 0 0 0-3.032.785 5.043 5.043 0 0 0-1.94 2.171 7.185 7.185 0 0 0-.662 3.14 6.165 6.165 0 0 0 .785 3.124 5.72 5.72 0 0 0 2.155 2.155 6.229 6.229 0 0 0 3.155.785 6.052 6.052 0 0 0 2.047-.37 6.238 6.238 0 0 0 1.678-.862 1.662 1.662 0 0 1 .924-.323 1.241 1.241 0 0 1 .861.293 1.365 1.365 0 0 1 .525.939 1 1 0 0 1-.462.877 8.309 8.309 0 0 1-2.586 1.37 9.205 9.205 0 0 1-2.986.539"
        transform="translate(-23.166 -228.265)"
      />
      <path id="패스_12" data-name="패스 12" className="cls-1"
        d="M464.577 276.732a9.67 9.67 0 0 1-4.751-1.158 8.482 8.482 0 0 1-3.282-3.216 9.264 9.264 0 0 1-1.178-4.683 9.718 9.718 0 0 1 1.113-4.7 8.224 8.224 0 0 1 3.107-3.22 8.821 8.821 0 0 1 4.53-1.166 8.272 8.272 0 0 1 4.385 1.14 7.534 7.534 0 0 1 2.862 3.142 10.114 10.114 0 0 1 .979 4.529 1.743 1.743 0 0 1-.533 1.291 1.839 1.839 0 0 1-1.32.5h-11.3a5.232 5.232 0 0 0 2.5 3.3 5.725 5.725 0 0 0 2.891.715 5.532 5.532 0 0 0 1.868-.338 5.73 5.73 0 0 0 1.535-.785 2.2 2.2 0 0 1 1.226-.431 1.731 1.731 0 0 1 1.22.415 1.887 1.887 0 0 1 .713 1.313 1.509 1.509 0 0 1-.672 1.334 8.852 8.852 0 0 1-2.733 1.448 9.753 9.753 0 0 1-3.157.567m-.462-17.084a7.776 7.776 0 0 0-3.994 1.021 7.184 7.184 0 0 0-2.711 2.812 8.674 8.674 0 0 0-.984 4.194 8.215 8.215 0 0 0 1.038 4.154 7.426 7.426 0 0 0 2.876 2.817 8.735 8.735 0 0 0 7.051.516 7.84 7.84 0 0 0 2.423-1.279c.28-.213.271-.369.268-.436a.855.855 0 0 0-.345-.571.724.724 0 0 0-.5-.161 1.124 1.124 0 0 0-.631.225 6.8 6.8 0 0 1-1.807.929 6.6 6.6 0 0 1-2.226.4 6.773 6.773 0 0 1-3.419-.855 6.246 6.246 0 0 1-2.351-2.351 6.369 6.369 0 0 1-.7-1.873h-.766v-3.368h.811a7.082 7.082 0 0 1 .517-1.515 5.6 5.6 0 0 1 2.14-2.393 6.225 6.225 0 0 1 3.309-.862 5.46 5.46 0 0 1 3.029.824 5.39 5.39 0 0 1 1.943 2.231 6.6 6.6 0 0 1 .548 1.716h1.535a8.3 8.3 0 0 0-.756-2.482 6.5 6.5 0 0 0-2.464-2.708 7.248 7.248 0 0 0-3.834-.985m4.761 8.482h1.612a.8.8 0 0 0 .587-.206.682.682 0 0 0 .206-.526q0-.462-.035-.9zm-9.848 0h9.669v-.886c0-.122 0-.242-.008-.362h-9.642a8.192 8.192 0 0 0-.038.792c0 .155 0 .306.016.455m.211-2.308h9.315a5.426 5.426 0 0 0-.422-1.256 4.364 4.364 0 0 0-1.565-1.8 4.427 4.427 0 0 0-2.452-.653 5.182 5.182 0 0 0-2.754.706 4.469 4.469 0 0 0-1.738 1.947 5.763 5.763 0 0 0-.385 1.058"
        transform="translate(-22.959 -228.045)"
      />
      <path id="패스_13" data-name="패스 13" className="cls-1"
        d="M487.915 272.074a4.088 4.088 0 0 1-2.34-.684 4.606 4.606 0 0 1-1.585-1.865 6.184 6.184 0 0 1-.569-2.723v-16.141a1.278 1.278 0 0 1 .385-.976 1.64 1.64 0 0 1 2.062 0 1.281 1.281 0 0 1 .385.976V266.8a3.424 3.424 0 0 0 .462 1.864 1.379 1.379 0 0 0 1.2.729h.77a1.2 1.2 0 0 1 .924.364 1.38 1.38 0 0 1 .338.976 1.134 1.134 0 0 1-.523.977 2.269 2.269 0 0 1-1.323.363z"
        transform="translate(-31.809 -224.041)"
      />
      <path id="패스_14" data-name="패스 14" className="cls-1"
        d="M487.881 272.385h-.186a4.638 4.638 0 0 1-2.647-.78 5.138 5.138 0 0 1-1.759-2.07 6.714 6.714 0 0 1-.617-2.939v-16.179a1.764 1.764 0 0 1 .539-1.334 2.186 2.186 0 0 1 2.812 0 1.767 1.767 0 0 1 .541 1.334V266.6a2.986 2.986 0 0 0 .386 1.61.833.833 0 0 0 .745.486h.77a1.748 1.748 0 0 1 1.32.534 1.861 1.861 0 0 1 .471 1.312 1.634 1.634 0 0 1-.739 1.384 2.808 2.808 0 0 1-1.637.462m-3.263-22.808a.874.874 0 0 0-.657.218.782.782 0 0 0-.229.622v16.18a5.779 5.779 0 0 0 .52 2.521 4.141 4.141 0 0 0 1.41 1.669 3.516 3.516 0 0 0 2.032.593h.186a1.718 1.718 0 0 0 1.009-.267.646.646 0 0 0 .307-.573.909.909 0 0 0-.207-.647.651.651 0 0 0-.524-.193h-.77a1.9 1.9 0 0 1-1.655-.974 3.931 3.931 0 0 1-.536-2.128v-16.184a.815.815 0 0 0-.23-.623.9.9 0 0 0-.655-.217"
        transform="translate(-31.607 -223.821)"
      />
      <path id="패스_15" data-name="패스 15" className="cls-1"
        d="M500.718 272.074a4.093 4.093 0 0 1-2.34-.684 4.6 4.6 0 0 1-1.584-1.865 6.184 6.184 0 0 1-.569-2.723v-16.141a1.281 1.281 0 0 1 .385-.976 1.639 1.639 0 0 1 2.061 0 1.279 1.279 0 0 1 .385.976V266.8a3.417 3.417 0 0 0 .463 1.864 1.378 1.378 0 0 0 1.2.729h.77a1.2 1.2 0 0 1 .922.364 1.374 1.374 0 0 1 .339.976 1.136 1.136 0 0 1-.523.977 2.27 2.27 0 0 1-1.324.363z"
        transform="translate(-36.883 -224.041)"
      />
      <path id="패스_16" data-name="패스 16" className="cls-1"
        d="M500.684 272.385h-.184a4.635 4.635 0 0 1-2.646-.78 5.137 5.137 0 0 1-1.759-2.069 6.719 6.719 0 0 1-.617-2.939v-16.18a1.768 1.768 0 0 1 .539-1.334 2.188 2.188 0 0 1 2.813 0 1.766 1.766 0 0 1 .54 1.334V266.6a2.989 2.989 0 0 0 .386 1.61.834.834 0 0 0 .745.486h.77a1.742 1.742 0 0 1 1.32.535 1.859 1.859 0 0 1 .471 1.311 1.631 1.631 0 0 1-.739 1.383 2.8 2.8 0 0 1-1.638.463m-3.263-22.808a.909.909 0 0 0-.656.218.811.811 0 0 0-.229.622v16.18a5.779 5.779 0 0 0 .52 2.521 4.141 4.141 0 0 0 1.41 1.669 3.521 3.521 0 0 0 2.032.593h.186a1.716 1.716 0 0 0 1.009-.267.645.645 0 0 0 .307-.573.911.911 0 0 0-.206-.646.649.649 0 0 0-.525-.194h-.77a1.9 1.9 0 0 1-1.654-.974 3.919 3.919 0 0 1-.537-2.128v-16.184a.812.812 0 0 0-.23-.623.871.871 0 0 0-.656-.217"
        transform="translate(-36.662 -223.821)"
      />
      <path id="패스_17" data-name="패스 17" className="cls-1"
        d="M514.869 262.76a1.374 1.374 0 0 1-1.016-.4 1.414 1.414 0 0 1-.4-1.047v-4.341h-4.371a1.4 1.4 0 0 1-1.031-.415 1.436 1.436 0 0 1-.416-1.062 1.419 1.419 0 0 1 1.446-1.446h4.371v-4.371a1.339 1.339 0 0 1 .43-1 1.382 1.382 0 0 1 .986-.416 1.417 1.417 0 0 1 1.046.4 1.379 1.379 0 0 1 .4 1.016v4.371h4.371a1.471 1.471 0 0 1 1.478 1.477 1.392 1.392 0 0 1-.431 1.016 1.424 1.424 0 0 1-1.047.43h-4.371v4.341a1.373 1.373 0 0 1-1.446 1.447"
        transform="translate(-40.227 -223.73)"
      />
      <path id="패스_18" data-name="패스 18" className="cls-1"
        d="M514.649 263.069a1.891 1.891 0 0 1-1.391-.556 1.93 1.93 0 0 1-.556-1.421v-3.811h-3.84a1.917 1.917 0 0 1-1.407-.571 1.949 1.949 0 0 1-.57-1.436 1.947 1.947 0 0 1 1.977-1.976h3.84v-3.841a1.858 1.858 0 0 1 .592-1.383 1.9 1.9 0 0 1 1.354-.565 1.937 1.937 0 0 1 1.421.555 1.9 1.9 0 0 1 .555 1.392v3.844h3.842a2 2 0 0 1 2.007 2.007 1.909 1.909 0 0 1-.587 1.39 1.941 1.941 0 0 1-1.421.587h-3.842v3.811a1.9 1.9 0 0 1-1.976 1.976m-5.787-8.71a.888.888 0 0 0-.917.916.908.908 0 0 0 .259.686.872.872 0 0 0 .657.261h4.9v4.871a.889.889 0 0 0 .245.671.846.846 0 0 0 .642.245.846.846 0 0 0 .915-.916v-4.871h4.9a.9.9 0 0 0 .672-.276.86.86 0 0 0 .276-.64.94.94 0 0 0-.947-.947h-4.9v-4.9a.852.852 0 0 0-.244-.643.9.9 0 0 0-.672-.245.853.853 0 0 0-.618.268.814.814 0 0 0-.268.619v4.9z"
        transform="translate(-40.008 -223.51)"
      />
    </g>
  </svg>,
  'Logo'
)

export default AIconLogo
