import type {FC} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import {AppBar, Box, Button, CardMedia, FormControlLabel, IconButton, Switch, Toolbar} from '@material-ui/core'
import {experimentalStyled} from '@material-ui/core/styles'
import type {AppBarProps} from '@material-ui/core'
import MenuIcon from '../../../icons/Menu'
import AccountPopover from '../../../components/dashboard/AccountPopover'
import ContactsPopover from '../../../components/dashboard/ContactsPopover'
import ContentSearch from '../../../components/dashboard/ContentSearch'
import LanguagePopover from '../../../components/dashboard/LanguagePopover'
import Logo from '../../../components/Logo'
import NotificationsPopover from '../../../components/dashboard/NotificationsPopover'
import {SIDEBAR_X, NAVBAR_COLOR, AccountType, keyEmergencyCallGlobalDisabled} from '../../consts'
import {ButtonChatbot, ButtonVitalReaction, ButtonLogout, ButtonUser} from './DashboardNavbarButtons'
import React, {useEffect, useState} from 'react'
import {carewellAuthApi} from '../../api/carewellAuthApi'
import ArrowLeftIcon from '../../../icons/ArrowLeft';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({theme}) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
)

// https://s3.ap-northeast-2.amazonaws.com/mys3.jeneri.net/test/logo.png

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarInstaller: FC<DashboardNavbarProps> = (props) => {
  const {onSidebarMobileOpen, ...other} = props

  const [emcDisabled, setEmcDisabled] = useState(false)

  const navigate = useNavigate()

  const handleEditProfile = () => {
    console.log('개인 정보 변경')

    navigate(`/dashboard/users/hcenter-managers/${carewellAuthApi.loggedInUser.id}/details-profile`)
    //  navigate('/dashboard/users/hcenter-managers/hcenter001/edit')
  }

  const handleToggle = (e: React.ChangeEvent<any>) => {
    console.log('handleToggle: ', e.target.checked)
    setEmcDisabled(!e.target.checked)
    if (e.target.checked) {
      localStorage.setItem(keyEmergencyCallGlobalDisabled, '1')
    } else {
      localStorage.removeItem(keyEmergencyCallGlobalDisabled)
    }
  }

  useEffect(() => {
    console.log('emcDisabled', localStorage.getItem(keyEmergencyCallGlobalDisabled))
    const enabled = localStorage.getItem(keyEmergencyCallGlobalDisabled) === null
    setEmcDisabled(enabled)
  }, [])

  const handleGoBackward = () => {
    navigate(-1)
  }

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{minHeight: 64, bgcolor: NAVBAR_COLOR}}>
        {/* <RouterLink to="/"> */}
        {/*  <CardMedia component="img" image="/static/logo.svg" alt="Live from space album cover" */}
        {/*    sx={{height: 22, ml: 1}} */}
        {/*  /> */}
        {/* </RouterLink> */}
        {/* <Button */}
        {/*  color="primary" */}
        {/*  startIcon={<ArrowLeftIcon fontSize="small" sx={{color: '#FFFFFF'}} />} */}
        {/*  onClick={handleGoBackward} */}
        {/* /> */}
        <Button
          sx={{fontSize: 40}}
          color="primary"
          startIcon={<KeyboardArrowLeftIcon sx={{color: '#FFFFFF', fontSize: 40}} fontSize="large" />}
          onClick={handleGoBackward}
        />

        {/* <Box sx={{ml: '60px'}}><ButtonChatbot /></Box> */}
        {/* <Box sx={{ml: 8}}><ButtonVitalReaction /></Box> */}

        {/* <Switch onChange={handleToggle} checked={!emcEnabled} /> */}
        {/* <Box sx={{ml: 8}} /> */}
        {/* <FormControlLabel value="start" label="부재중" labelPlacement="start" */}
        {/*  control={<Switch onChange={handleToggle} checked={!emcDisabled} />} */}
        {/* /> */}

        <Box sx={{flexGrow: 1, ml: 2}} />
        {/* <LanguagePopover /> */}
        {/* <Box sx={{ml: 1}}><ContentSearch /></Box> */}
        {/* <Box sx={{ml: 1}}><ContactsPopover /></Box> */}
        {/* <Box sx={{ml: 1}}><NotificationsPopover /></Box> */}
        {/* <Box sx={{ml: 1}}><AccountPopover /></Box> */}
        <Box sx={{ml: 1}}><ButtonUser /></Box>
        {carewellAuthApi.getAccountType() === AccountType.HCenterManager
                    && <Button sx={{color: 'primary.contrastText'}} size="small" onClick={handleEditProfile}>개인정보변경</Button>}
        <Box sx={{ml: 1}}><ButtonLogout /></Box>
      </Toolbar>
    </DashboardNavbarRoot>
  )
}

DashboardNavbarInstaller.propTypes = {
  onSidebarMobileOpen: PropTypes.func
}

export default DashboardNavbarInstaller
