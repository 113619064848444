
export interface UIOptionItem {
  label: string
  value: string
}



export const appUsableTimeOptions = [
  {
    label: '1년',
    value: '1년'
  },
  {
    label: '3년',
    value: '3년'
  },
]
export const fotaTypeOptions = [
  {
    label: '정규',
    value: '정규'
  },
  {
    label: '긴급',
    value: '긴급'
  },
]
export const fotaStatusOptions = [
  {
    label: '배포',
    value: '배포'
  },
  {
    label: '회수',
    value: '회수'
  },
  {
    label: '테스트',
    value: '테스트'
  },
]

export const statesOfKorea = ['서울', '경기', '인천', '강원', '세종', '대전', '대구', '충북', '충남', '전북', '전남', '경북', '경남', '울산', '광주', '부산', '제주']
export const statesOfKoreaOptions = ['전국', '서울', '경기', '인천', '강원', '세종', '대전', '대구', '충북', '충남', '전북', '전남', '경북', '경남', '울산', '광주', '부산', '제주']
export const areaSortOptions = [
  {label: '전국', value: ''},
  {label: '서울', value: '서울'},
  {label: '경기', value: '경기'},
  {label: '인천', value: '인천'},
  {label: '강원', value: '강원'},
  {label: '세종', value: '세종'},
  {label: '대전', value: '대전'},
  {label: '대구', value: '대구'},
  {label: '충북', value: '충북'},
  {label: '충남', value: '충남'},
  {label: '전북', value: '전북'},
  {label: '전남', value: '전남'},
  {label: '경북', value: '경북'},
  {label: '경남', value: '경남'},
  {label: '울산', value: '울산'},
  {label: '광주', value: '광주'},
  {label: '부산', value: '부산'},
  {label: '제주', value: '제주'},
]
export const hCenterSelectOptions = [
  {
    value: 'shirts',
    label: '송파구 보건소'
  },
  {
    value: 'Phones',
    label: '노원구 보건소'
  },
  {
    value: 'Cars',
    label: '마포구 보건소'
  }
]
// 간호사/의사/영양사/운동전문가/물리치료사/치과위생사
// TODO: 옵션 조정 필요
export const MunicipalityJobOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: '행정담당자',
    label: '행정담당자',
  },
  {
    value: '선임간호사',
    label: '선임간호사'
  },
  {
    value: '담당간호사',
    label: '담당간호사'
  },
  {
    value: '영양사',
    label: '영양사'
  },
  {
    value: '운동전문가',
    label: '운동전문가'
  },
  {
    value: '치위생사',
    label: '치위생사'
  },
  {
    value: '물리•직업치료사',
    label: '물리직업치료사'
  },
]
// 간호사/의사/영양사/운동전문가/물리치료사/치과위생사
export const hCenterJobOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: '행정담당자',
    label: '행정담당자',
  },
  {
    value: '선임간호사',
    label: '선임간호사'
  },
  {
    value: '담당간호사',
    label: '담당간호사'
  },
  {
    value: '영양사',
    label: '영양사'
  },
  {
    value: '운동전문가',
    label: '운동전문가'
  },
  {
    value: '치위생사',
    label: '치위생사'
  },
  {
    value: '물리•직업치료사',
    label: '물리직업치료사'
  },
]
// 슈퍼관리자,건장증진개발원,센터관리자
export const hCenterManagerType = [
  {
    value: '보건소관리자',
    label: '보건소관리자'
  }
]

export const MunicipalityManagerType = [
  {
    value: '지자체관리자',
    label: '지자체관리자'
  }
]

export const operatorType = [
  {
    value: '슈퍼관리자',
    label: '슈퍼관리자'
  },
  {
    value: '설치관리자',
    label: '설치관리자'
  },
  {
    value: '센터관리자',
    label: '센터관리자'
  },
  {
    value: '원격관리자',
    label: '원격관리자'
  }
]

export const speakerModelNameOptions = [
  {
    label: 'IF-H1100',
    value: 'IF-H1100'
  },
  {
    label: 'IF-S1100',
    value: 'IF-S1100'
  }
  // {
  //   label: 'MODEL-001',
  //   value: 'MODEL-001'
  // }
]

export const speakerStatusOptions: UIOptionItem[] = [
  {
    label: '전체',
    value: '전체'
  },
  {
    label: '입고',
    value: '입고'
  },
  {
    label: '출고',
    value: '출고'
  },
  {
    label: 'AS',
    value: 'AS'
  },
  {
    label: '중고',
    value: '중고'
  },
  {
    label: '폐기',
    value: '폐기'
  }
]

export const speakerStatusHCenterOptions: UIOptionItem[] = [
  {
    label: '전체',
    value: '전체'
  },
  {
    label: '출고',
    value: '출고'
  },
  {
    label: 'AS',
    value: 'AS'
  },
]

export const speakerNetworkStatusOptions = [
  {
    label: '전체',
    value: '전체'
  },
  {
    label: '사용',
    value: '사용'
  },
  {
    label: '미사용',
    value: '미사용'
  }
]

export const speakerServiceOptions = [
  {
    label: '인증',
    value: 'authorized'
  },
  {
    label: '미인증',
    value: 'unauthorized'
  }
]

export const emailOptions = [
  {
    label: '직접입력',
    value: '1'
  },
  {
    label: 'naver.com',
    value: 'naver.com'
  },
  {
    label: 'kakao.com',
    value: 'kakao.com'
  },
  {
    label: 'gmail.com',
    value: 'gmail.com'
  },
]

export const IoTEmailOptions = [
  {
    label: '직접입력',
    value: ''
  },
  {
    label: 'email.carewellplus.co.kr',
    value: 'email.carewellplus.co.kr'
  },
  {
    label: 'marknova.co.kr',
    value: 'marknova.co.kr'
  }
]

export const hCenterManagerQuitReasonOptions = [
  {
    label: '담당 업무 변경',
    value: '담당 업무 변경'
  },
  {
    label: '퇴사 및 계약 종류',
    value: '퇴사 및 계약 종류'
  },
  {
    label: '기타',
    value: '기타'
  }

]

// 보건소관리자
export const deleteHCenterManagerApiUrl = '/delete_public_care_admin'
export const deleteHCenterManagerReasonOptions = [
  '담당 업무 변경으로 인한 개인정보 삭제 목적',
  '퇴사 및 계약 종료로 인한 개인정보 삭제 목적',
  '기타'
]

// 지자체관리자
export const deleteMunicipalityManagerApiUrl = '/delete_public_care_admin'
export const deleteMunicipalityManagerReasonOptions = [
  '담당 업무 변경으로 인한 개인정보 삭제 목적',
  '퇴사 및 계약 종료로 인한 개인정보 삭제 목적',
  '기타'
]

export const deleteOperatorApiUrl = '/delete_public_care_admin'
export const deleteOperatorReasonOptions = [
  '담당 업무 변경으로 인한 개인정보 삭제 목적',
  '퇴사 및 계약 종료로 인한 개인정보 삭제 목적',
  '기타'
]

export const oneToOneTermsAgreeOptions = [
  {
    label: '미동의',
    value: '미동의'
  },
  {
    label: '동의',
    value: '동의'
  },
]

export const oneToOneQuestionStatus = [
  '대기중',
  '답변완료'
]

export const emergencyCallPhoneStatusOptions = [
  {
    value: '통화완료',
    label: '통화완료'
  },
  {
    value: '통화불가',
    label: '통화불가'
  }
]

// 119요청완료 , 어르신실수요청취소 , 건강완화응급요청취소 ,네트워크등일시적오류,기타

// 처리상태
export const emergencyCallProcessStatusOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: '119요청완료',
    label: '119요청완료'
  },
  {
    value: '어르신실수요청취소',
    label: '어르신실수요청취소'
  },
  {
    value: '건강완화응급요청취소',
    label: '건강완화응급요청취소'
  },
  {
    value: '네트워크등일시적오류',
    label: '네트워크등일시적오류'
  },
  {
    value: '기타',
    label: '기타'
  },
]
export const patternGroup = [
  {
    value: '',
    label: ''
  },
  {
    value: '식사',
    label: '식사'
  },
  {
    value: '날씨',
    label: '날씨'
  },
  {
    value: '운동',
    label: '운동'
  },
  {
    value: '정서',
    label: '정서'
  }
]

export const patternMeal = [
  {
    value: '',
    label: ''
  },
  {
    value: '브리핑',
    label: '브리핑'
  },
  {
    value: '아침',
    label: '아침'
  },
  {
    value: '점심',
    label: '점심'
  },
  {
    value: '저녁',
    label: '저녁'
  },
  {
    value: '랜덤',
    label: '랜덤'
  }
]

export const patternWeather = [
  {
    value: '',
    label: ''
  },
  {
    value: '브리핑',
    label: '브리핑'
  },
  {
    value: '더움',
    label: '더움(35도 이상)'
  },
  {
    value: '보통',
    label: '보통(18도~29도 미만)'
  },
  {
    value: '선선',
    label: '선선(13도~18도 미만)'
  },
  {
    value: '추움',
    label: '추움(7도~13도 미만)'
  },
  {
    value: '매우추움',
    label: '매우추움(-9도~7도 미만)'
  },
  {
    value: '몹시추움',
    label: '몹시추움(-9도 미만)'
  },
  {
    value: '맑음',
    label: '맑음'
  },
  {
    value: '구름많음',
    label: '구름많음'
  },
  {
    value: '흐림',
    label: '흐림'
  },
  {
    value: '소나기',
    label: '소나기'
  },
  {
    value: '빗방울',
    label: '빗방울'
  },
  {
    value: '비',
    label: '비'
  },
  {
    value: '비/눈',
    label: '비/눈'
  },
  {
    value: '빗방울/눈날림',
    label: '빗방울/눈날림'
  },
  {
    value: '눈날림',
    label: '눈날림'
  },
  {
    value: '미세먼지매우나쁨',
    label: '미세먼지매우나쁨'
  },
  {
    value: '미세먼지나쁨',
    label: '미세먼지나쁨'
  },
  {
    value: '미세먼지보통',
    label: '미세먼지보통'
  },
  {
    value: '미세먼지좋음',
    label: '미세먼지좋음'
  },
  {
    value: '초미세먼지매우나쁨',
    label: '초미세먼지매우나쁨'
  },
  {
    value: '초미세먼지나쁨',
    label: '초미세먼지나쁨'
  },
  {
    value: '초미세먼지보통',
    label: '초미세먼지보통'
  },
  {
    value: '초미세먼지좋음',
    label: '초미세먼지좋음'
  },
]

export const patternExercise = [
  {
    value: '',
    label: ''
  },
  {
    value: '브리핑',
    label: '브리핑'
  },
  {
    value: '근력운동',
    label: '근력운동'
  },
  {
    value: '체조',
    label: '체조'
  },
  {
    value: '가벼운운동',
    label: '가벼운운동'
  },
  {
    value: '몸풀기',
    label: '몸풀기'
  }
]

export const patternEmotion = [
  {
    value: '',
    label: ''
  },
  {
    value: '브리핑',
    label: '브리핑'
  },
  {
    value: '독서',
    label: '독서'
  },
  {
    value: '영화',
    label: '영화'
  },
  {
    value: '드라마',
    label: '드라마'
  },
  {
    value: '하루일정마무리',
    label: '하루일정마무리'
  }
]

export const answerPerform = [
  {
    value: '',
    label: ''
  },
  {
    value: '메뉴네비게이션',
    label: '메뉴네비게이션'
  },
  {
    value: '어플리케이션',
    label: '어플리케이션'
  },
  {
    value: '스피커발화',
    label: '스피커발화'
  }
]

export const menuNavigation = [
  {
    value: '',
    label: ''
  },
  {
    value: 'IDLE HOME 이동',
    label: 'IDLE HOME 이동'
  },
  {
    value: '전체 메뉴 이동',
    label: '전체 메뉴 이동'
  },
  {
    value: '앱 종료',
    label: '앱 종료'
  },
  {
    value: '이전 페이지 이동',
    label: '이전 페이지 이동'
  }
]

export const application = [
  {
    value: '',
    label: ''
  },
  {
    value: '앱 실행',
    label: '앱 실행'
  },
  {
    value: '앱 종료',
    label: '앱 종료'
  },
  {
    value: '이전 페이지 이동',
    label: '이전 페이지 이동'
  }
]

export const runApp = [
  {
    value: '',
    label: ''
  },
  {
    value: '날씨',
    label: '날씨'
  },
  {
    value: '음악감상',
    label: '음악감상'
  },
  {
    value: '라디오',
    label: '라디오'
  },
  {
    value: '응급요청',
    label: '응급요청'
  },
  {
    value: '프로필',
    label: '프로필'
  },
  {
    value: '치매바로열기',
    label: '치매바로열기'
  },
  {
    value: '치매전문기관',
    label: '치매전문기관'
  },
  {
    value: '두근두근뇌운동',
    label: '두근두근뇌운동'
  },
  {
    value: '어르신운동',
    label: '어르신운동'
  },
  {
    value: '날짜맞히기',
    label: '날짜맞히기'
  },
  {
    value: '숫자맞히기',
    label: '숫자맞히기'
  },
  {
    value: '마은튼튼',
    label: '마음튼튼'
  },
  {
    value: '명상힐링',
    label: '명상힐링'
  },
  {
    value: '건강검진이해하기',
    label: '건강검진이해하기'
  },
  {
    value: '음식',
    label: '음식'
  },
  {
    value: '건강관리',
    label: '건강관리'
  },
  {
    value: '어르신커뮤니티',
    label: '어르신커뮤니티'
  },
  {
    value: '전화번호부',
    label: '전화번호부'
  }
]
