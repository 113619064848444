import DescriptionIcon from '@material-ui/icons/Description'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup'
import RemoteIcon from './icons/remote.png';
import UsersIcon from '../icons/Users'
import { carewellAuthApi } from './api/carewellAuthApi'
import { AccountType } from './consts'


// 운영관리자 사이드바 메뉴
export const sectionsOperator = [
  {
    title: 'General',
    items: [
      // {
      //   title: 'API Test(이건 무시)',
      //   path: '/dashboard/api-test',
      //   icon: <ApiIcon fontSize="small" />
      // },
      // {
      //   title: 'Home',
      //   path: '/dashboard',
      //   icon: <ChartSquareBarIcon fontSize="small" />
      // }
      // ,
      {
        title: '긴급요청 현황',
        path: '/dashboard/emergency-req',
        icon: <NotificationsActiveIcon fontSize="small" />
      }
    ]
  },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원 관리',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
              {
                title: '등록',
                path: '/dashboard/users/recipients/new'
              }
            ]
          },
          {
            title: '보건소관리자',
            path: '/dashboard/hcenter-managers',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/hcenter-managers'
              },
              {
                title: '등록',
                path: '/dashboard/users/hcenter-managers/new'
              }
            ]
          },
          {
            title: '운영관리자',
            path: '/dashboard/operators',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/operators'
              },
              {
                title: '등록',
                path: '/dashboard/users/operators/new'
              }
            ]
          },
          {
            title: '지자체관리자',
            path: '/dashboard/municipality-managers',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/municipality-managers'
              },
              {
                title: '등록',
                path: '/dashboard/users/municipality-managers/new'
              }
            ]
          },
          {
            title: 'IoT',
            path: '/dashboard/IoT',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/IoT'
              },
              {
                title: '등록',
                path: '/dashboard/users/IoT/new'
              }
              // {
              //   title: '상세보기',
              //   path: '/dashboard/users/IoT/detail'
              // },
              // {
              //   title: '수정',
              //   path: '/dashboard/users/IoT/update'
              // }
            ]
          },
          {
            title: '탈퇴회원 조회',
            path: '/dashboard/users/recipients-quit',
            children: [
              {
                title: '대상자',
                path: '/dashboard/users/recipients-quit'
              },
              {
                title: '보건소관리자',
                path: '/dashboard/users/hcenter-managers-quit'
              },
              {
                title: '운영관리자',
                path: '/dashboard/users/operators-quit'
              },
              {
                title: '지자체관리자',
                path: '/dashboard/users/municipality-managers-quit'
              }
            ]
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 관리',
        path: '/dashboard/speakers',
        icon: <SpeakerGroupIcon fontSize="small" />,
        children: [
          {
            title: '스피커 현황',
            path: '/dashboard/speakers/overview'
          },
          {
            title: '입고 등록',
            path: '/dashboard/speakers',
            children: [
              {
                title: '조회',
                path: '/dashboard/speakers'
              },
              {
                title: '등록',
                path: '/dashboard/speakers/new'
              }
            ]
          },
          {
            title: '소프트웨어 관리',
            path: '/dashboard/softwares',
            children: [
              {
                title: '앱 관리',
                path: '/dashboard/softwares/apps'
              },
              {
                title: 'FOTA',
                path: '/dashboard/softwares/fota'
              }
            ]
          }
        ]
      },
    ]
  },
  // {
  //   title: '챗봇 관리 X',
  //   items: [
  //     {
  //       title: '챗봇 관리',
  //       path: '/dashboard/chatbots',
  //       icon: <SpeakerGroupIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: '스마트알림 관리',
  //           path: '/dashboard/chatbots',
  //           children: [
  //             {
  //               title: '조회',
  //               path: '/dashboard/chatbots'
  //             },
  //             {
  //               title: '등록',
  //               path: '/dashboard/chatbots/new'
  //             },
  //             // {
  //             //   title: '상세보기',
  //             //   path: '/dashboard/chatbots/:id/details'
  //             // }
  //           ]
  //         }
  //       ]
  //     },
  //   ]
  // },
  {
    title: '소프트웨어 관리 X',
    items: [
      {
        title: '소프트웨어 관리',
        path: '/dashboard/softwares',
        // icon: <AppSettingsAltIcon fontSize="small" />,
        children: [
          {
            title: '앱 관리',
            path: '/dashboard/softwares/apps'
          },
          {
            title: 'FOTA',
            path: '/dashboard/softwares/fota'
          }
        ]
      }
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티 관리',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: '공지사항',
            path: '/dashboard/communities/notices'
          },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          // {
          //   title: '자주 묻는 질문',
          //   path: '/dashboard/communities/faqs'
          // },
          // {
          //   title: '대상자 문의관리',
          //   path: '/dashboard/communities/recipient-questions'
          // },
          {
            title: '보건소 문의관리',
            path: '/dashboard/communities/hcenter-questions'
          },
          {
            title: '이용약관 관리',
            path: '/dashboard/communities/terms'
          }
        ]
      },
    ]
  },
  {
    title: '원격서비스 X',
    items: [
      {
        title: '원격서비스',
        path: '/service/remote',
        icon: <img src={RemoteIcon} alt="RemoteIcon" width={20} height={20} />
      },
    ]
  }
]


export const sectionRemote = [
  {
    title: '원격서비스 X',
    items: [
      {
        title: '원격서비스',
        path: '/service/remote',
        icon: <img src={RemoteIcon} alt="RemoteIcon" width={20} height={20} />
      },
    ]
  }
]


// 보건소관리자 사이드바 메뉴
export const sectionsHCenterManager = [
  {
    title: 'General',
    items: [
      {
        title: '긴급요청 현황',
        path: '/dashboard/emergency-req',
        icon: <NotificationsActiveIcon fontSize="small" />
      }
    ]
  },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
              {
                title: '등록',
                path: '/dashboard/users/recipients/new'
              }
            ]
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 현황',
        path: '/dashboard/speakers/overview',
        icon: <SpeakerGroupIcon fontSize="small" />,
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: '공지사항',
            path: '/dashboard/communities/notices'
          },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          {
            title: '자주 묻는 질문',
            path: '/dashboard/communities/hcenter-faqs'
          },
          {
            title: '1:1 문의',
            path: '/dashboard/communities/1to1-questions'
          },
          {
            title: '이용약관',
            path: '/dashboard/communities/hcenter-terms'
          }
        ]
      },
    ]
  },
  {
    title: '원격서비스 X',
    items: [
      {
        title: '원격서비스',
        path: '/service/remote',
        icon: <img src={RemoteIcon} alt="RemoteIcon" width={20} height={20} />
      },
    ]
  }
]
// 건강증진개발원 사이드바 메뉴
export const sectionsKHealthMember = [
  {
    title: 'General',
    items: [
      {
        title: '긴급요청 현황',
        path: '/dashboard/emergency-req',
        icon: <NotificationsActiveIcon fontSize="small" />
      }
    ]
  },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 현황',
        path: '/dashboard/speakers/overview',
        icon: <SpeakerGroupIcon fontSize="small" />,
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: '공지사항',
            path: '/dashboard/communities/notices'
          },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          {
            title: '자주 묻는 질문',
            path: '/dashboard/communities/hcenter-faqs'
          },
          {
            title: '1:1 문의',
            path: '/dashboard/communities/1to1-questions'
          },
          {
            title: '이용약관',
            path: '/dashboard/communities/hcenter-terms'
          }
        ]
      },
    ]
  }
]

// 설치관리자 사이드바 메뉴
export const sectionsInstaller = [
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원 관리',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: '공지사항',
            path: '/dashboard/communities/notices'
          },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
        ]
      },
    ]
  }
]

// 보건소관리자 사이드바 메뉴
export const sectionsCallCenterManager = [
  {
    title: 'General',
    items: [
      {
        title: '긴급요청 현황 cc',
        path: '/dashboard/emergency-req',
        icon: <NotificationsActiveIcon fontSize="small" />
      }
    ]
  },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원 관리',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
              {
                title: '등록',
                path: '/dashboard/users/recipients/new'
              }
            ]
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 관리',
        path: '/dashboard/speakers',
        icon: <SpeakerGroupIcon fontSize="small" />,
        children: [
          {
            title: '스피커 현황',
            path: '/dashboard/speakers/overview'
          }
        ]
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: '공지사항',
            path: '/dashboard/communities/notices'
          },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          {
            title: '자주 묻는 질문',
            path: '/dashboard/communities/hcenter-faqs'
          },
          {
            title: '1:1 문의',
            path: '/dashboard/communities/1to1-questions'
          },
          {
            title: '이용약관',
            path: '/dashboard/communities/hcenter-terms'
          }
        ]
      },
    ]
  }
]

// 지자체관리자 사이드바 메뉴
export const sectionsMunicipalitymanager = [
  {
    title: 'General',
    items: [
      {
        title: '종합현황판',
        path: '/',
      }
    ]
  },
  {
    title: 'Emergencycall',
    items: [
      {
        title: '긴급요청 현황',
        path: '/dashboard/emergency-req',
      }
    ]
  },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원 관리',
        path: '', // '/dashboard/customers',
        children: [
          {
            title: '대상자 조회',
            path: '/dashboard/users/recipients',
          },
          {
            title: '대상자 등록',
            path: '/dashboard/users/recipients/new',
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 관리',
        path: '/dashboard/speakers/overview',
      },
    ]
  },
  {
    title: '시스템관리 X',
    items: [
      {
        title: '시스템관리',
        path: '/dashboard/communities',
        children: [
          {
            title: '공지사항',
            path: '/dashboard/communities/notices'
          },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          {
            title: '자주묻는질문',
            path: '/dashboard/communities/hcenter-faqs'
          },
          {
            title: '1:1 문의',
            path: '/dashboard/communities/1to1-questions'
          },
          {
            title: '이용약관',
            path: '/dashboard/communities/hcenter-terms'
          }
        ]
      },
    ]
  }
]



// export const currentSidebarSection = (): [] => {
export function currentSidebarSection() : Array<any> {
  switch (carewellAuthApi.getAccountType()) {
    // case  AccountType.Operator: // 운영관리자
    case AccountType.HCenterManager: // 보건소관리자
      return sectionsHCenterManager
    case AccountType.KHealthMember: // 건강증진개발원
      return sectionsKHealthMember
    case AccountType.InstallManager: // 설치 관리자
      return sectionsInstaller
    case AccountType.CallCenterManager:
      return sectionsCallCenterManager
    case AccountType.MunicipalityManager:
      return sectionsMunicipalitymanager
    case AccountType.RemoteManager:
      return sectionRemote
    default:
      return sectionsOperator
  }

  // switch (getAppTypeByUrl()) {
  //   case AppTypeCareWell.HCenterManagerWeb:
  //     return sectionsHCenterManager
  //   case AppTypeCareWell.InstallerMobile:
  //     return sectionsInstaller
  //   case AppTypeCareWell.CallCenterTablet:
  //     return sectionsCallCenterManager
  //   default: {
  //     return sectionsOperator
  //   }
  // }
}
