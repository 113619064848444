import {decode, JWT_EXPIRES_IN, JWT_SECRET, sign} from '../../utils/jwt'
import {apiClient, HCenterManagerInfo, MunicipalityManagerInfo} from './common'
import {User} from '../../types/user'
import {
  AccountType,
  accountTypeNames,
  AppTypeCareWell,
  AppTypeNames,
  getAppTypeByUrl,
  KEY_USER,
  shouldCheckTempPassword
} from '../consts'
import { getCookie, setCookie } from '../utils/cookieTools'
// const users = [
//   {
//     id: '5e86809283e28b96d2d38537',
//     avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
//     email: 'test001',
//     name: 'Jane Rotanson',
//     password: 'info1!',
//     plan: 'Premium'
//   }
// ]

class CarewellAuthApi {
  ///
  loggedInUser: any

  constructor() {
    const myStorage = window.localStorage
    const userJson = myStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      // console.log('Response from server: ', userJson)
      console.log('>> logged in user: ', this.loggedInUser)
      console.log(this.loggedInUser);
    } else {
      this.loggedInUser = null
    }
    // console.log('>>>> ', myStorage.getItem(`${KEY_USER}`))
  }

  getAdminName(): any {
    const userJson = window.localStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      if (this.loggedInUser.accountType === AccountType.MunicipalityManager) return (this.loggedInUser as MunicipalityManagerInfo).municipality_username
      if (this.loggedInUser.accountType === AccountType.HCenterManager) return (this.loggedInUser as HCenterManagerInfo).public_care_username
      return (this.loggedInUser as any).admin_username;
    }
    return null;
  }

  getAdminId(): any {
    const userJson = window.localStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      if (this.loggedInUser.accountType === AccountType.MunicipalityManager) return (this.loggedInUser as MunicipalityManagerInfo).municipality_admin_id
      if (this.loggedInUser.accountType === AccountType.HCenterManager) return (this.loggedInUser as HCenterManagerInfo).public_care_admin_id
      return (this.loggedInUser as any).admin_user_id;
    }
    return null;
  }

  getAccountInfo(): any {
    const userJson = window.localStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      if (this.loggedInUser.accountType === AccountType.MunicipalityManager) return this.loggedInUser as MunicipalityManagerInfo
      if (this.loggedInUser.accountType === AccountType.HCenterManager) return this.loggedInUser as HCenterManagerInfo
      return this.loggedInUser as any;
    }
    return null;
  }

  getAccountType(): AccountType {
    // console.log('getAccountType: ', this.loggedInUser.accountType)
    if (this.loggedInUser) {
      return this.loggedInUser.accountType
    }
    return AccountType.Operator
  }

  getAccountTypeName(): string {
    return accountTypeNames[this.loggedInUser.accountType]
  }

  hasHiddenSidebar(): boolean {
    return this.loggedInUser.accountType === AccountType.CallCenterManager || this.loggedInUser.accountType === AccountType.InstallManager
  }

  async login({email, password}): Promise<string> {
    console.log('> login .. ')
    console.log('loc: ', window.location.href)

    let appType = AppTypeCareWell.OpManagerWeb
    let getUrl = '/admin_logindo'
    let queryIdName = 'admin_user_id'
    let nameTableName = 'admin_username'
    // 보건소 관리자 WEB
    if (getAppTypeByUrl() === AppTypeCareWell.HCenterManagerWeb) { // || getAppTypeByUrl() === AppTypeCareWell.CallCenterTablet) {
      appType = AppTypeCareWell.HCenterManagerWeb
      getUrl = '/public_care_admin_logindo'
      queryIdName = 'public_care_admin_id'
      nameTableName = 'public_care_username'
    }
    if (getAppTypeByUrl() === AppTypeCareWell.MunicipalityWeb) {
      appType = AppTypeCareWell.MunicipalityWeb
      getUrl = '/municipality_admin_logindo'
      queryIdName = 'municipality_admin_id'
      nameTableName = 'municipality_username'
    }

    console.log('app type: ', AppTypeNames[appType])
    console.log(`> LOGIN: ${getUrl}?${queryIdName}=${email}&password=${password}`)

    const reqData = {
      [queryIdName]: email,
      password,
    };

    const refToken = (await apiClient.post('/create_refresh_token', {
      id: email,
    })).data.result
    const accToken = (await apiClient.post('/create_access_token')).data.result

    const config = {
      headers: {
        'x-access-token': accToken,
        'x-refresh-token': refToken,
      }
    }

    const res = await apiClient.post(getUrl, reqData, config)
    console.log(`[API ${getUrl}]: `, res.data)
    console.log(res);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {status, state, x_access_token, x_refresh_token} = res.data
    const isSuccess = status === 'ok' || state === 'ok'
    if (isSuccess) {
      setCookie('access_token', x_access_token, 1)
      setCookie('refresh_token', x_refresh_token, 1)
      const tempLoggedInUser = {
        id: email,
        avatar: null,
        email,
        name: '',
        plan: ''
      }
      let accountType0 = AccountType.Operator
      if (res.data.admin_role) {
        if (res.data.admin_role === accountTypeNames[3]) accountType0 = AccountType.InstallManager
        else if (res.data.admin_role === accountTypeNames[4]) accountType0 = AccountType.CallCenterManager
        else if (res.data.admin_role === accountTypeNames[6]) accountType0 = AccountType.RemoteManager
      } else if (res.data.public_care_role) {
        if (res.data.public_care_role === accountTypeNames[1]) accountType0 = AccountType.HCenterManager
        else if (res.data.public_care_role === accountTypeNames[2]) accountType0 = AccountType.KHealthMember
      } else if (res.data.municipality_role) {
        if (res.data.municipality_role === accountTypeNames[5]) accountType0 = AccountType.MunicipalityManager
      }

      console.log('logged in 계정타입: ', accountTypeNames[accountType0])

      const loggedInUser = {...res.data, ...tempLoggedInUser, accountType: accountType0, name: res.data[nameTableName]}

      console.log('loggedInUser: ', loggedInUser)
      this.loggedInUser = loggedInUser
      const myStorage = window.localStorage
      myStorage.setItem(KEY_USER, JSON.stringify(this.loggedInUser))
      console.log('loggedInUser has saved.', this.loggedInUser)
      if (AccountType.HCenterManager === accountType0 && res.data.public_care_temp_password_yn === 'true') {
        localStorage.setItem(shouldCheckTempPassword, 'true')
      }
    }

    return new Promise((resolve, reject) => {
      try {
        // Find the user
        // const user = users.find((_user) => _user.email === email);


        if (isSuccess === false) {
          reject(new Error('ID와 비밀번호를 확인하여 주십시오.'))
          return
        }

        // Create the access token
        const accessToken = sign(
          {userId: email},
          JWT_SECRET,
          {expiresIn: JWT_EXPIRES_IN}
        )

        resolve(accessToken)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  me(accessToken): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const {userId} = decode(accessToken) as any

        // Find the user
        // const user = users.find((_user) => _user.id === userId);

        if (!this.loggedInUser) {
          reject(new Error('Invalid authorization token'))
          return
        }

        resolve(this.loggedInUser)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  logout() {
    this.loggedInUser = null
    localStorage.clear()
  }
}

export const carewellAuthApi = new CarewellAuthApi()
