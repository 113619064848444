import {apiClient} from './common';
import {HCenterData, MuniciaplityData} from '../types/user';
import { setCookie } from '../utils/cookieTools';


class CommonApi {
  async getHCenterList(config) : Promise<any> {
    try {
      const url = '/get_public_care_list'
      let res = await apiClient.get(url, config)
      if (res.data.state === 'reissue') {
        const tkn = res.data.token;
        config.headers['x-access-token'] = tkn
        setCookie('access_token', tkn, 1)
        res = await apiClient.get(url, config)
      }
      console.log(`[API ${url}]: `, res.data)

      const centers: HCenterData[] = res.data.list
      const newHCenters = {}
      // for (let i = 1; i < statesOfKoreaOptions.length; ++i) {
      //   newHCenters[statesOfKoreaOptions[i]] = []
      // }
      const hCenterAreas = ['']
      centers.forEach((item) => {
        // console.log('-> area: ', item.public_care_area)
        if (newHCenters[item.public_care_area] === undefined) {
          newHCenters[item.public_care_area] = []
          newHCenters[item.public_care_area].push('')
          hCenterAreas.push(item.public_care_area)
        }
        newHCenters[item.public_care_area].push(item.public_care_name)
      })
      return newHCenters
    } catch (err) {
      console.error(err)
      return {}
    }
  }

  async getMunicipalityList(config) : Promise<any> {
    try {
      const url = '/get_municipality_list'
      let res = await apiClient.get(url, config)
      if (res.data.state === 'reissue') {
        const tkn = res.data.token;
        config.headers['x-access-token'] = tkn
        setCookie('access_token', tkn, 1)
        res = await apiClient.get(url, config)
      }
      console.log(`[API ${url}]: `, res.data)

      const centers: MuniciaplityData[] = res.data.list
      const newMunicipalities = {}
      // for (let i = 1; i < statesOfKoreaOptions.length; ++i) {
      //   newMunicipalities[statesOfKoreaOptions[i]] = []
      // }
      const municipalities = ['']
      centers.forEach((item) => {
        // console.log('-> area: ', item.public_care_area)
        if (newMunicipalities[item.municipality_area] === undefined) {
          newMunicipalities[item.municipality_area] = []
          newMunicipalities[item.municipality_area].push('')
          municipalities.push(item.municipality_area)
        }
        newMunicipalities[item.municipality_area].push(item.municipality_name)
      })
      return newMunicipalities
    } catch (err) {
      console.error(err)
      return {}
    }
  }
}


export const commonApi = new CommonApi()
